import { useEffect, useState } from "react"
import { useLatest } from "./useLatest"

/** 获取 query string 参数 */
export function useSearchParams<T = any>(key?: string, defaultValue?: T, parser: (params?: any) => T | undefined = (value) => value) {
  const propsParser = useLatest(parser)
  const propsDefaultValue = useLatest(defaultValue)
  const [searchParams, setSearchParams] = useState<T | undefined>(() => getSearchParams(key, defaultValue, parser))


  useEffect(() => {
    const finalValue = getSearchParams(key, propsDefaultValue.current, propsParser.current)

    setSearchParams(finalValue)
  }, [key, propsDefaultValue, propsParser])

  return searchParams
}

function getSearchParams<T = any>(key?: string, defaultValue?: T, parser: (params?: any) => T | undefined = (value) => value) {
  const search = window.location.search

  const params = new URLSearchParams(search)
  if (key === undefined) {
    return parser(params) ?? defaultValue
  }

  const data = params.get(key)
  const finalValue = parser(data) ?? defaultValue

  return finalValue
}
