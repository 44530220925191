import React, { useEffect } from 'react'
import { StoreContext } from './store.context'
import { useFetchStoreDetail } from '@/services/store'
import { useAppContext } from '../app/app.context'

export interface StoreProviderProps {
  storeId?: string
  children?: React.ReactNode
}

export const StoreProvider = React.memo<StoreProviderProps>((props) => {
  const { storeId } = useAppContext()

  const {
    data: storeBusiness,
    loading,
    run: refreshBusiness,
  } = useFetchStoreDetail({
    manual: true,
    defaultLoading: true,
  })

  useEffect(() => {
    if (!storeId) return

    refreshBusiness()
  }, [storeId, refreshBusiness])

  return (
    <StoreContext.Provider value={{ loading, storeBusiness, refreshBusiness }}>
      {props.children}
    </StoreContext.Provider>
  )
})
