/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 13 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5.766L11.734 0 6.5 5.234 1.266 0 .5.766 5.734 6 .5 11.234l.766.766L6.5 6.766 11.734 12l.766-.766L7.266 6 12.5.766z"
      fill="#000"
      fillOpacity={0.54}
    />
  </svg>
)
const Memo = memo(SvgClear)
export default Memo
