import { invert, mapValues } from 'lodash'
import { Country } from './env.constant'
import { DishSaleWeekDay } from '@/services/menu'
// 这里放 rn 中的 common.constants
export const DefaultPageSize = 10
export const DefaultPageNum = 1

// 布尔值状态
export enum BoolStatus {
  True = 1,
  False = 0,
}

// Timezone
export const TimezoneOffsetCountryMapping = {
  [Country.my]: +8,
  [Country.th]: +7,
  [Country.vn]: +7,
  [Country.id]: +7, // +7|+8|+9
}

export type Second = number
export type Millisecond = number
export type UnixTime = Second // @see https://en.wikipedia.org/wiki/Unix_time
export type UnixTimeMs = Millisecond
export type UnixTimeMsString = string
// Time interval of seconds * milliseconds
export const MinuteIntervalSeconds = 60 // 60
export const HourIntervalSeconds = MinuteIntervalSeconds * 60 // 3600
export const DayIntervalSeconds = HourIntervalSeconds * 24 // 86400
export const DayIntervalSecondsEnd = DayIntervalSeconds - 1 // 86399
export const SecondIntervalMilliseconds = 1000

// Week of bit mask
export const WeekBitMaskAllDay = 127 // 1111111

export const WeekBitMaskList = [
  0b0000010, // mon
  0b0000100, // tue
  0b0001000,
  0b0010000,
  0b0100000,
  0b1000000,
  0b0000001, // sunday
]

export const WeekBitIdxMapping = {
  [WeekBitMaskList[0]]: 1, // mon
  [WeekBitMaskList[1]]: 2, // tue
  [WeekBitMaskList[2]]: 3,
  [WeekBitMaskList[3]]: 4,
  [WeekBitMaskList[4]]: 5,
  [WeekBitMaskList[5]]: 6,
  [WeekBitMaskList[6]]: 0, // sun
}

export const weekBitDayMapVN = {
  [WeekBitMaskList[0]]: DishSaleWeekDay.MONDAY,
  [WeekBitMaskList[1]]: DishSaleWeekDay.TUESDAY,
  [WeekBitMaskList[2]]: DishSaleWeekDay.WEDNESDAY,
  [WeekBitMaskList[3]]: DishSaleWeekDay.THURSDAY,
  [WeekBitMaskList[4]]: DishSaleWeekDay.FRIDAY,
  [WeekBitMaskList[5]]: DishSaleWeekDay.SATURDAY,
  [WeekBitMaskList[6]]: DishSaleWeekDay.SUNDAY,
}

export const weekDayBitMapVN = mapValues(invert(weekBitDayMapVN), Number)
