import { I18nKey } from '@/assets/strings/i18n/i18n'
import { ErrorCode } from './request.codes'

export const ErrorCodeMessageMappingAuth = {
  // 3004
  // auth_failed
  // 鉴权失败
  [ErrorCode.AuthFailed]: 'common.error.check_network',
  // 1130001
  // not login
  // token过期，需要重新登录
  [ErrorCode.NotLogin]: 'common.error.login_required',
  // 1130002
  // need to select a store
  // 需要选择具体一个store
  [ErrorCode.NeedToSelectStore]: 'common.error.login_required',
  // 1130003
  // merchant/store belongs to token/entity_id is not found
  // 没找到属于token/entity_id的商家或店铺
  [ErrorCode.TokenOrEntityIdNotFound]: 'common.error.login_required',
}

export const ErrorCodeMessageListAuth = Object.keys(ErrorCodeMessageMappingAuth).map((i) =>
  parseInt(i, 10),
)

export const ErrorCodeMessageMappingShop = {
  // 1110006
  // get shop base failed
  // 获取店铺基础信息失败 / admin获取基础信息
  [ErrorCode.GetShopBaseFailed]: 'common.error.check_network',
  // 1110092
  [ErrorCode.ShopReopenWarning]: 'common.error.shop_reopen_warning',
  // 1110093
  [ErrorCode.ShopForceClosed]: 'common.error.shop_force_closed',
  // 1110094
  // 店铺不可用
  [ErrorCode.ShopInactived]: 'common.error.shop_inactived_VN',
} as const

export const ErrorCodeMessageMappingSellerAPI = {
  // 1000
  // error params invalid
  // 参数类型错误 / 分页数值超出最大限制
  [ErrorCode.ErrorParamsInvalid]: 'common.error.check_network',
  // 2009
  // no need update
  // 无需更新
  [ErrorCode.NoNeedUpdate]: 'common.error.no_need_update',
  // 1130000
  // Number of available options conflicts with select control
  // 删除option / 将option置为unavailable / option group下available的option数量与select mode不一致
  [ErrorCode.NumberOfOptionsConflict]: 'common.error.at_least_x_options',
  // 1130004
  // no edit menu authority
  // 没有编辑菜单权限
  [ErrorCode.NoEditMenuAuthority]: 'common.error.check_network',
  // 1130005
  // option group bind dish failed
  // option group绑定dish时出现失败
  [ErrorCode.GroupBindDishFailed]: 'common.error.group_bind_dish_fail',
  // 1250002
  // Dishes errors
  // 标记是dish的错误(区分于campaign的错误)
  [ErrorCode.ItemDiscountDishError]: 'common.error.check_network',
  // 1250034
  // Error item discount price invalid
  // 	item discount 价格不正确
  [ErrorCode.ItemDiscountPriceInvalid]: 'common.error.check_network',
  // 1250035
  // 活动时间校验不通过
  [ErrorCode.CampaignTimeInvalid]:
    'edit_item_discount.error.start_time_earlier_current',
  // 1250039
  // Some of dishes not existed
  // 部分菜品不存在
  [ErrorCode.SomeDishesNotExist]: 'common.error.some_dish_not_exist',
  // 1250040
  // Number of valid discounts of a dish exceeds limit
  // 超过一个菜品最多绑定的活动数
  [ErrorCode.DiscountOfDishNumberExceedLimit]:
    'common.error.discount_dish_number_exceed_limit',
  // 1250041
  // Number of valid campaigns under a store exceeds limit
  // 过一个店铺下最多有效活动数
  [ErrorCode.CampaignsExceedLimit]: 'common.error.check_network',
  // 1250042
  // Error item discount in another campaign
  // 存在 dish 已经在 campaign 时间段内参加了其它活动
  [ErrorCode.DishInAnotherCampaign]: 'common.error.dish_in_another_campaign',
  // 1130006
  // no edit promotion authority
  // 没有编辑 promotion 权限
  [ErrorCode.NoEditPromotionAuthority]:
    'promotions.empty.no_edit_promotion_permission',
  // Error update item discount stock not allow to decrease
  // 菜品库存只能改大不能改小
  [ErrorCode.DiscountStockNotAllowDecrease]:
    'common.error.discount_stock_not_decrease',
} as const

export const ErrorCodeMessageMappingItem = {
  // 1100000
  // error dish no found
  // 不存在的菜品
  [ErrorCode.DishNoFound]: 'common.error.dish_invalid',
  // 1100001
  // error_group_count_limit_exceeded
  // 超出了 Group 数量的上限
  [ErrorCode.GroupsExceedLimit]: 'common.error.maximum_whitelist_groups',
  // 1100002
  // error dish count exceed limit
  // 超出菜品数量的上限
  [ErrorCode.DishExceedLimit]: 'common.error.maximum_150_dishes',
  // 1100003
  // error_group_rank_locked
  // 当前 Group 排序已被锁定, 该错误适用于使用锁的情况
  [ErrorCode.GroupRankLocked]: 'common.error.rank_of_group_locked',
  // 1100004
  // error_group_rank_deprecated
  // 给定的 Group 排序已过期, 该错误适用于使用 version 的情况
  [ErrorCode.GroupRankInvalid]: 'common.error.rank_of_group_invalid',
  // 1100005
  // error option no found
  // 不存在的option
  [ErrorCode.OptionInvalid]: 'common.error.option_invalid',
  // 1100006
  // error option exceed the limit size
  // 超出option数量的上限
  [ErrorCode.OptionsExceedLimit]: 'common.error.maximum_30_options_under_group',
  // 1100007
  // -
  // -
  [ErrorCode.None]: 'common.label.none',
  // 1100008
  // error_group_no_found
  // 找不到 Option Group
  [ErrorCode.GroupNoFound]: 'common.error.group_invalid',
  // 1100009
  // error_group_repeated
  // group 重复
  [ErrorCode.GroupRepeated]: 'common.error.duplicate_group',
  // 1100010
  // error_group_existed
  // group 已存在
  [ErrorCode.GroupExisted]: 'common.error.group_already_exists',
  // 1100011
  // error_option_name_too_long
  // option_name太长
  [ErrorCode.OptionNameTooLong]: 'common.error.maximum_30_characters',
  // 1100012
  // error_option_group_name_too_long
  // option_group_name太长
  [ErrorCode.OptionGroupNameTooLong]: 'common.error.maximum_30_characters',
  // 1100013
  // error_option_repeated
  // option 重复
  [ErrorCode.OptionRepeated]: 'common.error.duplicate_option',
  // 1100014
  // error_option_existed
  // option 已存在
  [ErrorCode.OptionExisted]: 'common.error.option_already_exists',
  // 1100015
  // error_produce_kitlog
  // 操作日志写入失败
  [ErrorCode.ErrorProduceKitLog]: 'common.error.check_network',
  // 1100016
  // Invalid select limits, need more available option(s).
  // group_select_control 与有效Options数不一致
  [ErrorCode.ErrorSelectControlNotMatch]:
    'edit_option_group.warn.select_limits',
  // 1100017
  // error dish in promotion
  // 菜品正在促销，禁止修改
  [ErrorCode.DishInPromotion]: 'common.error.check_network',
  // 1100018
  // error dish price invalid
  // 菜品价格低于折扣价
  [ErrorCode.PriceHigherThanPromotionPrice]:
    'common.error.price_higher_than_promotion_price',
  // 1134016
  // dish price smaller than discount price
  // 菜品价格低于flashSale折扣价
  [ErrorCode.PriceHigherThanFlashSalePrice]:
    'common.error.price_higher_than_promotion_price',
  // 1100019
  // error dish sale time invalid
  // 菜品售卖时间不合法
  [ErrorCode.DishSaleTimeInvalid]: 'common.error.check_network',
  // 1100020
  // 	error dish sale time not set
  // 菜品售卖时间未设置
  [ErrorCode.TimeSaleNotSet]: 'common.error.set_time_sale',
  // 1100021
  // error whitelist option exceed the limit size
  // Option数量超过白名单Store Option Group限制
  [ErrorCode.WhitelistOptionsExceedLimit]:
    'common.error.maximum_whitelist_options',
  // 1100022
  // error whitelist group exceed the limit size
  // Option Group数量超过白名单Store限制
  [ErrorCode.WhitelistGroupsExceedLimit]:
    'common.error.maximum_whitelist_groups',
  // 1100023
  // err whitelist dish exceed the limit size
  // 白名单店铺dish数量超出上限
  [ErrorCode.WhitelistDishExceedLimit]: 'common.error.check_network',
  // 1100024
  // error time for sales per dish exceed the limit size
  // 菜品的售卖时间段数量超出上限
  [ErrorCode.TimeSalesExceedLimit]: 'common.error.check_network',
  // 1100025
  // error repeated time period
  // 菜品售卖时间重叠
  [ErrorCode.ErrorRepeatedTimePeriod]: 'common.error.check_network',
  // 1100026
  // error dish rank invalid
  // 菜品rank不合法
  [ErrorCode.ErrorDishRankInvalid]: 'common.error.check_network',
  // 1100027
  // dish duplicated
  // 上传菜品重复
  [ErrorCode.DishDuplicated]: 'common.error.check_network',
  // 1100028
  // set dish option group binding failed
  // dish绑定option group失败
  [ErrorCode.SetDishOptionGroupBindingFailed]: 'common.error.check_network',
  // 1100029
  // error option group remark too long
  // option group remark 太长
  [ErrorCode.ErrorOptionGroupRemarkTooLong]: 'common.error.check_network',
  // 1100030
  // dish binding option count exceed limit
  // Dish绑定的Option Group超过上限
  [ErrorCode.DishBindingOptionCountExceedLimit]: 'common.error.check_network',
  // 1100031
  // submit qc to cqcp platform failed
  // 提交qc平台失败
  // save qc record failed
  [ErrorCode.SubmitQcFailed]: 'common.error.group_submit_qc_fail',
  // 1100032
  // 保存qc记录失败
  [ErrorCode.SaveEditRecordFailed]: 'common.error.group_save_qc_fail',
  // 1100033
  // invalid save request for previous saving process
  // 提交保存请求失败，因为正在处理上一次提交的记录
  [ErrorCode.SaveQcFailedForPreviousSavingProcess]:
    'common.error.group_save_qc_fail_for_process',
  // 1100034
  // edit record not found
  // edit record not found
  [ErrorCode.EditRecordNotFound]: 'common.error.check_network',
  // 1100035
  // forbidden modifiy listing_status/shelve for qc frozen
  // qc冻结，禁止变更上下架
  [ErrorCode.ForbiddenModifiyListingStatusShelveForQcFrozen]:
    'common.error.qc_frozen_change_display_status',
  // 1100036
  // price's edit times exceed the limit times
  // 菜品价格编辑频次达到上限
  [ErrorCode.PriceEditTimesExceedLimit]: 'common.error.check_network',
  // 1100037
  // whitelist store dish binding option count exceed limit
  // 白名单店铺Dish绑定的Option Group超过上限
  [ErrorCode.WhitelistDishBindingOptionCountExceedLimit]:
    'common.error.check_network',
  // 1100038
  // dish image mapping invalid
  // 菜品图片mapping不合法
  [ErrorCode.DishImageMappingInvalid]: 'common.error.check_network',
  // 1100039
  // edit record has closed
  // 编辑记录已经关闭
  [ErrorCode.EditRecordHasClosed]: 'common.error.check_network',
  // 1100040
  // dish price change exceed limit
  // 价格改动幅度超过限制
  [ErrorCode.PriceChangeExceedLimit]: 'common.error.check_network',
  // 1100047
  [ErrorCode.ItemInOnGoingPromotion]: 'dish.disabled.on_going_promotion',
  // 1100048
  // Please check again, those fields above must not include sensitive words
  // 包含敏感词
  [ErrorCode.DishHasSensitiveWords]: 'common.error.has_sensitive_words',
  // 1100049
  // Cannot edit items and toppings belonging to head shop
  // 不能更新主店同步菜品
  [ErrorCode.UpdateHeadDishFailed]: 'common.error.update_head_item',
  // 1100050
  // Dish Type not found
  // 菜品组不存在
  [ErrorCode.DishTypeNotFound]: 'common.error.dish_type_not_found',
  // 1100051
  // Error file format invalid
  // 上传图片格式不正确
  [ErrorCode.DishImageInvalid]: 'common.error.invalid_file_format',
  // 1100052
  // Please check again, those fields above must not include special character
  // 包含特殊字符
  [ErrorCode.DishHasSpecialCharacter]: 'common.error.invalid_string',
  // 1100053
  // Cannot update item price. Please contact our hotline to request for price change
  // 不允许修改菜品价格
  [ErrorCode.UpdateDishPriceFailed]: 'common.error.not_allowed_change_price',
  // 1100054
  // You cannot create more dishes when there’s an on-going promotion
  // 菜品被设置了促销，不允许修改
  [ErrorCode.UpdatePromotionDishFailed]:
    'common.error.restaurant_has_promotion',
  // 1100055
  // You cannot edit/delete gift item
  // 不允许修改赠菜信息
  [ErrorCode.UpdateGiftDishFailed]: 'common.error.not_allowed_update_gift',
  // 1100056
  // Cannot input description with mobile phone, please input again
  // 菜品描述不能为空
  [ErrorCode.DishDescInvalid]: 'common.error.menu_description_invalid',
  // 1100057
  // This dish has been blocked and you can not edit it.
  // 在 admin 端设置为 blocked dish 不允许编辑内容
  [ErrorCode.DishBlocked]: 'dish.blocked.error',
  // 1400004
  // qc abandon error
  // 废弃qc内容失败
  [ErrorCode.AbandonQCFail]: 'common.error.check_network',
  /** 设置节假日价格失败 1100060 */
  [ErrorCode.SetHolidayPriceError]: 'common.error.check_network',
  /** 节假日价格 时间已到期 不能查看或设置  1100062 */
  [ErrorCode.HolidayPriceExpirationError]:
    'common.error.holiday_price_expiration',
  /** 设置节假日价格 最大 Dish/options 限制  1100063 */
  [ErrorCode.HolidayPriceLimitItemsError]:
    'common.error.holiday_price_limit_items',
  /** 设置节假日价格 最大 Dish/options 限制（同步子店时）  1100063 */
  [ErrorCode.HolidayPriceLimitSyncItemsError]:
    'common.error.holiday_price_sync_limit_items',
} as const

export const ErrorCodeMessageMapping = {
  ...ErrorCodeMessageMappingAuth,
  ...ErrorCodeMessageMappingShop,
  ...ErrorCodeMessageMappingSellerAPI,
  ...ErrorCodeMessageMappingItem,
}

export const ErrorMessageFallback = 'common.error.something_wrong' as I18nKey
