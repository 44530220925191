/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 8v15.5h-5V8h5zM22.5 27.5v5.001h-5V27.5h5z"
      fill="#fff"
    />
  </svg>
)
const Memo = memo(SvgWarning)
export default Memo
