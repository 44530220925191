import qs from 'qs'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import {
  addOfflineTokenInterceptor,
  addSapRequestInterceptor,
  addServiceHeaderRequestInterceptor,
  camelCaseRequestInterceptor,
  camelCaseResponseInterceptor,
  processResponseInterceptor
} from './interceptors'

type RequestConfig = AxiosRequestConfig | string

export const instance = axios.create({
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { sort: (a, b) => a.localeCompare(b) }),
})

// #region 请求拦截  执行顺序：后进先出

// 添加 Sap 请求头
instance.interceptors.request.use(addSapRequestInterceptor)

// 添加 OfflineToken 请求头
instance.interceptors.request.use(addOfflineTokenInterceptor)

// 数据转换
instance.interceptors.request.use(camelCaseRequestInterceptor)

// 添加 Header 字段
instance.interceptors.request.use(addServiceHeaderRequestInterceptor)

// #endregion 请求拦截  执行顺序：后进先出

// #region 响应拦截  执行顺序：先进先出

// 数据转换
instance.interceptors.response.use(camelCaseResponseInterceptor)
// 数据处理
instance.interceptors.response.use(processResponseInterceptor)

// #endregion 响应拦截  执行顺序：先进先出

const request = async (config: RequestConfig) => {
  return instance(config as AxiosRequestConfig)
}

export default request
