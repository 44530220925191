export const COOKIE_CACHE_KEYS = {
  clientToken: 'tob_token',
  clientEntityId: 'WALLET_SESSION_ENTITY_ID',
  requestToken: 'shopee_tob_token',
  requestEntityId: 'shopee_tob_entity_id',
  requestFrom: 'shopee_request_from',
  sessionId: 'shopee_tob_session_id',
  pfb: 'SPC_PFB_FOODY_SELLER',
  entityId: 'currentStoreId',
}
