// 在 transform.ts 中调用，辅助转换的函数
import {
  DayIntervalSecondsEnd,
  Second,
  SecondIntervalMilliseconds,
  UnixTime,
  UnixTimeMs,
} from '@/constants/common.constant'
import { DishSaleDayVN, SyncableItemVN } from './schema.vn'
import { weekBitDayMapVN } from '@/constants/menu.constant'

export enum ITEM_HEAD_ID {
  NOT_SYNC = 0,
}

export function secToMs(sec: UnixTime): UnixTimeMs {
  return sec * SecondIntervalMilliseconds
}

export function normalizeSaleDays(
  saleDays?: DishSaleDayVN[],
): DishSaleDayVN[] & { length?: number | 7 } {
  if (!Array.isArray(saleDays) || !saleDays.length) {
    return []
  }

  // Api may return incomplete without whole week days, we should complement it
  const weekDaySaleMap = new Map()
  saleDays.forEach((s) => weekDaySaleMap.set(Number(s.weekday), s))

  return Object.values(weekBitDayMapVN).map((day) => {
    return (
      weekDaySaleMap.get(day) || {
        // Day not exist means all day
        weekday: day,
        timeForSales: [{ startTime: 0, endTime: DayIntervalSecondsEnd }],
      }
    )
  })
}

export type SecondFromDayStart = Second

export function normalizeSyncItemId(item: SyncableItemVN) {
  return item.headId === ITEM_HEAD_ID.NOT_SYNC || !item.headId ? undefined : String(item.headId)
}
