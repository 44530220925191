export enum Environment {
  Test = 'test',
  Uat = 'uat',
  Staging = 'staging',
  Live = 'live',
}

export enum Country {
  id = 'id',
  my = 'my',
  th = 'th',
  vn = 'vn',
}

export enum Language {
  en = 'en',
  id = 'id',
  'ms-my' = 'msMy',
  'zh-my' = 'zhHans',
  'zh-Hans' = 'zhHans',
  th = 'th',
  vi = 'vi',
}

// 国家简称对应的域名变更部分
export const CidMap: Record<Country, string> = {
  id: 'co.id',
  my: 'com.my',
  th: 'co.th',
  vn: 'vn',
}

// Server
export const ServerEnvironmentMapping = {
  [Environment.Test]: 'foody.test.',
  [Environment.Uat]: 'foody.uat.',
  [Environment.Staging]: 'foody.staging.',
  [Environment.Live]: 'foody.',
}

export const OpenApiServerEnvironmentMapping = {
  [Environment.Test]: 'food-open.test.',
  [Environment.Uat]: 'food-open.uat.',
  [Environment.Staging]: 'food-open.staging.',
  [Environment.Live]: 'food-open.',
}

export const EnvironmentMapping = {
  [Environment.Test]: 'test.',
  [Environment.Uat]: 'uat.',
  [Environment.Staging]: 'staging.',
  [Environment.Live]: '',
}

export const ServerEnvironmentMappingVn = {
  [Environment.Test]: 'gmerchant.test.now.vn',
  [Environment.Uat]: 'gqamerchant.test.now.vn',
  [Environment.Staging]: 'gmerchant.stage.now.vn',
  [Environment.Live]: 'gmerchant.deliverynow.vn',
}

export const ServerCountryMapping = {
  [Country.my]: 'shopee.com.my',
  [Country.th]: 'shopee.co.th',
  [Country.vn]: 'shopee.vn',
  [Country.id]: 'shopee.co.id',
}
