import { RouteConfig } from '../interface'

const storeRoutes: RouteConfig[] = [
  {
    path: '/settings/shopee-food',
    redirect: '/settings/shopee-food/setting',
  },
  {
    path: '/settings/shopee-food/setting',
    component: () =>
      import(/* webpackChunkName: "Store-Settings" */ '@/pages/Settings'),
  },
  {
    path: '/settings/shopee-food/business-hours',
    component: () =>
      import(
        /* webpackChunkName: "Store-BusinessHours" */ '@/pages/Settings/BusinessHours/HoursSettings'
      ),
  },
]

export default storeRoutes
