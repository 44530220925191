import useRequest, { UseFetchOptions } from '@/hooks/useRequest'
import * as ISchemaStore from './data.d'
import * as StoreApiConfig from './api.config'

export * from './data.d'

/**
 * 查询单个店铺信息
 */
export function useFetchMerchantDetail(
  options?: UseFetchOptions<ISchemaStore.IMerchantDetailResponse, [ISchemaStore.IMerchantDetailRequest]>
) {
  return useRequest<ISchemaStore.IMerchantDetailResponse, [ISchemaStore.IMerchantDetailRequest]>(
    (params: ISchemaStore.IMerchantDetailRequest) => ({
      url: '/api/seller/merchants',
      method: 'GET',
      params,
    }), {
    manual: true,
    ...options,
  })
}

/**
 * 查询店铺信息
 */
export function useFetchStoreList(
  options?: UseFetchOptions<ISchemaStore.IStoreListResponse, [ISchemaStore.IStoreListRequest]>,
) {
  const { service, formatResult } = StoreApiConfig.getConfigFetchStoreList()

  return useRequest<any, [ISchemaStore.IStoreListRequest], ISchemaStore.IStoreListResponse>(service, {
    defaultLoading: true,
    ...options,
    formatResult,
  })
}

/**
 * 查询店铺信息
 */
export function useFetchStoreDetail(
  options?: UseFetchOptions<ISchemaStore.IStoreDetailResponse, []>,
) {
  const { service, formatResult } = StoreApiConfig.getConfigFetchStoreDetail()

  return useRequest<any, [], ISchemaStore.IStoreDetailResponse>(service, {
    defaultLoading: true,
    ...options,
    formatResult,
    useAllSettled: true,
  })
}

/**
 * 查询子店铺列表
 */
export function useFetchStoreBranchList(
  options?: UseFetchOptions<ISchemaStore.IStoreFetchBranchListResponse, []>,
) {
  const { service, formatResult } = StoreApiConfig.getFetchStoreBranchListConfig()

  return useRequest<any, [], ISchemaStore.IStoreFetchBranchListResponse>(service, {
    ...options,
    formatResult,
  })
}
