export enum TimeStandard {
  UTC = 'UTC',
  /** 印度尼西亚西部时间，UTC+7 */
  WIB = 'WIB',
  /** 印度尼西亚中部时间，UTC+8 */
  WITA = 'WITA',
  /** 印度尼西亚东部时间，UTC+9 */
  WIT = 'WIT',
}

export enum TimezoneOffset {
  UTC7 = 7,
  UTC8 = 8,
  UTC9 = 9,
}

export const TimezoneOffsetText = {
  [TimezoneOffset.UTC7]: 'UTC+7',
  [TimezoneOffset.UTC8]: 'UTC+8',
  [TimezoneOffset.UTC9]: 'UTC+9',
}
