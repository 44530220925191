import { memo, useMemo } from 'react'
import { t } from '@/utils/i18n'
import { formatTimestamp } from '@/utils/time.util'
import { CancelSourceLabels, OrderStatus } from '@/constants/order.constant'
import { getCancelReasonText } from '../../utils'
import { BasicInfoProps, BasicRenderGroup } from '../../data'
import { OrderStatusType, TransactionType } from '../../constants'
import { localeConfig } from '@/locale'
import './index.less'

/**
 * 基础信息模块
 * 包含: Store ID/Store Name/Transaction Type/Transaction ID/Order Submitted Time/...
 * @param {BasicInfoProps} { order, orderStatus, timezone }
 * @return {*}
 */
const BasicInfo = ({ order, status }: BasicInfoProps) => {
  const timezone = useMemo(() => {
    // return order?.storeLocationGroups[0]?.timezone || localeConfig.defaultTimezone
    return localeConfig.defaultTimezone
  }, [])
  const groups = useMemo(
    () =>
      [
        {
          key: '1',
          data: [
            {
              key: t('transaction_detail.label.store_id'),
              value: order.storeId,
            },
            {
              key: t('transaction_detail.label.store_name'),
              value: order.storeName,
            },
            {
              key: t('transaction_detail.label.transaction_type'),
              value:
                order.transactionType === TransactionType.selfPickup
                  ? t('transaction_detail.value.self_pickup_order')
                  : t('transaction_detail.value.delivery_order'),
            },
          ],
        },
        {
          key: '2',
          data: [
            {
              key: t('transaction_detail.label.transaction_id'),
              value: order.id,
            },
            {
              key: t('transaction_detail.label.order_submitted_time'),
              value: formatTimestamp(order.createTime, timezone),
            },
            {
              key: t('transaction_detail.label.order_pickup_time'),
              value: formatTimestamp(order.deliveryPickupTime, timezone),
              validator: () => {
                return order.deliveryPickupTime !== '0'
              },
            },
            {
              key: t('transaction_detail.label.order_delivered_time'),
              value: formatTimestamp(order.deliveryCompleteTime, timezone),
              validator: () => {
                return (
                  [OrderStatus.Completed, OrderStatus.DeliveryCompleted].includes(
                    order.orderStatus,
                  ) && order.deliveryCompleteTime !== '0'
                )
              },
            },
            {
              key: t('transaction_detail.label.cancelled_by'),
              value: CancelSourceLabels[order.cancelSource],
              validator: () => {
                return status.key === OrderStatusType.Cancelled
              },
            },
            {
              key: t('transaction_detail.label.order_cancelled_time'),
              value: formatTimestamp(order.cancelTime, timezone),
              validator() {
                return status.key === OrderStatusType.Cancelled
              },
            },
            {
              key: t('transaction_detail.label.cancellation_reason'),
              value: getCancelReasonText(order.cancelReason),
              validator() {
                return status.key === OrderStatusType.Cancelled
              },
            },
          ],
        },
      ] as BasicRenderGroup[],
    [order, status, timezone],
  )
  return (
    <>
      {groups.map((group) => (
        <ul className="transaction-info" key={group.key}>
          {group.data.map(
            (item) =>
              (!item?.validator || item?.validator?.()) && (
                <li className="transaction-info-item" key={item.key}>
                  <div className="transaction-info-key">{item.key}</div>
                  <div className="transaction-info-val">{item.value}</div>
                </li>
              ),
          )}
        </ul>
      ))}
    </>
  )
}

export default memo(BasicInfo)
