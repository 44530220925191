import { AppStoreId } from '@/constants/app.constant'
import { StoreIdDataStorageKey } from '@/containers/StoreSelector/StoreSelector'
import '@shopee/tracking-bootstrap'
import { ITrackingSDK } from '@shopee/tracking-bootstrap'

export enum TrackOperationType {
  VIEW = 'view',
  CLICK = 'click',
  IMPRESSION = 'impression',
  ACTION = 'action',
  ACTION_PARTNER_SEARCH = 'action_partner_search',
}

export enum TrackPageType {
  PARTNER_MENU_MANAGEMENT = 'partner_menu_mgmt',
  PARTNER_MENU_MANAGEMENT_CREATE_DISH = 'partner_menu_mgmt_create_dish',
  PARTNER_MENU_MANAGEMENT_EDIT_DISH = 'partner_menu_mgmt_edit_dish',
  PARTNER_MENU_MANAGEMENT_CREATE_OPTION = 'partner_menu_mgmt_create_option_group',
  PARTNER_MENU_MANAGEMENT_EDIT_OPTION = 'partner_menu_mgmt_edit_option_group',
}

interface TrackData {
  operation: TrackOperationType
  pageType?: string
  targetType?: string
  pageSection?: string
  data?: any
}

let tracker: ITrackingSDK<any>

function generateCommon() {
  return {
    store_id: AppStoreId.current,
  }
}

export function initTracker() {
  if (typeof window.trackingSDK?.ubt === 'function') return
  window.trackingSDK.init(['ubt'], (name: string) => {
    if (name === 'ubt') {
      return {
        platform: 'pc_web',
        appId: 12, // appId 为本项目在 tms 系统上的编号
        env: process.env.APP_ENV || 'test',
        locale: process.env.APP_REGION || 'id',
        enableAutoTrack: false,
      }
    }
  })
  tracker = window.trackingSDK
}

export function track(trackData: TrackData) {
  const finalData = {
    ...trackData,
    data: {
      ...trackData.data,
      ...generateCommon(),
    },
  }
  if (typeof tracker?.ubt === 'function') {
    return tracker?.ubt(
      'trackEvent',
      finalData.operation,
      finalData.pageType,
      finalData.pageSection,
      finalData.targetType,
      'pc_web',
      finalData.data,
    )
  }
}

export function setTrackerUserId(userId?: number) {
  if (!userId) return
  tracker?.ubt?.('setUserId', userId)
}
