import { AppCountry } from '@/constants/app.constant'
import { transformDefault } from '@/utils/transform.util'
import * as ISchemaStore from './data.d'
import * as transformStore from './transform'

function getConfigFetchStoreListID() {
  return {
    service: (payload: ISchemaStore.IStoreListRequest) => ({
      url: '/api/seller/stores',
      method: 'POST',
      data: payload,
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchStoreListVN() {
  return {
    service: (payload: ISchemaStore.IStoreListRequest) => ({
      url: '/api/v5/seller/store/get_basic_infos_for_partner_web',
      method: 'POST',
      data: transformStore.transformStoreListRequest(payload),
    }),
    formatResult: transformStore.transformStoreListResponse,
  }
}

export function getConfigFetchStoreList() {
  const getRequestConfig = AppCountry.isVn ? getConfigFetchStoreListVN : getConfigFetchStoreListID

  return getRequestConfig()
}

function getConfigFetchStoreDetailID() {
  return {
    service: () => ({
      url: '/api/seller/store',
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchStoreDetailVN() {
  return {
    service: () => [
      {
        method: 'GET',
        url: '/api/v5/seller/store/get_basic_info',
      },
      {
        method: 'POST',
        url: '/api/v5/seller/store/settings-page/get',
      },
      {
        method: 'GET',
        url: '/api/v5/seller/store/opening-status/refact/get',
      },
    ],
    formatResult: transformStore.transformStoreDetailResponse,
  }
}

export function getConfigFetchStoreDetail() {
  const getRequestConfig = AppCountry.isVn
    ? getConfigFetchStoreDetailVN
    : getConfigFetchStoreDetailID

  return getRequestConfig()
}

function getFetchStoreBranchListConfigID() {
  return {
    service: () => Promise.resolve({ code: 0, msg: '', data: { branches: [] } }),
    formatResult: transformDefault,
  }
}

function getFetchStoreBranchListConfigVN() {
  return {
    service: () => ({
      method: 'GET',
      url: '/api/v5/seller/store/branches',
    }),
    formatResult: transformDefault,
  }
}

export function getFetchStoreBranchListConfig() {
  const getRequestConfig = AppCountry.isVn
    ? getFetchStoreBranchListConfigVN
    : getFetchStoreBranchListConfigID

  return getRequestConfig()
}
