import React, { useState, useMemo, CSSProperties, useEffect } from 'react'
import './index.less'
import { useFetchTransactionDetail } from '@/services/transaction'
import { OrderStatus, TransactionStatus } from '@/constants/order.constant'
import { calcTransactionAmount } from './utils'
import { notify } from '@/components/Notify'
import { t } from '@/utils/i18n'
import BasicInfo from './components/BasicInfo'
import AdaptiveModal from '@/components/AdaptiveModal'
import { OrderStatusType } from './constants'
import OrderItems from './components/OrderItems'
import { OrderType, TransactionDetailProps } from './data'
import BreakDown from './components/BreakDown'
import StatusTag from './components/StatusTag'
import Price from './components/Price'
import { OrderDetailResponse, TransactionDetailResponse } from '@/services/transaction/data'
import { AppCountry } from '@/constants/app.constant'
import { withSelectedStore } from '@/context/store'

const TransactionDetail = ({
  transactionId: orderId,
  storeId,
  storeName,
  transactionType,
  onClose,
}: TransactionDetailProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const {
    data: transactionDetailRes,
    run: getTransactionDetail,
    loading,
  } = useFetchTransactionDetail({
    onError: () => {
      notify.error({
        title: t('common.title.error'),
        message: t('common.message.something_wrong'),
        duration: 3,
      })
      setIsModalVisible(false)
      setTimeout(() => {
        onClose?.()
      }, 3500)
    },
  })

  useEffect(() => {
    setIsModalVisible(true)
    getTransactionDetail(orderId, storeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 将两种不同的接口返回类型转换成统一的数据结构
  const orderInfo = useMemo(() => {
    const info = {
      storeId: storeId.toString(),
      storeName,
      id: orderId,
    }
    if (AppCountry.isTh) {
      const data = (transactionDetailRes?.data || {}) as OrderDetailResponse
      return {
        ...info,
        createTime: data.orderSubmittedTime,
        deliveryPickupTime: data.orderPickupTime,
        deliveryCompleteTime: data.orderDeliveredTime,
        status: data.status,
        orderStatus: data.orderStatus,
        cancelSource: data.cancelledBy,
        cancelReason: data.cancelReason,
        cancelTime: data.orderCancelledTime,
        items: data.orderItems,
        transactionType, // 此字段可以用MIS获取，也可以从接口获取（for TH region）
        statistics: {
          foodOriginalPrice: data.foodOriginalPrice,
          itemDiscounts: data.itemDiscounts,
          flashSaleDiscounts: data.flashSaleDiscounts,
          surchargeFee: data.surchargeFee,
          merchantSubsidyVoucher: data.merchantSubsidyVoucher,
          shippingSubsidyVoucher: data.shippingSubsidyVoucher,
          amount: data.amount,
          orderValue: data.orderValue,
          promotionSubsidy: data.promotionSubsidy,
          itemDiscountAmount: data.itemDiscountAmount,
          merchantPrepaidSubsidy: data.merchantPrepaidSubsidy,
        },
      } as OrderType
    } else {
      const { order } = (transactionDetailRes?.data || { order: {} }) as TransactionDetailResponse
      return {
        ...info,
        transactionType,
        createTime: order.createTime,
        deliveryPickupTime: order.deliveryPickupTime,
        deliveryCompleteTime: order.deliveryCompleteTime,
        orderStatus: order.status,
        cancelSource: order.cancelSource,
        cancelReason: order.cancelReason,
        cancelTime: order.cancelTime,
        amount: order.amount,
        items: order.items,
      } as OrderType
    }
  }, [orderId, storeId, storeName, transactionType, transactionDetailRes?.data])

  const orderStatusMap = useMemo(
    () => ({
      [OrderStatusType.Completed]: {
        text: t('transaction_detail.status.completed'),
        className: 'transaction-completed',
        key: OrderStatusType.Completed,
      },
      [OrderStatusType.Cancelled]: {
        text: t('transaction_detail.status.cancelled'),
        className: 'transaction-cancelled',
        key: OrderStatusType.Cancelled,
      },
      [OrderStatusType.Processing]: {
        text: t('transaction_detail.status.processing'),
        className: 'transaction-processing',
        key: OrderStatusType.Processing,
      },
    }),
    [],
  )

  const transactionStatus = useMemo(() => {
    if (AppCountry.isTh) {
      switch (orderInfo.status) {
        case TransactionStatus.Completed:
          return orderStatusMap[OrderStatusType.Completed]
        case TransactionStatus.Cancelled:
          return orderStatusMap[OrderStatusType.Cancelled]
        default:
          return orderStatusMap[OrderStatusType.Processing]
      }
    }
    switch (orderInfo.orderStatus) {
      case OrderStatus.Completed:
      case OrderStatus.DeliveryCompleted:
        return orderStatusMap[OrderStatusType.Completed]
      case OrderStatus.Canceled:
        if (orderInfo.deliveryPickupTime !== '0') {
          return orderStatusMap[OrderStatusType.Completed]
        }
        return orderStatusMap[OrderStatusType.Cancelled]
      default:
        return orderStatusMap[OrderStatusType.Processing]
    }
  }, [orderInfo.deliveryPickupTime, orderInfo.status, orderInfo.orderStatus, orderStatusMap])

  const transactionAmount = useMemo(() => {
    if (AppCountry.isTh) {
      const statistics = orderInfo.statistics!
      return {
        orderValue: statistics.orderValue,
        foodOriginPrice: statistics.foodOriginalPrice,
        flashSaleDiscountsAmount: statistics.flashSaleDiscounts,
        itemDiscountAmount: statistics.itemDiscounts,
        surchargeFee: statistics.surchargeFee,
        totalAmount: statistics.amount,
        promotionSubsidy: statistics.promotionSubsidy,
        voucherMerchantSubsidyAmount: statistics.merchantSubsidyVoucher,
        voucherShippingSubsidyAmount: statistics.shippingSubsidyVoucher,
        // TH Prepaid subsidy的逻辑
        merchantPrepaidSubsidyAmount: statistics.merchantPrepaidSubsidy,
        directDiscountAmount: statistics.itemDiscountAmount,
      }
    } else {
      return orderInfo.amount
        ? calcTransactionAmount(orderInfo, transactionDetailRes?.data?.storeSettlement)
        : null
    }
  }, [orderInfo, transactionDetailRes?.data?.storeSettlement])

  const handleClose = () => {
    setIsModalVisible(false)
    onClose?.()
  }

  return (
    <AdaptiveModal
      className="modal-wrapper"
      title={<div className="dailog-title">{t('transaction_detail.title.shopee_food')}</div>}
      visible={isModalVisible}
      width={580}
      minHeight={600}
      footer={null}
      onClose={handleClose}
      loading={loading}
    >
      {!!orderInfo.id && !!transactionAmount && (
        <>
          <div className="content-header">
            <Price
              block
              price={transactionAmount.totalAmount}
              style={
                {
                  fontWeight: 700,
                  '--price-size': '36px',
                  lineHeight: 'unset',
                } as CSSProperties
              }
            />
            <StatusTag status={transactionStatus} />
          </div>
          <BasicInfo order={orderInfo} status={transactionStatus as any} />
          <OrderItems items={orderInfo.items || []} />
          {transactionAmount && <BreakDown data={transactionAmount} />}
        </>
      )}
    </AdaptiveModal>
  )
}

export default withSelectedStore(TransactionDetail)
