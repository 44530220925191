import { cancelReason } from '@foody/common/src/config/reasons'
import { t } from '@/utils/i18n'
import { I18nKey } from '@/assets/strings/i18n/i18n'

export enum OrderStatus {
  // 订单已创建
  Created = 0,
  // 订单已通过审核
  Approved = 100,
  // 订单已支付
  Paid = 200,
  // 商家已接单
  MerchantConfirmed = 300,
  // 骑手已接单
  DeliveryAssigned = 410,
  // 骑手已到店
  DeliveryArrived = 420,
  // 骑手已下单
  DeliveryReconfirmed = 425,
  // 骑手已取货
  DeliveryPickedUp = 430,
  // 骑手已送达
  DeliveryCompleted = 440,
  // 订单已完成
  Completed = 800,
  // 订单取消中
  Canceling = 900,
  // 订单已取消
  Canceled = 910,
}

export enum TransactionStatus {
  Completed = 4,
  Cancelled = 2,
  Processing = 1,
}

export enum ItemStatus {
  Origin = 0, // 未修改
  ModifiedPrice = 1, // 已修改价格
  ModifiedCount = 2, // 已修改商品数量
  ModifiedAll = 3, // 价格费用都改了
  Deleted = 4, // 已删除（数量为0）
}

// merchant端用到的未知原因，不在PM的原因配置范围内
const DefaultCancelReasonId = '0'

export type CancelReason = keyof typeof cancelReason | typeof DefaultCancelReasonId

export enum CancelSource {
  // 买家端
  Buyer = 1,
  // 运营端
  Admin = 2,
  // 骑手端
  Driver = 3,
  // 机器人
  Robot = 4,
  // 商家端
  Merchant = 5,
}

export enum RewriteSource {
  RewriteNone = 0, // 未修改(RewriteType != 0，但是 RewriteSource = 0，则表示骑手修改了订单。因为 rewrite source 是在支持商户改单后才新增的，历史数据该字段都为 0)
  RewriteBuyer = 1, // 买家(未启用)
  RewriteDriver = 3, // 骑收
  RewriteRobot = 4, // CRON(未启动)
  RewriteMerchant = 5, // 商家
}

// @see https://confluence.shopee.io/display/SPFOODY/5.3.2.7+Order+System#id-5.3.2.7OrderSystem-RewriteType
export enum RewriteType {
  Origin = 0, //	未修改
  Refund = 1, //	需退给买家差价
  Makeup = 2, //	需找买家补差价
  Modified = 3, //	骑手已改单但无需退补差价
}

// @see https://confluence.shopee.io/display/SPFOODY/5.3.2.7+Order+System#id-5.3.2.7OrderSystem-RewriteReason
export enum RewriteReason {
  ITEM_UNAVAILABLE = 201, // 已选商品无效
  BUYER_REQUIREMENT = 202, // 买家要求改单
  ITEM_PRICE_CHANGED = 203, // 商品价格未更新
  EXTRA_FEE = 204, // 有额外费用
  OTHERS = 250, // 其它原因
}

export enum ChargebackStatus {
  CREATED = 1, // 扣款单已创建
  COMPLETED = 2, // 扣款单已完成
  CANCEL = 3, // 扣款单已取消
}

export enum ChargebackMehod {
  Wallet = 1, // 钱包
  Invoice = 2, // 发票
}

export enum PaymentMethod {
  // 货到付款
  COD = 1,
  // ShopeePay
  ShopeePay = 2,
  // virtual account
  VirtualAccount = 3,
  // indomret
  Indomret = 4,
  // alfamart
  Alfamart = 5,
  // bank
  Bank = 6,
}

export const CancelSourceLabels = {
  get [CancelSource.Admin]() {
    return t('order_detail.cancel_source.admin')
  },
  get [CancelSource.Buyer]() {
    return t('order_detail.cancel_source.buyer')
  },
  get [CancelSource.Driver]() {
    return t('order_detail.cancel_source.driver')
  },
  get [CancelSource.Robot]() {
    return t('order_detail.cancel_source.system')
  },
  get [CancelSource.Merchant]() {
    return t('order_detail.cancel_source.merchant')
  },
}

export const DefaultCancelReason = {
  id: DefaultCancelReasonId,
  key: 'order.cancel_reason.0' as I18nKey,
}

export const merchantCancelReason = Object.entries(cancelReason).map(([key, item]) => {
  return {
    id: key,
    value: item.merchant as any,
  }
}) as {
  id: CancelReason
  value: {
    enable: boolean
    key: I18nKey
  }
}[]

export enum ListingStatus {
  Inactive = 0, // 下架
  Active = 1, // 上架
}

export enum SaleStatus {
  NotForSale = 0, // 不可售
  ForSale = 1, // 售卖中
}
