import * as React from 'react'
import * as MenuConstants from './constants'
import * as  SchemaMenuVn from './schema.vn'
import { IStoreBranch } from '../store'
import { Moment } from 'moment'

// event
export interface SortEnd {
  oldIndex: number
  newIndex: number
  collection: number | string
  isKeySorting: boolean
  nodes: HTMLElement[]
}
export type SortEvent = React.MouseEvent<any> | React.TouchEvent<any>
export type SortEndHandler = (sort: SortEnd, event: SortEvent) => void

export interface IEventEmitter {
  addEventListener: (type: 'click', listener: (e: React.MouseEvent<HTMLDivElement>) => any) => void
  removeEventListener: (
    type: 'click',
    listener: (e: React.MouseEvent<HTMLDivElement>) => any,
  ) => void
}

export interface ICategoryDish {
  //其实是要转为这个格式的？
  // index?: number
  id: string
  name?: string
  price?: string
  category?: string
  // catalogId?: string  这边都是要改的，如何呢？要不要都改了？就不考虑 ID 的？ 那就用 VN 的格式不就好了放在 VN 中先做呗
  timeForSales?: string
  listingStatus?: string
  available?: number
  picture?: string
  rank?: number
  syncItemGroupId?: boolean
}

/** 特殊售卖时间 */
export interface ISpecialSalDates {
  id: string
  startDate: string
  endDate: string
  timeForSales: ITimeForSale[]
  operationType?: number
}

// Time for Sale
export interface ITimeForSale {
  saleStartTime: number // 售卖开始时间 (相对秒数)
  saleEndTime: number // 售卖结束时间 (相对秒数)
}

//-----

/** 不可售时间 */
export interface IOutOfStockTime {
  startTime: string
  endTime: string
}

interface IRankRequest {
  id: string
  rank: number
}

export interface IOperationTimeItem {
  time: { start: Moment, end: Moment }[],
  is24Hour: boolean,
  isSwitchOn: boolean
}
// #region Dish

export interface IDish {
  // ID
  id: string //	菜品ID
  // 基础信息
  name: string // 菜品名称
  available: boolean // 可售状态
  description: string // 描述
  /** 当前价（原价 或者 PriceSlash 价格） */
  price: string // 价格
  /** 原价 */
  listPrice: string // 原价
  picture?: string // 图片
  // 关联信息
  // bindGroupCount: number //	总group数
  optionGroupCount: number //	有效的 group 数量
  // 由前端拼接
  optionGroups?: IOptionGroup[]
  // 销售信息
  // ratingTotal: number // 评价数
  // ratingGood: number // 好评数
  // ratingBad: number // 差评数
  salesVolume: number // 销量
  timeForSales: ITimeForSale[] // 菜品售卖时间（目前最多支持设置 3 个）
  operationTime: IOperationTimeItem[]
  saleStartTime: number // 售卖开始时间 (相对秒数)
  saleEndTime: number // 售卖结束时间 (相对秒数)
  saleWeekBit: number // 售卖时间周几生效 (返回为十进制, 需要转换为二进制按位运算) (最右第一位起为周日, 周一 ... 周六)
  inSaleTime: boolean // 菜品是否在售卖时间内 (根据 saleStartTime, saleEndTime, saleWeekBit 三个字段基于服务器时间计算得出)
  listingStatus: MenuConstants.DishListingStatus // 菜品上下架状态
  saleStatus: MenuConstants.DishSaleStatus // 菜品是否可售 (根据 available, inSaleTime, listingStatus 三个字段基于服务器时间计算得出)
  // 以下为详情页字段
  catalogId?: string
  catalogName?: string
  rank?: number // 菜品排序
  qcFrozen?: boolean
  failedQcRecordId?: string
  flashSalePrice: string
  syncItemGroupId?: string // 关联主店商品 id
  // 新增by https://confluence.shopee.io/pages/viewpage.action?spaceKey=SPFOODY&title=%5BVN+Migration%5D+Add+function+of+set+special+dates+in+time+for+sale
  specialSaleDates?: ISpecialSalDates[]
  outOfStockTime?: IOutOfStockTime

  // VN merge props
  saleDays?: SchemaMenuVn.DishSaleDayVN[]
  specialSaleTimes?: SchemaMenuVn.DishSpecialSaleTimeVN[]
  isPopular?: boolean
  isPending?: boolean // 审核中，除修改上架缺货功能，禁止操作其他
  isHidden?: boolean // gift 菜品，不允许修改 option group 绑定
  pictureId?: number // vn 上传图片后需要保留对应的 picture id 传给后端用于保存
  blocked?: boolean
  pictureType?: number // 图片类型，手动上传或者使用默认图；
  limitStatus?: number // 活动中菜品，不允许修改 option group 绑定
  /**
   * VN菜品上下架状态
   *
   * NOTE:
   * 业务逻辑中的页面会依赖于 listingStatus 逻辑，
   * 因此这里新增个 VN 字段单独记录 VN 的 listingStatus
   *
   * @region VN
   * @type {ListingStatus}
   */
  listingStatusVn?: SchemaMenuVn.ListingStatus
  /**
   * 是否隐藏
   *
   * @region VN
   * @type {?boolean}
   */
  isHidden?: boolean

  /**
   * 节假日价格信息
   * @region VN
   */
  holidayPriceInfo?: {
    price: number
    startTime: number
    endTime: number
  }
  /**
   * 是否有菜品同步到子店
   * @region VN
   */
  isGlobal?: boolean
  createTime?: number // fs campaign 新增

  // 新增Mart Category
  categoryId?: string
  categoryNameEn?: string
  categoryNameVi?: string
  rootCategoryId?: string
  rootCategoryNameEn?: string
  rootCategoryNameVi?: string
  categorySource?: CATEGORY_SOURCE
  /** PS 折扣信息 */
  // itemDiscount?: DishItemDiscount
}

export enum CATEGORY_SOURCE {
  algo = 1,
  merchant = 2,
  ops = 3,
}

export interface IDishListResponse {
  catalogs: ICatalog[]
}

export interface IDishDetailRequest {
  dishId: string
}

export interface IDishDetailResponse {
  dish?: IDish
}
export interface IOptionGroupBinding {
  groupId: string
  rank: number
}

export interface ICreateDishRequest {
  dish: Partial<IDish>
  optionGroupBindings?: IOptionGroupBinding[]

  /**
   * 需要同步到子店，仅在菜品进入审批流程时生效
   * @region VN
   */
  branchSyncInfo?: {
    syncBranchIds?: number[]
    deleteBranchIds?: number[]
  }
}

export interface ICreateDishResponse {
  id?: string
  isPending: boolean
  failedGroupIds: string[]
  autoQcResult: MenuConstants.QCResult
  failedFields: string[]
}

export interface IRankDishRequest {
  ranks: IRankRequest[]
}

export interface IUpdateDishAvailableRequest extends Pick<IDish, 'available' | 'outOfStockTime'> {
  catalogId: string
  dishId: string
}

export interface IUpdateDishAvailableResponse {
  isPending?: boolean
  errorMessage?: string
}

export interface IBatchUpdateDishAvailableResponse {
  failedDishIds: string[]
}

export interface ISyncBranchesRequest {
  id: number
  branchIds: number[]
  action: MenuConstants.ApplyBranchType
}

export interface IDishBranchInfo {
  id: number
  listingStatus: boolean
  price: number
  updateTime: number
  syncTime: number
}

export interface IOptionGroupBranchInfo {
  id: number
  name: string
  updateTime: number
  syncTime: number
}

export interface IDishBranch extends IStoreBranch {
  dishInfo?: IDishBranchInfo
  optionGroupInfo?: IOptionGroupBranchInfo
}

export interface IStoreBranchesResponse {
  branches: IDishBranch[]
}

// #endregion Dish

// #region Catalog

export interface ICreateCatalogRequest {
  name: string
}

export interface ICreateCatalogResponse {
  id: string
}

export interface IEditCataloResponse {
  isPending: boolean
}

export interface ICatalog {
  //store/dishes 请求
  id: string
  name?: string
  rank?: number
  // dishes?: (ICategoryDish & { flag?: { qcFrozen?: boolean } })[]
  dishes?: IDish[]
  syncItemGroupId?: string
  headConfigId?: string
}

export interface ICatalogListResponse {
  catalogs: ICatalog[]
}

export interface IRankCatalogRequest {
  ranks: IRankRequest[]
  // VN only
  isApplyBranch: boolean
}

export interface IRankCatalogResponse {
  isPending: boolean
}
// #endregion Catalog

// #region OptionGroup

export interface IOption {
  optionId?: string
  optionName: string
  available: boolean
  price: string
  rank: number
  syncItemGroupId?: string
  outOfStockTime?: IOutOfStockTime
}

export interface IOptionGroup {
  groupId: string
  groupName: string
  shelveState: boolean
  remark: string
  selectMin: number
  selectMax: number
  selectMode: MenuConstants.OptionSelectMode
  linkedDishCount: number
  options: IOption[]
  rank: number
  failedQcRecordId?: string
  qcFrozen?: boolean
  syncItemGroupId?: string
}

export interface ICreateOptionGroupRequest {
  optionGroup: Partial<IOptionGroup>
  options: (Omit<IOption, 'optionId' | 'optionName'> & { id?: string; name: string })[]
  dishBindingIds?: string[]
  /**
   * 需要同步到子店，仅在菜品进入审批流程时生效
   * @region VN
   */
  branchSyncInfo?: {
    syncBranchIds?: number[]
    deleteBranchIds?: number[]
  }
}

export interface ICreateOptionGroupResponse {
  id?: string
  isPending: boolean
  failedDishIds: string[]
  autoQcResult: MenuConstants.QCResult
  failedFields: string[]
}

export interface IUpdateOptionGroupAvailableRequest {
  groupId: string
  optionId: string
  available?: boolean
  outOfStockTime?: IOutOfStockTime
}

export type IOptionGroupDetailResponse = IOptionGroup

export interface IOptionGroupListResponse {
  groups: IOptionGroup[]
}

export interface IOptionGroupLinkedDishesResponse {
  catalogs: ICatalog[]
}

// #endregion OptionGroup

export interface IMenuRecentlyModifiedRequest {
  id: string
  recordType: MenuConstants.MenuRecentlyRecordType
}

export interface IMenuRecentlyModifiedResponse {
  modified: boolean
}

export interface IAbandonAuditContentRequest {
  contentId: number
  productId: MenuConstants.AbandonContentProductType
}

export type WeekNum = 1 | 2 | 3 | 4 | 5 | 6 | 7
export interface TimePeriodInfo {
  saleStartTime?: string | number | undefined
  saleEndTime?: string | number | undefined
}

interface IQCViolation {
  severity: MenuConstants.QCViolationSeverity
  name: string
  secondaryName: string
  description: string
  severityCode: number
}

export interface IQCDetail {
  status: MenuConstants.QCStatus
  closed?: MenuConstants.QCClosedStatus
  failedFields?: string[]
  violations?: IQCViolation[]
  operationType?: MenuConstants.QCOperationType
  qcContentSaved?: MenuConstants.QCContentSavedStatus
  entityType?: number
  dishEditRecord?: IDish
}

export interface IMenuMartCategoryDishResponse {
  categories: MartCategoryDishes<MartCategoryItem, SchemaMenuVn.DishVN>[]
}

export interface MartCategoryListItem extends MartCategoryItem {
  clientStatistics: CategoryDishStatistics
}

export type MartCategoryListData = MartCategoryDishes<
  // 暂时屏蔽
  MartCategoryListItem,
  MartCategoryListDishItem
>

export type MartCategoryData = MartCategoryDishes<MartCategoryItem, IDish> // 后者改为 IDish

export interface MartCategoryDishes<T extends object = MartCategoryItem, D extends object = IDish>
  extends T {
  /** category 子级列表 */
  subCategories?: MartCategoryDishes<T, D>[]
  /** 菜品列表 */
  dishes?: D[]
}

export interface MartCategoryItem {
  // catalogId: number
  catalogId: string // 改为 string
  categoryId: string
  // categoryId: number
  /** 该category的英文名称 */
  categoryNameEn: string
  /** 该category的越南文名称 */
  categoryNameVi: string
  /** 排序顺序 */
  displayOrder: number
  /**
   * 类型标识  为了让 Other 永远排到最后
   * 0: normal category
   * 1: 代表着永远排在最后的category
   */
  categoryType: MenuConstants.CategoryType
  syncItemGroupId?: string
  /** Category 同步的主店ID */
  headConfigId?: string
}

export interface MartCategoryDishResponse {
  catalogs: MartCategoryDishes[]
}

export interface CategoryDishStatistics {
  /** 所有的 dish 总数量 */
  allDishCount: number
  /** 搜索后的 dish 总数量 */
  totalDishCount: number
  /** 上架的 dish 总数量 (可售卖) */
  activeDishCount: number
  /** 下架的 dish 总数量 (不可售卖) */
  inactiveDishCount: number
  /** 无图片的 dish 总数量 */
  withoutPictureDishCount: number
}

export interface MartCategoryListDishItem {
  // 暂时屏蔽
  id: string
  /** 记录本地更新时间，用于刷新列表数据 */
  updateTime: number
}

export interface IMartCategory {
  id?: string
  name?: string
  nameEn?: string
  nameVi?: string
  mmsImgId?: string
  displayOrder?: number
  level?: number
  subCategories?: IMartCategory[]
  rootCategoryId?: string // 选择all category时需要回填
}

export interface IMartCategoryListResponse {
  categories: IMartCategory[]
}

export interface IMartRecommendCategoryListRequest {
  dishId?: string
  dishName: string
  dishDescription: string
}

export interface IMartRecommendCategory {
  categoryId: string
  categoryName: string
  rootCategoryName: string
  categoryNameVi?: string
  categoryNameEn?: string
  rootCategoryNameVi?: string
  rootCategoryNameEn?: string
  rootCategoryId: string
}

export interface IMartRecommendCategoryListResponse {
  recommendedOptionList: IMartRecommendCategory[]
}
