import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneVN {
  HOCHIMINH = 'Asia/Ho_Chi_Minh',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneVN.HOCHIMINH,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLowerCase()
    const config = {
      [TimezoneVN.HOCHIMINH.toLowerCase()]: {
        timezoneStandardText: TimeStandard.UTC,
        timezoneOffset: TimezoneOffset.UTC7,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC7],
        timezoneName: TimezoneVN.HOCHIMINH,
      },
    }
    return config[lowerTimezone as TimezoneVN] || config[TimezoneVN.HOCHIMINH.toLowerCase()]
  },

  policyUrl: 'https://shopeefood.vn/regulation-web',

  price: {
    regExp: /^(\d+)(\.\d{0,2})?$/,
    precision: 2
  },


  menu: {
    dish: {
      maxNameLength: 255,
      minPrice: 1,
      maxPrice: 10000000,
      maxBindingOptionGroup: 20,
      maxDescriptionLength: 250,
    },

    option: {
      minPrice: 0,
      maxPrice: 10000000,
      maxNameLength: 70
    },

    optionGroup: {
      linkDishLimit: 500,
      maxNameLength: 80
    },
  },
  assistanceContactNumber: '1500702',
}

export default localeConfig
