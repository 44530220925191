/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 21 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      d="M9 9.75a.75.75 0 01.75.75v6a.75.75 0 11-1.5 0v-6A.75.75 0 019 9.75zM15.75 10.501a.75.75 0 00-1.5 0v6a.75.75 0 101.5 0v-6zM12 9a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5A.75.75 0 0112 9z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 1.5A2.25 2.25 0 007.5 3.75v1.5H2.25a.75.75 0 000 1.5H4.5V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V6.75h2.25a.75.75 0 000-1.5H16.5v-1.5a2.25 2.25 0 00-2.25-2.25h-4.5zM15 5.25v-1.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75v1.5h6zm-9 1.5V19.5c0 .415.336.75.75.75h10.5a.75.75 0 00.75-.75V6.75H6z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgDelete)
export default Memo
