/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3 5.205h8.918v-1.5h3.924H5.3a2 2 0 00-2 2v14.198a2 2 0 002 2h12.833a2 2 0 002-2V8.6l1.005-1.018a1 1 0 00.001-1.403l-1.046-1.065a1 1 0 00-1.426 0L9.08 14.867a2 2 0 00-.479.793L7.79 18.2a.4.4 0 00.518.498l2.476-.898a2 2 0 00.742-.476l8.608-8.723v7.388h-1.5v3.914a.5.5 0 01-.5.5H5.3a.5.5 0 01-.5-.5V5.705a.5.5 0 01.5-.5z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgEdit)
export default Memo
