import useRequest, { UseFetchOptions } from '@/hooks/useRequest'
import * as ISchemaMenu from './data.d'

import * as MenuApiConfig from './api.config'
import { IStoreBranch } from '../store/data'

export * from './constants'
export * from './data.d'

// #region Dish

/**
 * 创建 菜品
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useCreateDish = (
  options?: UseFetchOptions<ISchemaMenu.ICreateDishResponse, [ISchemaMenu.ICreateDishRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigCreateDish()

  return useRequest<any, [ISchemaMenu.ICreateDishRequest], ISchemaMenu.ICreateDishResponse>(
    service,
    {
      manual: true,
      ...options,
      formatResult,
      formatError,
    },
  )
}

/**
 * 删除 菜品
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useDeleteDish = (options?: UseFetchOptions<unknown, [string]>) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigDeleteDish()

  return useRequest<any, [string], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 更新 dish 信息
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useUpdateDish = (
  options?: UseFetchOptions<
    ISchemaMenu.ICreateDishResponse,
    [string, ISchemaMenu.ICreateDishRequest]
  >,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateDish()

  return useRequest<any, [string, ISchemaMenu.ICreateDishRequest], ISchemaMenu.ICreateDishResponse>(
    service,
    {
      manual: true,
      ...options,
      formatResult,
      formatError,
    },
  )
}

/**
 * 查询 dish 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useDishList = (options?: UseFetchOptions<ISchemaMenu.IDishListResponse, []>) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchDishList()

  return useRequest<any, [], ISchemaMenu.IDishListResponse>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

/**
 * 查询 dish 详情
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchDishDetail = (
  options?: UseFetchOptions<ISchemaMenu.IDishDetailResponse, [ISchemaMenu.IDishDetailRequest]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchDishDetail()

  return useRequest<any, [ISchemaMenu.IDishDetailRequest], ISchemaMenu.IDishDetailResponse>(
    service,
    {
      ...options,
      formatResult,
    },
  )
}

/**
 * dish 排序
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useRankDish = (
  options?: UseFetchOptions<unknown, [string, ISchemaMenu.IRankDishRequest]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigRankDish()

  return useRequest<any, [string, ISchemaMenu.IRankDishRequest], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

/**
 * 更新 dish 可售状态
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useUpdateDishAvailable = (
  options?: UseFetchOptions<unknown, [ISchemaMenu.IUpdateDishAvailableRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateDishAvailable()

  return useRequest<any, [ISchemaMenu.IUpdateDishAvailableRequest], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 批量更新 dish 可售状态
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useBatchUpdateDishAvailable = (
  options?: UseFetchOptions<ISchemaMenu.IBatchUpdateDishAvailableResponse, [string[]]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigBatchUpdateDishAvailable()

  return useRequest<any, [string[]], ISchemaMenu.IBatchUpdateDishAvailableResponse>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

/**
 * 查询 dish 绑定的 OptionGroup 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchDishLinkedOptionGroups = (
  options?: UseFetchOptions<ISchemaMenu.IOptionGroupListResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchDishLinkedOptionGroups()

  return useRequest<any, [string], ISchemaMenu.IOptionGroupListResponse>(service, {
    ...options,
    formatResult,
  })
}

export const useSyncDishBranches = (
  options?: UseFetchOptions<IStoreBranch[], [ISchemaMenu.ISyncBranchesRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigSyncDishBranches()

  return useRequest<any, [ISchemaMenu.ISyncBranchesRequest], IStoreBranch[]>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

export const useFetchDishStoreBranches = (
  options?: UseFetchOptions<ISchemaMenu.IStoreBranchesResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchDishStoreBranches()

  return useRequest<any, [string], ISchemaMenu.IStoreBranchesResponse>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

// #endregion Dish

// #region Catalog

/**
 * 创建 catalog
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useCreateCatalog = (
  options?: UseFetchOptions<
    ISchemaMenu.ICreateCatalogResponse,
    [ISchemaMenu.ICreateCatalogRequest]
  >,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigCreateCatalog()

  return useRequest<any, [ISchemaMenu.ICreateCatalogRequest], ISchemaMenu.ICreateCatalogResponse>(
    service,
    {
      manual: true,
      ...options,
      formatResult,
      formatError,
    },
  )
}

/**
 * 更新 catalog 信息
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useUpdateCatalog = (
  options?: UseFetchOptions<
    ISchemaMenu.IEditCataloResponse,
    [string, ISchemaMenu.ICreateCatalogRequest, boolean]
  >,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateCatalog()

  return useRequest<
    any,
    [string, ISchemaMenu.ICreateCatalogRequest, boolean],
    ISchemaMenu.ICreateCatalogResponse
  >(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 删除 catalog
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useDeleteCatalog = (options?: UseFetchOptions<unknown, [string]>) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigDeleteCatalog()

  return useRequest<any, [string], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 查询 catalog 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchCatalogList = (
  options?: UseFetchOptions<ISchemaMenu.ICatalogListResponse, []>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchCatalogList()

  return useRequest<any, [], ISchemaMenu.ICatalogListResponse>(service, {
    ...options,
    formatResult,
  })
}

/**
 * catalog 排序
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useRankCatalog = (
  options?: UseFetchOptions<ISchemaMenu.IRankCatalogResponse, [ISchemaMenu.IRankCatalogRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigRankCatalog()

  return useRequest<any, [ISchemaMenu.IRankCatalogRequest], ISchemaMenu.IRankCatalogResponse>(
    service,
    {
      manual: true,
      ...options,
      formatResult,
      formatError,
    },
  )
}

// #endregion Catalog

// #region OptionGroup

/**
 * 创建 OptionGroup
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useCreateOptionGroup = (
  options?: UseFetchOptions<
    ISchemaMenu.ICreateOptionGroupResponse,
    [ISchemaMenu.ICreateOptionGroupRequest]
  >,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigCreateOptionGroup()

  return useRequest<
    any,
    [ISchemaMenu.ICreateOptionGroupRequest],
    ISchemaMenu.ICreateOptionGroupResponse
  >(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 删除 OptionGroup
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useDeleteOptionGroup = (options?: UseFetchOptions<unknown, [string]>) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigDeleteOptionGroup()

  return useRequest<any, [string], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 更新 OptionGroup 信息
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useUpdateOptionGroup = (
  options?: UseFetchOptions<
    ISchemaMenu.ICreateOptionGroupResponse,
    [
      string,
      ISchemaMenu.ICreateOptionGroupRequest,
      ISchemaMenu.ICreateOptionGroupRequest | undefined,
    ]
  >,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateOptionGroup()

  return useRequest<
    any,
    [
      string,
      ISchemaMenu.ICreateOptionGroupRequest,
      ISchemaMenu.ICreateOptionGroupRequest | undefined,
    ],
    ISchemaMenu.ICreateOptionGroupResponse
  >(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

/**
 * 更新 option group 可售状态 （仅 Staff 角色使用）
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useUpdateOptionGroupAvailable = (
  options?: UseFetchOptions<unknown, [ISchemaMenu.IUpdateOptionGroupAvailableRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateOptionGroupAvailable()

  return useRequest<any, [ISchemaMenu.IUpdateOptionGroupAvailableRequest], unknown>(service, {
    manual: true,
    formatError,
    ...options,
    formatResult,
  })
}

/**
 * 查询 OptionGroup 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchOptionGroupList = (
  options?: UseFetchOptions<ISchemaMenu.IOptionGroupListResponse, []>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchOptionGroupList()

  return useRequest<any, [], ISchemaMenu.IOptionGroupListResponse>(service, {
    ...options,
    formatResult,
  })
}

/**
 * 查询 OptionGroup 详情
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchOptionGroupDetail = (
  options?: UseFetchOptions<ISchemaMenu.IOptionGroupDetailResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchOptionGroupDetail()

  return useRequest<any, [string], ISchemaMenu.IOptionGroupDetailResponse>(service, {
    ...options,
    formatResult,
  })
}

/**
 * 查询 OptionGroup 关联的 Dish 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchOptionGroupLinkedDishes = (
  options?: UseFetchOptions<ISchemaMenu.IOptionGroupLinkedDishesResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchOptionGroupLinkedDishes()

  return useRequest<any, [string], ISchemaMenu.IOptionGroupLinkedDishesResponse>(service, {
    ...options,
    formatResult,
  })
}

/**
 * 查询 OptionGroup 关联的 Dish 列表
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useFetchOptionGroupLinkedMartDishes = (
  options?: UseFetchOptions<ISchemaMenu.IOptionGroupLinkedDishesResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchOptionGroupLinkedMartDishes()

  return useRequest<any, [string], ISchemaMenu.IOptionGroupLinkedDishesResponse>(service, {
    ...options,
    formatResult,
  })
}

export const useSyncOptionGroupBranches = (
  options?: UseFetchOptions<IStoreBranch[], [ISchemaMenu.ISyncBranchesRequest]>,
) => {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigSyncOptionGroupBranches()

  return useRequest<any, [ISchemaMenu.ISyncBranchesRequest], IStoreBranch[]>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

export const useFetchOptionGroupStoreBranches = (
  options?: UseFetchOptions<ISchemaMenu.IStoreBranchesResponse, [string]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigFetchOptionGroupStoreBranches()

  return useRequest<any, [string], ISchemaMenu.IStoreBranchesResponse>(service, {
    ...options,
    formatResult,
  })
}


// #endregion OptionGroup

/**
 * 查询最近是否被修改数据
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export function useFetchMenuRecentlyModified(
  options?: UseFetchOptions<
    ISchemaMenu.IMenuRecentlyModifiedResponse,
    [ISchemaMenu.IMenuRecentlyModifiedRequest]
  >,
) {
  const { service, formatResult } = MenuApiConfig.getConfigFetchMenuRecentlyModified()

  return useRequest<
    any,
    [ISchemaMenu.IMenuRecentlyModifiedRequest],
    ISchemaMenu.IMenuRecentlyModifiedResponse
  >(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

/**
 * 废弃 QC 平台内容
 *
 * @param {?UseFetchOptions} [options]
 * @returns {*}
 */
export const useAbandonAuditContent = (
  options?: UseFetchOptions<unknown, [ISchemaMenu.IAbandonAuditContentRequest]>,
) => {
  const { service, formatResult } = MenuApiConfig.getConfigAbandonAuditContent()

  return useRequest<any, [ISchemaMenu.IAbandonAuditContentRequest], unknown>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

// #region Mart

/**
 * 获取店铺排序后的 Category
 * 该接口与 fetchMartCategoryDishes 一致，但是不包含 dish 信息
 * @returns
 */
// export function fetchMartCategory() {
//   return get('/api/v5/seller/store/category/get_basic_infos', undefined, {
//     vmUtils: adapterMartCategoryDishes,
//   })
// }

export function useFetchMartCategoryDishes(options?: UseFetchOptions<ISchemaMenu.MartCategoryDishResponse, []>) {
  const { service, formatResult } = MenuApiConfig.getConfigFetchMartCategoryDish()

  return useRequest<any, [], ISchemaMenu.MartCategoryDishResponse>(service, {
    ...options,
    manual: true,
    formatResult,
  })
}

export function useFetchMartCategoryList(
  options?: UseFetchOptions<ISchemaMenu.IMartCategoryListResponse, []>,
) {
  const { service, formatResult } = MenuApiConfig.getConfigFetchMartCategoryList()

  return useRequest<any, [], ISchemaMenu.IMartCategoryListResponse>(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

export function useFetchMartRecommendCategoryList(
  options?: UseFetchOptions<
    ISchemaMenu.IMartRecommendCategoryListResponse,
    [ISchemaMenu.IMartRecommendCategoryListRequest]
  >,
) {
  const { service, formatResult } = MenuApiConfig.getConfigFetchMartRecommendCategoryList()

  return useRequest<
    any,
    [ISchemaMenu.IMartRecommendCategoryListRequest],
    ISchemaMenu.IMartRecommendCategoryListResponse
  >(service, {
    manual: true,
    ...options,
    formatResult,
  })
}

/**
 * 批量更新 Category 排序，不区分 L1 L2 L3
 * @param data 在同一层级被放到一起排序的category_ids数组。 category是可以基于L1层级或者某个L1下的所有L3进行排序，所以这里的子数组可以传所有L1，也可以传L1下的全部L3。
 * @param config
 * @returns
 */
export function useUpdateMartCategoryRank(
  options?: UseFetchOptions<any, [categoryIdsList: string[]]>,
) {
  const { service, formatResult, formatError } = MenuApiConfig.getConfigUpdateMartCategoryRank()

  return useRequest<any, [categoryIdsList: string[]], any>(service, {
    manual: true,
    ...options,
    formatResult,
    formatError,
  })
}

// #endregion OptionGroup
