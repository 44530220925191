/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.914 13.75c1.747 0 3.164-1.4 3.164-3.125 0-1.726-1.416-3.125-3.164-3.125-1.747 0-3.164 1.4-3.164 3.125 0 1.726 1.417 3.125 3.164 3.125zM15.89 31.967c.593.355 1.348.533 2.267.533 1.54 0 2.874-.322 4-.966 1.155-.666 2.103-1.41 2.844-2.23l-.4-.3c-.681.488-1.244.843-1.689 1.065-.444.2-.889.3-1.333.3-.593 0-.89-.244-.89-.733 0-.266.03-.533.09-.799.059-.289.148-.633.266-1.032l3.467-12.055-.444-.3-8.356 1.799-.044.366.71.266c1.127.378 1.556 1.021 1.29 1.932l-2.09 7.492a33.85 33.85 0 00-.4 1.665c-.118.555-.177 1.033-.177 1.432 0 .688.296 1.21.889 1.565z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 37c9.389 0 17-7.611 17-17S29.389 3 20 3 3 10.611 3 20s7.611 17 17 17zm0 3c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgInfoCircle)
export default Memo
