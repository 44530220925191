import { t } from "@/utils/i18n"

/** 店铺类型 */
export enum StoreType {
  /** 正常 */
  Normal = 1,
  /** 子店铺 */
  SubStore = 2,
  /** 总店 */
  HeadStore = 3,
}

/** 店铺营业状态 */
export enum StoreOpeningStatus {
  /** 忙碌 */
  Busy = 1,
  /** 关闭 */
  Closed = 2,
  /** 在线 */
  Pause = 3,
}

export enum StorePauseType {
  None = 1,
  SpecialMode = 2,
}

export enum StoreCloseType {
  ClosedByRegularHour = 1,
  ClosedBySpecialHour = 2,
  ClosedByPlatform = 3,
}

/** Store 为 inactive 的原因 */
export enum StoreStatusReason {
  /** 新开店铺 */
  Onboarding = 1,
  /** QMS - 永久关闭 */
  PermanentlyClosed = 2,
  /** QMS - 临时关闭 */
  TemporarilyClosed = 3,
  /** QMS - 欺诈 */
  Fraud = 4,
  /** 其他原因 */
  Others = 100,
}

/** Store 为 inactive 的触发场景 */
export enum StoreStatusReasonsScene {
  /** 管理员 触发 */
  Admin = 1,
  /** QMS - 永久关闭 触发 */
  QmsSuspend = 2,
  /** QMS - 临时关闭 触发 */
  QmsInactive = 3,
  /** MIS 触发 */
  Mis = 4,
}

/** Store 接单状态 */
export enum StoreActiveStatus {
  /** 正常 */
  Active = 1,
  /** 不活跃 */
  Inactive = 2,
}

/** 店铺是否即时备餐 */
export enum InstantPrepMode {
  /** 是 */
  Yes = 1,
  /** 否 */
  No = 0,
}

export const InstantPrepModeString = {
  get [InstantPrepMode.Yes]() {
    return t('instant_prep_mode.yes')
  },
  get [InstantPrepMode.No]() {
    return t('instant_prep_mode.no')
  },
}
/** 店铺接单模式 */
export enum OrderConfirmationMode {
  /** 手动确认 */
  Manually = 0,
  /** 自动确认 */
  Automatically = 1,
}

export const OrderConfirmationModeString = {
  get [OrderConfirmationMode.Manually]() {
    return t('order_confirmation_mode.manually')
  },
  get [OrderConfirmationMode.Automatically]() {
    return t('order_confirmation_mode.automatically')
  },
}

/** 店铺订单超时接单模式 */
export enum OvertimeOrderMode {
  /** 确认订单 */
  Confirm = 0,
  /** 拒绝订单 */
  Decline = 1,
  /** 不接单 */
  None = -1,
}

export const OvertimeOrderModeString = {
  get [OvertimeOrderMode.Confirm]() {
    return t('overtime_order_mode.confirm')
  },
  get [OvertimeOrderMode.Decline]() {
    return t('overtime_order_mode.decline')
  },
  get [OvertimeOrderMode.None]() {
    return t('overtime_order_mode.none')
  },
}

/** 钱包结算 */
export enum StoreSettleToEnum {
  /** 保留 */
  Unknow = 0,
  /** 商家 */
  Merchant = 1,
  /** 门店 */
  Store = 2,
}

/** 配送方式 */
export enum StoreDeliveryMode {
  /** ShopeeFood 平台配送 */
  Platform = 0,
  /** 商家自配送 */
  Self = 1,
}
