import { IDishFormValues } from "./interface"
import { convertSpaces } from "@/utils/common.util"
import { addPrecision, removePrecision } from "@foody/common"
import { AppCountry, AppLanguage } from "@/constants/app.constant"
import { ICreateDishRequest, IDish, IOutOfStockTime, IQCDetail, IOperationTimeItem } from "@/services/menu/data"
import { ApplyBranchType, DishListingStatus, QCErrorDialogResult } from "@/services/menu"
import { t } from "@/utils/i18n"
import { showAlertDialog } from "@/utils/dialog.util"
import { DayIntervalSecondsEnd, WeekBitMaskAllDay } from "@/constants/common.constant"
import { Language } from "@/constants/env.constant"
import { ErrorCode } from "@/hooks/useRequest/request.codes"

import moment from 'moment'
const defaultStart = moment('00:00', 'HH:mm')
const defaultEnd = moment('23:59', 'HH:mm')
const defaultOperationTime = [
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  },
  {
    time: [
      { start: defaultStart, end: defaultEnd },
    ],
    is24Hour: true,
    isSwitchOn: true
  }
]

export function getInitialDish() {
  const defaultDish: Partial<IDishFormValues> = {
    name: '',
    picture: '',
    description: '',
    available: true,
    price: '',
    listingStatus: DishListingStatus.Active,
    optionGroups: [],
    // syncItemGroupId: '',
    outOfStockTime: undefined,
  }

  if (AppCountry.isVn) {
    defaultDish.isPending = false
    defaultDish.pictureId = undefined
    defaultDish.operationTime = defaultOperationTime
  }

  return defaultDish as IDishFormValues
}

interface DishFormatToRequestOptions {
  allowBindingDishOptionGroup: boolean;
  allowMenuApplySubStore: boolean;
  appliedBranchIds?: number[]
}

export function formatToRequest(formValues: IDishFormValues, formatOptions: DishFormatToRequestOptions) {
  const {
    name,
    picture,
    pictureId,
    catalogId,
    price,
    available,
    description,
    optionGroups = [],
    outOfStockTime,
    listingStatus,
    timeForSale: sale,
    operationTime,
    categoryInfo,
    ...values
  } = formValues

  const payload: ICreateDishRequest = {
    dish: {
      name: convertSpaces(name?.trim()),
      price: addPrecision(price).toString(),
      description: convertSpaces(description?.trim()),
      picture,
      pictureId,
      catalogId,
      available,
      // saleStatus,
      outOfStockTime,
      // timeForSales: appliedHours,
      // saleWeekBit: appliedDays,
      categoryId: categoryInfo?.categoryId,
      rootCategoryId: categoryInfo?.rootCategoryId,
      categorySource: categoryInfo?.categorySource
    },
  }

  if (AppCountry.isVn) {
    payload.dish.saleDays = formatSaleDays(operationTime)
    // payload.dish.specialSaleTimes = sale?.specialSaleTimes // TODO待确认；

    const { allowBindingDishOptionGroup, allowMenuApplySubStore, appliedBranchIds } = formatOptions

    // @region VN: 该店铺将 Dish 和 OptionGroup 进行绑定
    if (allowBindingDishOptionGroup) {
      payload.optionGroupBindings = optionGroups.map((groupId, index) => ({ groupId, rank: index + 1 }))
    }

    // @region VN: 该店铺允许同步到子店
    if (allowMenuApplySubStore) {
      const {
        branchChangedTotal,
        applyBranchIds = [],
        removeBranchIds = [],
      } = getApplyBranchItem(allowMenuApplySubStore, values.applyBranchItem, appliedBranchIds)

      const branchSyncInfo = {
        syncBranchIds: applyBranchIds.length ? applyBranchIds : undefined,
        deleteBranchIds: removeBranchIds.length ? removeBranchIds : undefined,
      }

      if (branchChangedTotal > 0) {
        payload.branchSyncInfo = branchSyncInfo
      }
    }
  }

  if (isValidOutOfStockTime(outOfStockTime)) {
    payload.dish.available = true
  }

  return payload
}

/** 提交时获取同步子店状态 */
function getApplyBranchItem(allowMenuApplySubStore: boolean, applyBranchItem: IDishFormValues['applyBranchItem'] = {}, appliedBranchIds: number[] = []) {
  if (!allowMenuApplySubStore) {
    return { branchChangedTotal: 0 }
  }

  const { applyBranchType, applyBranchIds = [] } = applyBranchItem

  const removeBranchIds =
    applyBranchType === ApplyBranchType.Remove
      ? appliedBranchIds.reduce<number[]>((prev, id) => {
        if (applyBranchIds.includes(id)) {
          return prev
        }

        return [...prev, id]
      }, [])
      : []

  return {
    branchChangedTotal: applyBranchIds.length + removeBranchIds.length,
    applyBranchIds,
    removeBranchIds,
  }
}

function formatSaleDays(operationTime: IOperationTimeItem[]) {
  if (!operationTime) {
    return []
  }
  const all24Hour = operationTime.every((item) => item.is24Hour)
  const allOpen = operationTime.every((item) => item.isSwitchOn)
  if (all24Hour && allOpen) {
    return []
  }
  const midnightMoment = moment().clone().startOf('day')
  const result = operationTime?.map((item, index) => {
    if (!item || !item.time) {
      // 关闭的情况
      return {
        weekday: index === 0 ? 7 : index,
      }
    }
    const timeForSales = item.time?.map((timeItem) => {
      return {
        startTime: timeItem.start.second(0).diff(midnightMoment, 'seconds'),
        endTime: timeItem.end.second(59).diff(midnightMoment, 'seconds')
      }
    })
    return {
      weekday: index === 0 ? 7 : index,
      timeForSales
    }
  })
  return result

}

export function formatFromResponse(dish: IDish, applyBranchIds: number[]): IDishFormValues {
  const {
    name,
    picture,
    description,
    available,
    listPrice,
    catalogId = '',
    saleWeekBit,
    timeForSales,
    listingStatus,
    syncItemGroupId,
    outOfStockTime,
    specialSaleDates,
    pictureType = 0,

    // VN 融合字段
    isPending, // VN 有此属性表示禁止修改任何字段，只能设置缺货
    saleDays, // VN 使用 saleDays 数组表示售卖时间不使用 saleBit
    specialSaleTimes, //  VN 使用 specialSaleTimes 数组表示特殊售卖日期
    categoryId,
    categoryNameEn,
    categoryNameVi,
    rootCategoryId,
    rootCategoryNameEn,
    rootCategoryNameVi,
    categorySource,
  } = dish

  const values: Partial<IDishFormValues> = {
    name,
    picture,
    pictureType,
    price: removePrecision(listPrice),
    description,
    available,
    listingStatus,
    catalogId,
    syncItemGroupId,
    outOfStockTime,
  }

  if (AppCountry.isVn) {
    values.isPending = isPending

    if (rootCategoryId) {
      const categoryNames: (string | undefined)[] = []
      categoryNames.push(getMartCategoryName({ nameEn: rootCategoryNameEn, nameVi: rootCategoryNameVi }))
      categoryNames.push(getMartCategoryName({ nameEn: categoryNameEn, nameVi: categoryNameVi }))

      values.categoryInfo = {
        categoryId,
        rootCategoryId,
        categorySource,
        categoryName: categoryNames.join(' > ')
      }
    }

    values.applyBranchItem = { applyBranchIds }

    // values.timeForSale = { saleDays, specialSaleTimes, }
    const midnightMoment = moment().startOf('day')
    values.operationTime = (!saleDays || !saleDays.length) ? defaultOperationTime : saleDays.map((item) => {
      const itemConfig = { isSwitchOn: true } as IOperationTimeItem
      if (!item.timeForSales) {
        itemConfig.isSwitchOn = false
        return itemConfig
      }
      itemConfig.is24Hour = false
      itemConfig.time = item.timeForSales?.map((item) => {
        const startTime = midnightMoment.clone().add(item.startTime, 'seconds')
        const endTime = midnightMoment.clone().add(item.endTime, 'seconds')
        if (startTime.format('HH:mm') === "00:00" && endTime.format('HH:mm') === "23:59") {
          itemConfig.is24Hour = true
        }
        return {
          start: startTime,
          end: endTime,
        }
      })

      return itemConfig
    })
  } else {
    const timeForSale: IDishFormValues['timeForSale'] = {
      saleWeekBit,
      timeForSales,
      specialSaleDates,
    }

    if (saleWeekBit === undefined || !timeForSales) {
      timeForSale.saleWeekBit = WeekBitMaskAllDay
      timeForSale.timeForSales = timeForSales || [
        {
          saleEndTime: DayIntervalSecondsEnd,
          saleStartTime: 0,
        },
      ]
    }

    values.timeForSale = timeForSale
  }
  console.log('formatRes', values)
  return values as IDishFormValues
}

// 提交时要求有 outOfStockTime 对象时，dish / option 的 available 要提交 Available.AVAILABLE
// 但从后端获取 dish / option detail 详情时，接口会返回的 outOfStockTime 值有时是一个默认的 {} 空对象，会影响前端对 outOfStockTime 的判断
export function isValidOutOfStockTime(outOfStockTime?: IOutOfStockTime,) {
  return Boolean(
    outOfStockTime && outOfStockTime.startTime && outOfStockTime.endTime,
  )
}

type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T }

export type QCResultCallbacks = PartialRecord<
  QCErrorDialogResult,
  (qcDetail?: IQCDetail) => void
> & {
  others?: () => void
}

export const handleQCError = async (
  error: any,
  callbacks?: QCResultCallbacks,
) => {
  switch (error.code) {
    // 如果全部 dish 下架， VN 返回 自动关店 错误
    case ErrorCode.GetShopBaseFailed: {
      await showAlertDialog({
        title: t('common.title.warning'),
        content: error.message,
        okText: t('common.button.ok'),
      })
      break
    }
    default:
      await callbacks?.others?.()
      break
  }
}

export function isDeepEqual(value: any, other: any) {
  try {
    return JSON.stringify(value) === JSON.stringify(other)
  } catch (error) {
    return false
  }
}

export function getMartCategoryName({ nameEn, nameVi }: { nameEn?: string; nameVi?: string }) {
  return AppLanguage.current === Language.vi ? nameVi : nameEn
}


export function checkOpeningTime(operationTime: any, dayIndex: number) {
  const times = operationTime[dayIndex]?.time || []
  const newErrors: string[] = []

  for (let i = 0; i < times.length; i++) {
    const start1 = times[i]?.start
    const end1 = times[i]?.end
    if (start1 && end1 && !start1.isBefore(end1)) {
      newErrors.push(`Time slot ${i + 1} has an invalid range`)
    }
    if (times.length < 2) {
      return newErrors
    }
    for (let j = i + 1; j < times.length; j++) {
      const start2 = times[j]?.start
      const end2 = times[j]?.end
      if (start1 && end1 && start2 && end2) {
        if (
          (start1.isBefore(end2) && (end1.isAfter(start2) || end1.isSame(start2, 'minute'))) ||
          ((start2.isBefore(end1) || start2.isSame(end1, 'minute')) && end2.isAfter(start1))
        ) {
          newErrors.push(`Time slots ${i + 1} and ${j + 1} overlap`)
        }
      }
    }
  }
  return newErrors
}

export function validateTimeOverlap(operationTime: any) {
  let errors: string[] = []
  for (let dayIndex = 0; dayIndex < operationTime.length; dayIndex++) {
    const currentErrors = checkOpeningTime(operationTime, dayIndex)
    errors = [...errors, ...currentErrors]
  }
  return errors
}
