import type Api from '@/types/api'
import { addPrecision, removePrecision } from "@foody/common"
import * as SchemaMenuVn from './schema.vn'
import { DishSaleStatus, OptionSelectMode, DishListingStatus } from './constants'
import * as ISchemaMenu from './data.d'
import { normalizeSaleDays } from './menu.vm'
import { getMartCategoryName } from '@/pages/Menu/MainDish/Create/utils'

function msToSec(ms: number): number {
  return Math.floor(ms / 1000)
}

function secToMs(sec: number): number {
  return sec * 1000
}


interface ISyncableItem {
  headId?: number | string
}

function normalizeSyncItemId(item: ISyncableItem) {
  return item.headId === 0 || !item.headId ? undefined : String(item.headId)
}

export function isDuringOutOfStockTime(outOfStockTime?: ISchemaMenu.IOutOfStockTime) {
  // out of stock unset
  if (!outOfStockTime) return false

  // unavailable forever
  if (!outOfStockTime.startTime && !outOfStockTime.endTime) return true

  // other time
  const now = new Date().getTime()
  const start = Number(outOfStockTime.startTime)
  const end = Number(outOfStockTime.endTime)

  return start < now && now < end
}

/** 接口数据 转换成 页面数据 */
function fromOutOfStockTime(
  outOfStockInfo?: SchemaMenuVn.IStockInfo,
): ISchemaMenu.IOutOfStockTime | undefined {
  if (!outOfStockInfo?.fromTime || !outOfStockInfo?.toTime) {
    return
  }

  return {
    startTime: String(secToMs(outOfStockInfo.fromTime)),
    endTime: String(secToMs(outOfStockInfo.toTime)),
  }
}

/** 页面数据 转换成 接口数据 */
export function toOutOfStockTime(
  available: boolean,
  outOfStockInfo?: ISchemaMenu.IOutOfStockTime,
): SchemaMenuVn.IStockInfo {
  if (available && !outOfStockInfo) {
    return {
      stock: SchemaMenuVn.StockType.AVAILABLE,
    }
  }

  const outOfStockTime: Pick<SchemaMenuVn.IStockInfo, 'fromTime' | 'toTime'> = {
    fromTime: undefined,
    toTime: undefined,
  }

  if (outOfStockInfo?.startTime) {
    outOfStockTime.fromTime = msToSec(Number(outOfStockInfo.startTime))
  }
  if (outOfStockInfo?.endTime) {
    outOfStockTime.toTime = msToSec(Number(outOfStockInfo.endTime))
  }

  return {
    ...outOfStockTime,
    stock: SchemaMenuVn.StockType.OUT_OF_STOCK,
  }
}

function getAvailable(
  isActive: boolean,
  stockAvailable: boolean,
  outOfStockTime?: ISchemaMenu.IOutOfStockTime,
) {
  if (!isActive) return false

  if (stockAvailable || !outOfStockTime) return true

  return !isDuringOutOfStockTime(outOfStockTime)
}

function fromOption(option: SchemaMenuVn.IOptionItem): ISchemaMenu.IOption {
  // vn 设置 out of stock 为 forever 时，接口会把 isActive 改成 false
  const outOfStockInfo = option.isActive ? option.stockInfo : undefined

  const outOfStockTime = fromOutOfStockTime(outOfStockInfo)

  const stockAvailable = outOfStockInfo?.stock === SchemaMenuVn.StockType.AVAILABLE

  const available = getAvailable(option.isActive, stockAvailable, outOfStockTime)

  return {
    ...option,
    optionName: option.name,
    optionId: String(option.id),
    available,
    outOfStockTime,
    price: addPrecision(option.price),
    syncItemGroupId: normalizeSyncItemId(option),
  }
}

export function transformRequestOptionAvailable(
  options: ISchemaMenu.ICreateOptionGroupRequest['options'],
) {
  return options.map(option => {
    const optionId = Number(option.id)
    const outOfStockTime = toOutOfStockTime(option.available, option.outOfStockTime)

    return {
      optionId,
      ...outOfStockTime
    }
  })
}

function toOptionGroupSelectMode(
  group: SchemaMenuVn.IOptionGroup,
): Pick<ISchemaMenu.IOptionGroup, 'selectMode' | 'selectMax' | 'selectMin'> {
  const { minSelect, maxSelect } = group

  const selectMode =
    !minSelect && maxSelect > 0
      ? OptionSelectMode.Max
      : minSelect > 0 && maxSelect > 0
        ? minSelect === maxSelect
          ? OptionSelectMode.Equal
          : OptionSelectMode.Range
        : undefined

  return { selectMin: minSelect, selectMax: maxSelect, selectMode: selectMode! }
}

function toOptionGroup(group: SchemaMenuVn.IOptionGroup): ISchemaMenu.IOptionGroup {
  const options = (group.options || []).map(fromOption)

  const selectMode = toOptionGroupSelectMode(group)

  return {
    ...group,
    linkedDishCount: 0, // 需要从 option-group/dish-bindings 接口获取
    // groupId 必须是 string 否则 OptionGroupDetail.tsx 显示 id 的 List.Item 传入数字会识别为组件导致渲染报错
    groupId: String(group.id),
    groupName: group.name,
    // Vn 配料组无上架状态工架功能
    shelveState: true,
    // 无此值会导致页面 remark.trim 报错
    remark: '',
    syncItemGroupId: normalizeSyncItemId(group),
    options,
    ...selectMode,
  }
}

export function transformOptionGroupListResponse(
  response: Api.IResponse<SchemaMenuVn.IOptionGroupListResponse>,
): ISchemaMenu.IOptionGroupListResponse {
  return {
    ...response.data,
    groups: response.data.optionGroups?.map(toOptionGroup),
  }
}

export function transformOptionGroupResponse(
  response: Api.IResponse<SchemaMenuVn.IOptionGroup>,
): ISchemaMenu.IOptionGroupDetailResponse {
  return toOptionGroup(response.data)
}

function fromDish(dish: SchemaMenuVn.DishVN): ISchemaMenu.IDish {
  // VN 货币值为真实的数字
  const price = addPrecision(Math.round(dish.price))

  // vn 设置 out of stock 为 forever 时，接口会把 listingStatus 改成 false
  const outOfStockInfo = dish.listingStatus ? dish.stockInfo : undefined

  const outOfStockTime = fromOutOfStockTime(outOfStockInfo)

  const stockAvailable = outOfStockInfo?.stock === SchemaMenuVn.StockType.AVAILABLE

  const available = getAvailable(dish.listingStatus, stockAvailable, outOfStockTime)

  const normalizedSaleDays = normalizeSaleDays(dish.saleDays)

  return {
    ...dish,
    id: String(dish.id),
    catalogId: String(dish.catalogId),
    picture: dish.mmsImgId || dish.pictureUrl,
    price,
    // 菜品编辑页面使用的是 listPrice
    listPrice: price,
    // 控制菜品列表 item 上下架 Switch 的显示状态
    available,
    outOfStockTime,
    // 控制菜品列表 item 左侧名称下方是否显示 Out of time for sale 文字
    inSaleTime: true, // 暂时先不做 VN 不需要展示
    // inSaleTime: isInSaleTime(normalizedSaleDays, dish.specialSaleTimes),
    // 控制菜品列表 item 左侧名称下方是否显示 hidden from customers 文字
    // VN 不能直接操作 listingStatus，所以此值直接设置 Active
    listingStatus: DishListingStatus.Active,
    // 单独记录 VN 的 listingStatus
    listingStatusVn: dish.listingStatus ? DishListingStatus.Active : DishListingStatus.Inactive,
    // VN 无法直接使用 saleWeekBit / timeForSales 表示上架时间，使用 saleDays 进行展示
    saleDays: normalizedSaleDays,
    // VN 的 isPending 表示菜品正审核中
    syncItemGroupId: normalizeSyncItemId(dish),
    // 是否隐藏
    isHidden: dish.isHidden,

    // ID 必填字段
    timeForSales: [],
    optionGroupCount: 0,
    salesVolume: 0,
    saleStartTime: 0,
    saleEndTime: 0,
    saleWeekBit: 0,
    saleStatus: available ? DishSaleStatus.ForSale : DishSaleStatus.NotForSale,
    flashSalePrice: '0',
  }
}

export function transformDishResponse(
  response: Api.IResponse<SchemaMenuVn.DishResponseVN>,
): ISchemaMenu.IDishDetailResponse {
  return {
    ...response.data,
    dish: fromDish(response.data.dish || {}),
  }
}

export function transformMainDishItem(data: SchemaMenuVn.CatalogVN): ISchemaMenu.ICatalog {
  return {
    name: data.catalogName,
    id: String(data.catalogId),
    rank: data.rank,
    syncItemGroupId: normalizeSyncItemId(data),
    dishes: data.dishes?.map(fromDish) as ISchemaMenu.IDish[],
  }
}

export function transformMainDishResponse(
  // 即 rn 的 transformMainDishes
  response: Api.IResponse<SchemaMenuVn.IQueryCatalogResponse>,
): ISchemaMenu.ICatalogListResponse {
  return {
    ...response.data,
    catalogs: response.data?.catalogs.map(transformMainDishItem),
  }
}

export function transformCreateDishResponse(response: Api.IResponse<SchemaMenuVn.ICreateDishResponseVN>): ISchemaMenu.ICreateDishResponse {
  return {
    ...response.data as any,
    id: response.data.dishId
  }
}

export function transformEditCategoryRequest(
  catalogId: string,
  params: ISchemaMenu.ICreateCatalogRequest,
  isApplyBranch: boolean,
) {
  return {
    isApplyBranch,
    catalogs: [
      {
        id: Number(catalogId),
        name: params.name,
      },
    ],
  }
}

function transformCatalog(catalog: SchemaMenuVn.ICatalog): ISchemaMenu.ICatalog {
  return {
    id: String(catalog.id),
    name: catalog.name,
    rank: catalog.rank,
    syncItemGroupId: normalizeSyncItemId(catalog),
    dishes: catalog.dishes.map(fromDish),
  }
}

export const transformFetchCategoryResponse = (
  response: Api.IResponse<SchemaMenuVn.IFetchCatalogResponse>,
): ISchemaMenu.ICatalogListResponse => {
  return {
    ...response.data,
    catalogs: response.data?.catalogs?.map(transformCatalog),
  }
}

export function transformRankCategoryRequest(params: ISchemaMenu.IRankCatalogRequest) {
  return {
    isApplyBranch: params.isApplyBranch,
    catalogs: params.ranks.map((rank) => ({ ...rank, id: Number(rank.id) })),
  }
}

export function transformRankCategoryResponse(
  response: Api.IResponse<ISchemaMenu.IRankCatalogResponse>,
) {
  return {
    ...response.data,
  }
}

export function transformUpdateDishAvailableResponse(
  response: Api.IResponse<ISchemaMenu.IUpdateDishAvailableResponse>,
) {
  return {
    ...response,
  }
}

export function transformUpdateDishAvailableRequest(
  params: ISchemaMenu.IUpdateDishAvailableRequest,
) {
  const outOfStockTime = toOutOfStockTime(params.available, params.outOfStockTime)

  return {
    dishes: [
      {
        dishId: Number(params.dishId),
        ...outOfStockTime,
      },
    ],
  }
}

function transformSpecialSaleTime(specialSaleTimes?: SchemaMenuVn.DishSpecialSaleTimeVN[]) {
  // readonly in sale time page, but should post back as same
  return (specialSaleTimes || []).map((time) => ({
    specialSaleTime: {
      startDate: time.specialDateRange.startDate,
      endDate: time.specialDateRange.endDate,
    },
    timeForSales: time.timeRanges,
  }))
}

type DishQuerySaleDays = (Partial<SchemaMenuVn.DishSaleDayVN> & {
  specialSaleTime?: {
    startDate: SchemaMenuVn.DishSpecialSaleTimeVN['specialDateRange']['startDate']
    endDate: SchemaMenuVn.DishSpecialSaleTimeVN['specialDateRange']['endDate']
  }
})[]

export function transformDishRequest(dish: Partial<ISchemaMenu.IDish>) {
  const { categoryId, categorySource } = dish

  return {
    name: dish.name,
    // categoryId 和 categorySource 都存在，代表是Mart Category白名单且非emp商户
    // emp 商户如果在白名单内，无法修改Category会导致有id 没有source的情况
    ...(categoryId && categorySource
      ? { categoryId: Number(categoryId), categorySource }
      : { catalogId: Number(dish.catalogId) }),
    description: dish.description,
    price: Number(removePrecision(dish.price!)),
    pictureId: dish.pictureId || undefined,
    saleDays: dish.saleDays?.length
      ? (dish.saleDays as DishQuerySaleDays)?.concat(
        transformSpecialSaleTime(dish.specialSaleTimes),
      )
      : undefined, // ignore key if set saleDays all day
  }
}

export function transformRequestDishOptionGroupBindings(
  optionGroupBindings: ISchemaMenu.IOptionGroupBinding[] = [],
) {
  return optionGroupBindings.map((item) => ({
    optionGroupId: Number(item.groupId),
    rank: item.rank,
  }))
}

function transformCategoryDishes(
  categories?: ISchemaMenu.MartCategoryDishes<ISchemaMenu.MartCategoryItem, SchemaMenuVn.DishVN>[],
): ISchemaMenu.MartCategoryDishes[] | undefined {
  if (!categories?.length) {
    return categories as unknown as ISchemaMenu.MartCategoryDishes[]
  }

  return categories.map((category) => ({
    ...category,
    syncItemGroupId: normalizeSyncItemId({ headId: category.headConfigId }),
    dishes: category.dishes?.map((dish) => ({
      ...fromDish(dish),
      catalogId: String(dish.categoryId),
      categoryId: String(dish.categoryId),
    })) as ISchemaMenu.IDish[],
    categoryId: String(category.categoryId) || String(category.catalogId),
    subCategories: transformCategoryDishes(category.subCategories),
  }))
}

export function transformMartCategoryDishes(
  response: Api.IResponse<ISchemaMenu.IMenuMartCategoryDishResponse>,
): ISchemaMenu.MartCategoryDishResponse {
  const catalogs = transformCategoryDishes(response.data.categories) ?? []

  return {
    catalogs,
  }
}

export function normalizeCategory(categoryList: ISchemaMenu.MartCategoryDishes[]) {
  // 删除了最外层的 dishes 和第二层的 subCategories
  const dishMap = new Map<string, ISchemaMenu.IDish>()

  function traverse(list?: ISchemaMenu.MartCategoryDishes[]) {
    if (!list?.length) {
      return []
    }

    return list.map((item) => {
      const category: ISchemaMenu.MartCategoryData = {
        catalogId: item.catalogId, //---似乎没用
        categoryId: item.categoryId,
        categoryNameEn: item.categoryNameEn,
        categoryNameVi: item.categoryNameVi,
        syncItemGroupId: item.syncItemGroupId || normalizeSyncItemId({ headId: item.headConfigId ? Number(item.headConfigId) : undefined }),
        headConfigId: item.headConfigId,
        displayOrder: item.displayOrder,
        categoryType: item.categoryType,
      }

      if (item.subCategories?.length) {
        category.subCategories = traverse(item.subCategories)
      } else if (item.dishes?.length) {
        // const updateTime = Date.now()
        category.dishes = item.dishes.map((dish) => {
          dishMap.set(dish.id, dish)
          return dish
        })
      }

      return category
    })
  }

  const categoryData = traverse(categoryList)

  return {
    categoryList: categoryData,
    dishMap,
  } as const
}

export function toCreateOptions(options: ISchemaMenu.ICreateOptionGroupRequest['options']): SchemaMenuVn.ICreateOptionVN[] {
  return options.map<SchemaMenuVn.ICreateOptionVN>((option, index) => {

    return {
      optionId: option.id ? Number(option.id) : undefined,
      isActive: option.id ? undefined : true,
      name: option.name,
      price: Number(removePrecision(option.price)),
      syncItemGroupId: option.syncItemGroupId,
      rank: index + 1,
    }
  })
}

export function transformCreateOptionGroupRequest(data: ISchemaMenu.ICreateOptionGroupRequest): SchemaMenuVn.ICreateOptionGroupVN {
  const { optionGroup, options = [], dishBindingIds = [] } = data

  const payload: SchemaMenuVn.ICreateOptionGroupVN = {
    name: optionGroup.groupName!,
    options: toCreateOptions(options),
  }

  payload.minSelect = optionGroup.selectMin
  payload.maxSelect = optionGroup.selectMax

  if (dishBindingIds.length) {
    payload.dishIds = dishBindingIds.map(Number)
  }

  return payload
}

export function transformCreateOptionGroupResponse(response: Api.IResponse<SchemaMenuVn.ICreateOptionGroupResponseVN>): ISchemaMenu.ICreateOptionGroupResponse {
  return {
    ...response.data as any,
    id: response.data.optionGroupId
  }
}

export function transformUpdateOptionGroupResponse(response: [Api.IBaseResponse, Api.IResponse<ISchemaMenu.ICreateOptionGroupResponse>]) {
  const [, res] = response

  return {
    ...res.data,
  }
}

function transformOptionGroupDishBindingDish(
  dish: SchemaMenuVn.IOptionGroupDishBindingDishVN,
): ISchemaMenu.IDish {
  const { id, price, ...item } = dish
  return {
    ...item,
    id: String(dish.id),
    price: addPrecision(Math.round(price)),
    syncItemGroupId: normalizeSyncItemId(dish),
  } as unknown as ISchemaMenu.IDish
}

function transformOptionGroupDishBindingCatalog(
  catalog: SchemaMenuVn.IOptionGroupDishBindingCatalogVN,
): ISchemaMenu.ICatalog {
  return {
    id: String(catalog.catalogId),
    name: catalog.name,
    dishes: catalog.dishes.map(transformOptionGroupDishBindingDish),
  }
}

export function transformOptionGroupDishBindingResponse(
  response: Api.IResponse<SchemaMenuVn.IOptionGroupDishBindingResponseVN>,
): ISchemaMenu.ICatalogListResponse {
  return {
    ...response.data,
    catalogs: response.data?.catalogs?.map(transformOptionGroupDishBindingCatalog),
  }
}

function transformOptionGroupMartDishBindingCategory(
  category: SchemaMenuVn.IMartCategoryVN,
): ISchemaMenu.ICatalog {
  return {
    ...category,
    id: String(category.categoryId),
    name: getMartCategoryName({ nameEn: category.nameEn, nameVi: category.nameVi }),
    dishes: category.dishes.map(transformOptionGroupDishBindingDish),
  }
}

export function transformOptionGroupMartDishBindingResponse(
  response: Api.IResponse<SchemaMenuVn.IOptionGroupMartDishBindingResponseVN>,
): ISchemaMenu.ICatalogListResponse {
  return {
    ...response.data,
    catalogs: response.data?.rootCategories?.map(transformOptionGroupMartDishBindingCategory),
  }
}

export function transformNameByLanguage(item: ISchemaMenu.IMartRecommendCategory) {
  item.categoryName = getMartCategoryName({ nameEn: item.categoryNameEn, nameVi: item.categoryNameVi }) || ''
  item.rootCategoryName = getMartCategoryName({ nameEn: item.rootCategoryNameEn, nameVi: item.rootCategoryNameVi }) || ''

  return item
}

export function transformMartRecommendCategoryListResponse(respponse: Api.IResponse<ISchemaMenu.IMartRecommendCategoryListResponse>): ISchemaMenu.IMartRecommendCategoryListResponse {
  return {
    ...respponse.data,
    recommendedOptionList: respponse.data.recommendedOptionList?.map(transformNameByLanguage)
  }
}
