import type { LocaleConfig } from './config/type'
import ID from './config/ID'
import MY from './config/MY'
import TH from './config/TH'
import VN from './config/VN'
import { Country } from '@/constants/env.constant'
import { AppCountry } from '@/constants/app.constant'

export let localeConfig: LocaleConfig = ID

export const initLocaleConfig = () => {
  // 根据不同国家，使用不同的配置
  switch (AppCountry.current) {
    case Country.my:
      localeConfig = MY
      break
    case Country.th:
      localeConfig = TH
      break
    case Country.vn:
      localeConfig = VN
      break
    case Country.id:
    default:
      localeConfig = ID
  }
}
