/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="currentColor" />
    <path
      d="M12.668 11.763A10.975 10.975 0 0116.564 9.5 4.394 4.394 0 0020 11.16a4.374 4.374 0 003.436-1.66 10.976 10.976 0 013.896 2.263 4.43 4.43 0 001.617 5.258c.545.374 1.167.62 1.82.719a11.15 11.15 0 010 4.52 4.395 4.395 0 00-3.15 2.16 4.429 4.429 0 00-.287 3.817 10.976 10.976 0 01-3.896 2.262A4.395 4.395 0 0020 28.84a4.375 4.375 0 00-3.436 1.66 10.975 10.975 0 01-3.896-2.262 4.43 4.43 0 00-1.617-5.258 4.38 4.38 0 00-1.82-.719 11.148 11.148 0 010-4.52 4.38 4.38 0 003.15-2.16 4.427 4.427 0 00.287-3.817v-.001zM21.65 22.87a3.304 3.304 0 001.553-2.013 3.33 3.33 0 00-1.18-3.502 3.294 3.294 0 00-2.451-.65c-.431.058-.846.203-1.222.424a3.313 3.313 0 00-1.52 2.012 3.328 3.328 0 00.332 2.505 3.302 3.302 0 001.992 1.542c.841.229 1.738.115 2.496-.318z"
      fill="#fff"
    />
  </svg>
)
const Memo = memo(SvgSetting)
export default Memo
