import { AppCountry } from '@/constants/app.constant'
import * as ISchemaMenu from './data.d'
import * as transformMenu from './transform'
import { formatDefaultError, formatErrorMessage, transformDefault } from '@/utils/transform.util'
import { transformMainDishResponse } from './transform'
import { isEqual, pick } from 'lodash'

// #region Dish

function getConfigFetchDishListID() {
  return {
    service: () => ({
      url: '/api/seller/store/dishes',
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchDishListVN() {
  return {
    service: () => ({
      url: '/api/v5/seller/store/dishes',
      method: 'GET',
    }),
    formatResult: transformMainDishResponse,
  }
}

export function getConfigFetchDishList() {
  const getServiceConfig = AppCountry.isVn ? getConfigFetchDishListVN : getConfigFetchDishListID

  return getServiceConfig()
}

function getConfigFetchDishDetailID() {
  return {
    service: ({ dishId, ...params }: ISchemaMenu.IDishDetailRequest) => ({
      url: `/api/seller/store/dishes/${dishId}`,
      method: 'GET',
      params,
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchDishDetailVN() {
  return {
    service: ({ dishId }: ISchemaMenu.IDishDetailRequest) => ({
      url: '/api/v5/seller/store/dish',
      method: 'GET',
      params: { dishId },
    }),
    formatResult: transformMenu.transformDishResponse,
  }
}

export function getConfigFetchDishDetail() {
  const getServiceConfig = AppCountry.isVn ? getConfigFetchDishDetailVN : getConfigFetchDishDetailID

  return getServiceConfig()
}

function getConfigCreateDishID() {
  return {
    service: (params: ISchemaMenu.ICreateDishRequest) => ({
      url: '/api/seller/store/dishes',
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigCreateDishVN() {
  return {
    service: (params: ISchemaMenu.ICreateDishRequest) => {
      const payload = {
        branchSyncInfo: params.branchSyncInfo,
        ...transformMenu.transformDishRequest(params.dish),
      }

      if (params.optionGroupBindings !== undefined) {
        const optionGroups = transformMenu.transformRequestDishOptionGroupBindings(
          params.optionGroupBindings,
        )
        if (optionGroups.length) {
          Object.assign(payload, { optionGroups })
        }
      }

      return {
        url: '/api/v5/seller/store/dish/create',
        method: 'POST',
        data: payload,
      }
    },
    formatResult: transformMenu.transformCreateDishResponse,
    formatError: formatErrorMessage,
  }
}

export function getConfigCreateDish() {
  const getServiceConfig = AppCountry.isVn ? getConfigCreateDishVN : getConfigCreateDishID

  return getServiceConfig()
}

function getConfigDeleteDishID() {
  return {
    service: (dishId: string) => ({
      url: `/api/seller/store/dishes/${dishId}`,
      method: 'DELETE',
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigDeleteDishVN() {
  return {
    service: (dishId: string) => ({
      url: '/api/v5/seller/store/dish/delete',
      method: 'POST',
      data: { dishId: Number(dishId) },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigDeleteDish() {
  const getServiceConfig = AppCountry.isVn ? getConfigDeleteDishVN : getConfigDeleteDishID

  return getServiceConfig()
}

function getConfigBatchUpdateDishAvailableID() {
  return {
    service: (dishIds: string[]) => ({
      url: '/api/seller/store/dishes/stock/batch_update',
      method: 'POST',
      data: { dishIds },
    }),
    formatResult: transformDefault,
  }
}

function getConfigBatchUpdateDishAvailableVN() {
  return {
    service: (dishIds: string[]) => ({
      url: '/api/v5/seller/store/dishes/stock/batch_update',
      method: 'POST',
      data: { dishIds },
    }),
    formatResult: transformDefault,
  }
}

export function getConfigBatchUpdateDishAvailable() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigBatchUpdateDishAvailableVN
    : getConfigBatchUpdateDishAvailableID

  return getServiceConfig()
}

function getConfigUpdateDishID() {
  return {
    service: (dishId: string, params: ISchemaMenu.ICreateDishRequest) => ({
      url: `/api/seller/store/dishes/${dishId}`,
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigUpdateDishVN() {
  return {
    service: (dishId: string, params: ISchemaMenu.ICreateDishRequest) => {
      const { dish, branchSyncInfo, optionGroupBindings } = params
      if (dish.isPending) {
        return Promise.resolve({ code: 0, data: { id: dishId } })
      }

      const payload: any = {
        dishId: Number(dishId),
        available: dish.available,
        branchSyncInfo: branchSyncInfo,
        ...transformMenu.transformDishRequest(dish),
      }

      if (dish.outOfStockTime) {
        const outOfStockTime = transformMenu.toOutOfStockTime(
          !!dish.available,
          dish.outOfStockTime,
        )

        payload.outOfStockTime = {
          startTime: outOfStockTime.fromTime,
          endTime: outOfStockTime.toTime,
        }
      }

      if (optionGroupBindings !== undefined) {
        const optionGroups =
          transformMenu.transformRequestDishOptionGroupBindings(optionGroupBindings)

        if (optionGroups.length) {
          payload.optionGroups = optionGroups
        }
      }

      return {
        url: '/api/v5/seller/store/dish/update',
        method: 'POST',
        data: payload,
      }
    },
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigUpdateDish() {
  const getServiceConfig = AppCountry.isVn ? getConfigUpdateDishVN : getConfigUpdateDishID

  return getServiceConfig()
}

function getConfigRankDishID() {
  return {
    service: (params: ISchemaMenu.IRankDishRequest) => ({
      url: '/api/seller/store/dishes/-/rank',
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
  }
}

function getConfigRankDishVN() {
  return {
    service: () => Promise.resolve({ code: 0, data: {} }),
    formatResult: transformDefault,
  }
}

export function getConfigRankDish() {
  const getServiceConfig = AppCountry.isVn ? getConfigRankDishVN : getConfigRankDishID

  return getServiceConfig()
}

function getConfigUpdateDishAvailableID() {
  return {
    service: (params: ISchemaMenu.IUpdateDishAvailableRequest) => ({
      url: `/api/seller/store/catalogs/${params.catalogId}/dishes/${params.dishId}`,
      method: 'PUT',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError
  }
}

function getConfigUpdateDishAvailableVN() {
  return {
    service: (params: ISchemaMenu.IUpdateDishAvailableRequest) => ({
      url: '/api/v5/seller/store/dishes/stock/bulk_set',
      method: 'POST',
      data: transformMenu.transformUpdateDishAvailableRequest(params),
    }),
    formatResult: transformMenu.transformUpdateDishAvailableResponse,
    formatError: formatErrorMessage
  }
}

export function getConfigUpdateDishAvailable() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigUpdateDishAvailableVN
    : getConfigUpdateDishAvailableID

  return getServiceConfig()
}

function getConfigFetchDishLinkedOptionGroupsID() {
  return {
    service: (dishId: string) => ({
      url: `/api/seller/dishes/${dishId}/option-groups`,
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchDishLinkedOptionGroupsVN() {
  return {
    service: (dishId: string) => ({
      url: '/api/v5/seller/store/dish/option-group-bindings',
      method: 'GET',
      params: { dishId },
    }),
    formatResult: transformMenu.transformOptionGroupListResponse,
  }
}

export function getConfigFetchDishLinkedOptionGroups() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchDishLinkedOptionGroupsVN
    : getConfigFetchDishLinkedOptionGroupsID

  return getServiceConfig()
}

function getConfigSyncDishBranchesID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigSyncDishBranchesVN() {
  return {
    service: ({ id, ...params }: ISchemaMenu.ISyncBranchesRequest) => ({
      url: '/api/v5/seller/store/dish/sync',
      method: 'POST',
      data: { headDishId: id, ...params },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigSyncDishBranches() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigSyncDishBranchesVN
    : getConfigSyncDishBranchesID

  return getServiceConfig()
}

function getConfigFetchDishStoreBranchesID() {
  return {
    service: () => Promise.resolve({ code: 0, data: { branches: [] } }),
    formatResult: transformDefault,
  }
}

function getConfigFetchDishStoreBranchesVN() {
  return {
    service: (dishId: string) => {
      return {
        url: '/api/v5/seller/store/dish/branch_dishes',
        method: 'GET',
        params: { dishId: Number(dishId) },
      }
    },
    formatResult: transformDefault,
  }
}

export function getConfigFetchDishStoreBranches() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchDishStoreBranchesVN
    : getConfigFetchDishStoreBranchesID

  return getServiceConfig()
}

// #endregion Dish

// #region Catalog

function getConfigCreateCatalogID() {
  return {
    service: (params: ISchemaMenu.ICreateCatalogRequest) => ({
      url: '/api/seller/store/catalogs',
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigCreateCatalogVN() {
  return {
    service: (params: ISchemaMenu.ICreateCatalogRequest) => ({
      url: '/api/v5/seller/store/catalogs/bulk_create',
      method: 'POST',
      data: { catalogs: [params] },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigCreateCatalog() {
  const getServiceConfig = AppCountry.isVn ? getConfigCreateCatalogVN : getConfigCreateCatalogID

  return getServiceConfig()
}

function getConfigDeleteCatalogID() {
  return {
    service: (catalogId: string) => ({
      url: `/api/seller/store/catalogs/${catalogId}`,
      method: 'DELETE',
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigDeleteCatalogVN() {
  return {
    service: (catalogId: string) => ({
      url: '/api/v5/seller/store/catalog/delete',
      method: 'POST',
      data: { catalogId: Number(catalogId) },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigDeleteCatalog() {
  const getServiceConfig = AppCountry.isVn ? getConfigDeleteCatalogVN : getConfigDeleteCatalogID

  return getServiceConfig()
}

function getConfigUpdateCatalogID() {
  return {
    service: (catalogId: string, catalogs: ISchemaMenu.ICreateCatalogRequest) => ({
      url: `/api/seller/store/catalogs/${catalogId}`,
      method: 'POST',
      data: catalogs.name,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigUpdateCatalogVN() {
  return {
    service: (
      catalogId: string,
      catalogs: ISchemaMenu.ICreateCatalogRequest,
      isApplyBranch: boolean,
    ) => ({
      url: '/api/v5/seller/store/catalogs/bulk_update',
      method: 'POST',
      data: transformMenu.transformEditCategoryRequest(catalogId, catalogs, isApplyBranch),
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigUpdateCatalog() {
  const getServiceConfig = AppCountry.isVn ? getConfigUpdateCatalogVN : getConfigUpdateCatalogID

  return getServiceConfig()
}

function getConfigFetchCatalogListID() {
  return {
    service: () => ({
      url: '/api/seller/store/catalogs',
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchCatalogListVN() {
  return {
    service: () => ({
      url: '/api/v5/seller/store/catalogs',
      method: 'GET',
    }),
    formatResult: transformMenu.transformFetchCategoryResponse,
  }
}

export function getConfigFetchCatalogList() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchCatalogListVN
    : getConfigFetchCatalogListID

  return getServiceConfig()
}

function getConfigRankCatalogID() {
  return {
    service: (params: ISchemaMenu.IRankCatalogRequest) => ({
      url: `/api/seller/store/catalogs/-/rank`,
      method: 'POST',
      data: params.ranks,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigRankCatalogVN() {
  return {
    service: (params: ISchemaMenu.IRankCatalogRequest) => ({
      url: '/api/v5/seller/store/catalogs/bulk_update',
      method: 'POST',
      data: transformMenu.transformRankCategoryRequest(params),
    }),
    formatResult: transformMenu.transformRankCategoryResponse,
    formatError: formatErrorMessage,
  }
}

export function getConfigRankCatalog() {
  const getServiceConfig = AppCountry.isVn ? getConfigRankCatalogVN : getConfigRankCatalogID

  return getServiceConfig()
}

// #endregion Catalog

// #region OptionGroup

function getConfigCreateOptionGroupID() {
  return {
    service: (params: ISchemaMenu.ICreateOptionGroupRequest) => ({
      url: '/api/seller/store/option-groups',
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigCreateOptionGroupVN() {
  return {
    service: (params: ISchemaMenu.ICreateOptionGroupRequest) => {
      const payload = transformMenu.transformCreateOptionGroupRequest(params)

      return {
        url: '/api/v5/seller/store/option-group/create',
        method: 'POST',
        data: payload,
      }
    },
    formatResult: transformMenu.transformCreateOptionGroupResponse,
    formatError: formatErrorMessage,
  }
}

export function getConfigCreateOptionGroup() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigCreateOptionGroupVN
    : getConfigCreateOptionGroupID

  return getServiceConfig()
}

function getConfigDeleteOptionGroupID() {
  return {
    service: (groupId: string) => ({
      url: `/api/seller/store/option-groups/${groupId}`,
      method: 'DELETE',
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigDeleteOptionGroupVN() {
  return {
    service: (groupId: string) => ({
      url: '/api/v5/seller/store/option-group/delete',
      method: 'POST',
      data: { groupId: Number(groupId) },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigDeleteOptionGroup() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigDeleteOptionGroupVN
    : getConfigDeleteOptionGroupID

  return getServiceConfig()
}

function getConfigFetchOptionGroupListID() {
  return {
    service: () => ({
      url: '/api/seller/store/option-groups',
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchOptionGroupListVN() {
  return {
    service: () => ({
      url: '/api/v5/seller/store/option-groups',
      method: 'GET',
    }),
    formatResult: transformMenu.transformOptionGroupListResponse,
  }
}

export function getConfigFetchOptionGroupList() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchOptionGroupListVN
    : getConfigFetchOptionGroupListID

  return getServiceConfig()
}

function getConfigFetchOptionGroupDetailID() {
  return {
    service: (optionGroupId: string) => ({
      url: `/api/seller/store/option-groups/${optionGroupId}`,
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchOptionGroupDetailVN() {
  return {
    service: (groupId: string) => ({
      url: '/api/v5/seller/store/option-group',
      method: 'GET',
      params: { groupId },
    }),
    formatResult: transformMenu.transformOptionGroupResponse,
  }
}

export function getConfigFetchOptionGroupDetail() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchOptionGroupDetailVN
    : getConfigFetchOptionGroupDetailID

  return getServiceConfig()
}

function getConfigUpdateOptionGroupID() {
  return {
    service: (groupId: string, params: ISchemaMenu.ICreateOptionGroupRequest) => ({
      url: `/api/seller/store/option-groups/${groupId}`,
      method: 'POST',
      data: params,
    }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigUpdateOptionGroupVN() {
  return {
    service: (
      groupId: string,
      params: ISchemaMenu.ICreateOptionGroupRequest,
      origionParams: ISchemaMenu.ICreateOptionGroupRequest,
    ) => {
      const { optionGroup, options } = params
      const { optionGroup: origionOptionGroup, options: origionOptions } = origionParams

      const isDishLishModified = !isEqual(params.dishBindingIds, origionParams.dishBindingIds)

      const isGroupNameModified = !isEqual(optionGroup.groupName, origionOptionGroup?.groupName)

      const isSelectLimitsModified = !isEqual(
        pick(optionGroup, 'selectMin', 'selectMax'),
        pick(origionOptionGroup, 'selectMin', 'selectMax'),
      )

      const requestPayload = transformMenu.transformCreateOptionGroupRequest(params)
      const originalPayload = transformMenu.transformCreateOptionGroupRequest(origionParams)
      const isOptionsModified = !isEqual(requestPayload.options, originalPayload.options)

      const updateOpts = options.filter(({ id }) => Boolean(id))
      const originalOptionsAvailable = origionOptions?.filter(({ id }) => Boolean(id))
      const isOptionsAvailableModified = !isEqual(updateOpts, originalOptionsAvailable)

      const requestServices: any = []

      if (updateOpts.length && isOptionsAvailableModified) {
        requestServices.push({
          url: '/api/v5/seller/store/options/stock/bulk_set',
          method: 'POST',
          data: { options: transformMenu.transformRequestOptionAvailable(updateOpts) },
        })
      } else {
        requestServices.push(Promise.resolve({ code: 0, data: {}, msg: '' }))
      }

      const modifiedFieldsStatus = [
        isGroupNameModified,
        isSelectLimitsModified,
        isOptionsModified,
        isDishLishModified,
      ]
      if (modifiedFieldsStatus.some(Boolean)) {
        requestServices.push({
          url: '/api/v5/seller/store/option-group/update',
          method: 'POST',
          data: {
            optionGroupId: Number(groupId),
            ...requestPayload,
          },
        })
      } else {
        requestServices.push(Promise.resolve({ code: 0, data: [], msg: '' }))
      }

      return requestServices
    },
    formatResult: transformMenu.transformUpdateOptionGroupResponse,
    formatError: formatErrorMessage,
  }
}

export function getConfigUpdateOptionGroup() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigUpdateOptionGroupVN
    : getConfigUpdateOptionGroupID

  return getServiceConfig()
}

function getConfigUpdateOptionGroupAvailableID() {
  return {
    service: (options: ISchemaMenu.ICreateOptionGroupRequest['options']) => {
      return Promise.reject(new Error('ID Not Exist Api: useUpdateOptionGroupAvailable'))
    },
    formatResult: transformMenu.transformUpdateDishAvailableResponse,
    formatError: formatErrorMessage
  }
}

function getConfigUpdateOptionGroupAvailableVN() {
  return {
    service: (params: ISchemaMenu.IUpdateOptionGroupAvailableRequest) => {
      const outOfStockTime = transformMenu.toOutOfStockTime(!!params.available, params.outOfStockTime)

      return ({
        url: '/api/v5/seller/store/options/stock/bulk_set',
        method: 'POST',
        data: {
          options: [{
            optionId: Number(params.optionId),
            ...outOfStockTime
          }]
        },
      })
    },
    formatResult: transformDefault,
    formatError: formatErrorMessage
  }
}

export function getConfigUpdateOptionGroupAvailable() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigUpdateOptionGroupAvailableVN
    : getConfigUpdateOptionGroupAvailableID

  return getServiceConfig()
}

function getConfigFetchOptionGroupLinkedDishesID() {
  return {
    service: (groupId: string) => ({
      url: `/api/seller/option-groups/${groupId}/dishes`,
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchOptionGroupLinkedDishesVN() {
  return {
    service: (optionGroupId: string) => ({
      url: '/api/v5/seller/store/option-group/dish-bindings',
      method: 'GET',
      params: { optionGroupId },
    }),
    formatResult: transformMenu.transformOptionGroupDishBindingResponse,
  }
}

export function getConfigFetchOptionGroupLinkedDishes() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchOptionGroupLinkedDishesVN
    : getConfigFetchOptionGroupLinkedDishesID

  return getServiceConfig()
}

function getConfigFetchOptionGroupLinkedMartDishesID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
  }
}

function getConfigFetchOptionGroupLinkedMartDishesVN() {
  return {
    service: (optionGroupId: string) => ({
      url: '/api/v5/seller/store/option-group/dish-bindings_under_root_categories',
      method: 'GET',
      params: { optionGroupId },
    }),
    formatResult: transformMenu.transformOptionGroupMartDishBindingResponse,
  }
}

export function getConfigFetchOptionGroupLinkedMartDishes() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchOptionGroupLinkedMartDishesVN
    : getConfigFetchOptionGroupLinkedMartDishesID

  return getServiceConfig()
}

function getConfigSyncOptionGroupBranchesID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigSyncOptionGroupBranchesVN() {
  return {
    service: ({ id, ...params }: ISchemaMenu.ISyncBranchesRequest) => ({
      url: '/api/v5/seller/store/option-group/sync',
      method: 'POST',
      data: { headOptionGroupId: id, ...params },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigSyncOptionGroupBranches() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigSyncOptionGroupBranchesVN
    : getConfigSyncOptionGroupBranchesID

  return getServiceConfig()
}

function getConfigFetchOptionGroupStoreBranchesID() {
  return {
    service: () => Promise.resolve({ code: 0, data: { branches: [] } }),
    formatResult: transformDefault,
  }
}

function getConfigFetchOptionGroupStoreBranchesVN() {
  return {
    service: (optionGroupId: number) => ({
      url: '/api/v5/seller/store/option-group/branch_option_groups',
      method: 'GET',
      params: { optionGroupId },
    }),
    formatResult: transformDefault,
  }
}

export function getConfigFetchOptionGroupStoreBranches() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchOptionGroupStoreBranchesVN
    : getConfigFetchOptionGroupStoreBranchesID

  return getServiceConfig()
}

// #endregion OptionGroup

/**
 * 查询最近是否被修改数据
 *
 * @param {?UseFetchOptionsWithFormat} [options]
 * @returns {*}
 */
function getConfigFetchMenuRecentlyModifiedID() {
  return {
    service: (params: ISchemaMenu.IMenuRecentlyModifiedRequest) => ({
      url: '/api/seller/store/recently-modified',
      method: 'GET',
      params,
    }),
    formatResult: transformDefault,
  }
}

function getConfigFetchMenuRecentlyModifiedVN() {
  return {
    service: () => Promise.resolve({ code: 0, data: { modified: false } }),
    formatResult: transformDefault,
  }
}

export function getConfigFetchMenuRecentlyModified() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchMenuRecentlyModifiedVN
    : getConfigFetchMenuRecentlyModifiedID

  return getServiceConfig()
}

function getConfigAbandonAuditContentID() {
  return {
    service: (params: ISchemaMenu.IAbandonAuditContentRequest) => ({
      url: '/api/seller/store/qc-abandon',
      method: 'GET',
      params,
    }),
    formatResult: transformDefault,
  }
}

function getConfigAbandonAuditContentVN() {
  return {
    service: () => Promise.resolve({ code: 0, data: {} }),
    formatResult: transformDefault,
  }
}

export function getConfigAbandonAuditContent() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigAbandonAuditContentVN
    : getConfigAbandonAuditContentID

  return getServiceConfig()
}

/**
 * 查询 Mart Category Dish
 *
 */
function getConfigFetchMartCategoryDishID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
  }
}

function getConfigFetchMartCategoryDishVN() {
  return {
    service: () => ({
      url: '/api/v5/seller/store/dish/root_category_dishes',
      method: 'GET',
    }),
    formatResult: transformMenu.transformMartCategoryDishes,
  }
}

export function getConfigFetchMartCategoryDish() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchMartCategoryDishVN
    : getConfigFetchMartCategoryDishID

  return getServiceConfig()
}

function getConfigFetchMartCategoryListID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
  }
}

function getConfigFetchMartCategoryListVN() {
  return {
    service: () => ({
      url: '/api/v5/seller/category/get_basic_infos',
      method: 'GET',
    }),
    formatResult: transformDefault,
  }
}

export function getConfigFetchMartCategoryList() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchMartCategoryListVN
    : getConfigFetchMartCategoryListID

  return getServiceConfig()
}

function getConfigFetchMartRecommendCategoryListID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
  }
}

function getConfigFetchMartRecommendCategoryListVN() {
  return {
    service: (params: ISchemaMenu.IMartRecommendCategoryListRequest) => ({
      url: '/api/v5/seller/category/recommended_options',
      method: 'GET',
      params,
    }),
    formatResult: transformMenu.transformMartRecommendCategoryListResponse,
  }
}

export function getConfigFetchMartRecommendCategoryList() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigFetchMartRecommendCategoryListVN
    : getConfigFetchMartRecommendCategoryListID

  return getServiceConfig()
}

/**
 * 修改 Mart Category 排序（包括一级菜单和二级菜单）
 *
 */
function getConfigUpdateMartCategoryRankID() {
  return {
    service: () => Promise.resolve({ code: 0, data: [] }),
    formatResult: transformDefault,
    formatError: formatDefaultError,
  }
}

function getConfigUpdateMartCategoryRankVN() {
  return {
    service: (categoryIdsList: string[]) => ({
      url: '/api/v5/seller/store/category/update_bulk_display_order',
      method: 'POST',
      data: { categoryIdsList: [categoryIdsList.map((str) => Number(str))] },
    }),
    formatResult: transformDefault,
    formatError: formatErrorMessage,
  }
}

export function getConfigUpdateMartCategoryRank() {
  const getServiceConfig = AppCountry.isVn
    ? getConfigUpdateMartCategoryRankVN
    : getConfigUpdateMartCategoryRankID

  return getServiceConfig()
}
