
import { parseOrigin } from "@foody/common"
import { AppCountry, AppEnvironment, AppFoodServer, AppOpenApiServer, AppToken } from "@/constants/app.constant"
import {
  Country,
  Environment,
  OpenApiServerEnvironmentMapping,
  ServerCountryMapping,
  ServerEnvironmentMapping,
  ServerEnvironmentMappingVn,
} from "@/constants/env.constant"
import { getCookie, setCookies } from "./utils/cookie.util"
import { COOKIE_CACHE_KEYS } from "./constants/cache.constant"
import { initLanguage } from "./utils/i18n"
import { initLocaleConfig } from "./locale"

export async function initApp(user: NonNullable<NonNullable<Window['bridgeData']['data']>['user']>) {
  initAppEnv()
  initRegion(user.region as Country)

  initLanguage(user.language as any)

  initBaseUrl()
  await initPFB()

  if (user.shopeeToken) {
    await initToken(user.shopeeToken)
  }

  initLocaleConfig()
}

export function initRegion(region?: Country) {
  const finalRegion = region || (process.env.APP_REGION as Country) || Country.id

  AppCountry.current = finalRegion
}

function initAppEnv() {
  let finalEnv: string | undefined
  if (isLocalDevelopment()) {
    finalEnv = process.env.APP_ENV as Environment
  } else {
    const value = parseOrigin()
    finalEnv = value.env as Environment
  }

  AppEnvironment.current = (finalEnv as unknown as Environment) || Environment.Live
}

function initPFB() {
  return new Promise(resolve => {
    // FIXME: PFB工具设置的pfb domain使用当前网页域名，无法带到foody接口上的问题
    // 有成熟的解决方案以后可以去掉本段逻辑
    if (!AppEnvironment.isLive) {
      const pfb = getCookie('_SPC_PFB') || getCookie('_MSS_PFB')
      if (pfb) {
        setCookies([
          {
            name: '_SPC_PFB',
            value: pfb,
          },
        ])
      }
    }

    resolve(true)
  })
}

enum RequestFromType {
  PartnerWeb = 'partner_web',
  PartnerApp = 'partner_app',
}

function initToken(token: string) {
  AppToken.current = token

  return new Promise(resolve => {
    setCookies([
      {
        name: COOKIE_CACHE_KEYS.requestToken,
        value: token,
      },
      {
        name: COOKIE_CACHE_KEYS.requestFrom,
        value: RequestFromType.PartnerWeb,
      },
    ])

    resolve(true)
  })
}

function initBaseUrl() {
  AppFoodServer.current = getFoodyServer()
  AppOpenApiServer.current = getOpenApiServer()
}

function getFoodyServerId(env: Environment, country: Country) {
  return `https://${ServerEnvironmentMapping[env]}${ServerCountryMapping[country]}`
}

function getFoodyServerVn(env: Environment) {
  return `https://${ServerEnvironmentMappingVn[env]}`
}

function getFoodyServer(env = AppEnvironment.current, country = AppCountry.current) {
  if (isLocalDevelopment()) {
    // 让only_dev走webpack-dev-server的proxy
    return window.location.origin
  }

  return AppCountry.isVn ? getFoodyServerVn(env) : getFoodyServerId(env, country)
}

function getOpenApiServer(env = AppEnvironment.current, country = AppCountry.current) {
  if (process.env.NODE_ENV === 'development' && !(window as any).__POWERED_BY_QIANKUN__) {
    // 让only_dev走webpack-dev-server的proxy
    return window.location.origin
  }

  return `https://${OpenApiServerEnvironmentMapping[env]}${ServerCountryMapping[country]}`
}

function isLocalDevelopment() {
  return process.env.NODE_ENV === 'development' && !(window as any).__POWERED_BY_QIANKUN__
}
