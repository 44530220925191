import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneTH {
  BANGKOK = 'Asia/Bangkok',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneTH.BANGKOK,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLowerCase()
    const config = {
      [TimezoneTH.BANGKOK.toLowerCase()]: {
        timezoneStandardText: TimeStandard.WIB,
        timezoneOffset: TimezoneOffset.UTC9,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC9],
        timezoneName: TimezoneTH.BANGKOK,
      },
    }
    return config[lowerTimezone as TimezoneTH] || config[TimezoneTH.BANGKOK.toLowerCase()]
  },

  price: {
    regExp: /^[\d]+$/,
    precision: 0
  },

  menu: {
    dish: {
      minPrice: 0.01,
      maxPrice: 10000000
    }
  }
}

export default localeConfig
