import { createContext, useContext } from "react"

interface AppContextProps {
  userId: string;
  merchantId: number;
  storeId: number;
  setStoreId: (storeId: number) => void;
}

export const AppContext = createContext<AppContextProps>({
  userId: '',
  merchantId: 0,
  storeId: 0,
  setStoreId: () => { },
})

export const useAppContext = () => useContext(AppContext)
