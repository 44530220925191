import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

export enum TimezoneID {
  JAKARTA = 'Asia/Jakarta',
  MAKASSAR = 'Asia/Makassar',
  JAYAPURA = 'Asia/Jayapura'
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneID.JAKARTA,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLowerCase()
    const config = {
      [TimezoneID.JAKARTA.toLowerCase()]: {
        timezoneStandardText: TimeStandard.WIB,
        timezoneOffset: TimezoneOffset.UTC7,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC7],
        timezoneName: TimezoneID.JAKARTA,
      },
      [TimezoneID.MAKASSAR.toLowerCase()]: {
        timezoneStandardText: TimeStandard.WITA,
        timezoneOffset: TimezoneOffset.UTC8,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC8],
        timezoneName: TimezoneID.MAKASSAR,
      },
      [TimezoneID.JAYAPURA.toLowerCase()]: {
        timezoneStandardText: TimeStandard.WIT,
        timezoneOffset: TimezoneOffset.UTC9,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC9],
        timezoneName: TimezoneID.JAYAPURA,
      },
    }
    return config[lowerTimezone as TimezoneID] || config[TimezoneID.JAKARTA.toLowerCase()]
  },

  price: {
    regExp: /^[\d]+$/,
    precision: 0
  },

  menu: {
    dish: {
      minPrice: 0.01,
      maxPrice: 10000000
    }
  }
}

export default localeConfig
