export const convertSpaces = (str: string) => {
  // ASCII码值160的空格转成32
  return str ? str.replace(/\u00A0/g, ' ') : str
}

export function image2Base64(img: HTMLImageElement, type = 'image/png') {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ctx && ctx.drawImage(img, 0, 0, img.width, img.height)
  const dataURL = canvas.toDataURL(type)
  return dataURL
}

export function dataURLtoFile(dataUrl: string, filename: string, type: string) {
  const dataArray = dataUrl.split(',')
  const arrayString = atob(dataArray[1])
  let index = arrayString.length
  const fileBits = new Uint8Array(index)

  while (index--) {
    fileBits[index] = arrayString.charCodeAt(index)
  }

  return new File([fileBits], filename, { type })
}

// Remove accents when searching by frontend
export const normalizeString = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/đ/g, 'd')
    // Remove multiple spaces or abnormal spaces
    .replace(/\s+/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
