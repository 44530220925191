/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21 13.02a.5.5 0 010-.707L9.522 8 5.209 3.687a.5.5 0 01.708-.707L10.937 8l-5.02 5.02a.5.5 0 01-.708 0z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgArrowRight)
export default Memo
