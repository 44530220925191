import ReactDOM from 'react-dom'
import '@/styles/global.less'
import App from './App'
import { initApp } from '@/setup'
import type { AppProps } from './interface'
import { defaultsDeep } from 'lodash'
import { AppShowType } from './constants/app.constant'
import { Country } from './constants/env.constant'

const DefaultAppData: Window['bridgeData'] = {
  onClose: () => {},
  showType: AppShowType.Page,
  data: {
    props: {},
    user: {
      language: 'en',
      region: (process.env.APP_REGION as Country) || Country.id,
      shopeeToken: process.env.APP_TOKEN || '',
      userId: 0,
    },
  },
}

function getRoot(props: Partial<AppProps>) {
  return props.container ? props.container.querySelector('#root') : document.querySelector('#root')
}

async function render(props: Partial<AppProps>) {
  const root = getRoot(props)
  const { baseBridge } = props

  const appBridgeData = { current: DefaultAppData }
  if (baseBridge) {
    const appData = baseBridge.getAppData()!
    appBridgeData.current = defaultsDeep({}, appData, appBridgeData.current)
    window._BaseBridge = baseBridge
    window.bridgeData = appBridgeData.current
  }

  console.log('food app data', appBridgeData.current)

  const user = appBridgeData.current.data?.user

  // 初始化
  await initApp(user!)

  // render
  ReactDOM.render(<App {...props} appBridgeData={appBridgeData.current} />, root)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  // initSentry()
  render({})
}

export async function bootstrap() {
  // initSentry()
  console.log('food app bootstraped')
}

export async function mount(props: AppProps) {
  console.log('food props from main framework', props)
  render(props)
}

export async function unmount(props: AppProps) {
  const root = getRoot(props)
  if (!root) return
  try {
    ReactDOM.unmountComponentAtNode(root)
  } catch (err) {
    console.log(err)
  }
}
