/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgRank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 16 22"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.759 9.086a.806.806 0 01-.597-1.35l5.644-6.208a1.613 1.613 0 012.387 0l5.645 6.209a.806.806 0 01-.597 1.349H1.759zM14.241 13.086c.7 0 1.068.83.597 1.349l-5.645 6.208a1.613 1.613 0 01-2.386 0l-5.645-6.208a.806.806 0 01.597-1.35H14.24z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
)
const Memo = memo(SvgRank)
export default Memo
