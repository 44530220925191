// VN 的数据结构
import { DishSaleWeekDay } from './constants'
import { ITEM_HEAD_ID } from './menu.vm'

export enum StockType {
  AVAILABLE = 1,
  OUT_OF_STOCK,
}

export interface ISyncableItem {
  headId?: number
}

export interface IStockInfo {
  stock: StockType
  fromTime?: number
  toTime?: number
}

export interface IOptionItem extends ISyncableItem {
  name: string
  price: number
  rank: number
  stockInfo: IStockInfo
  groupId: number
  id: number
  isActive: boolean
}

export interface IOptionGroup extends ISyncableItem {
  isActive: boolean
  id: number
  rank: number
  name: string
  options: IOptionItem[]
  minSelect: number
  maxSelect: number
}

export interface IOptionGroupListResponse {
  optionGroups: IOptionGroup[]
}

export enum CATEGORY_SOURCE {
  algo = 1,
  merchant = 2,
  ops = 3,
}

export interface SyncableItemVN {
  headId?: ITEM_HEAD_ID | number // is sync item
}


export interface TimeForSaleVN {
  startTime: number
  endTime: number
}


export interface DishSaleDayVN {
  weekday: DishSaleWeekDay
  timeForSales?: TimeForSaleVN[]
}

export interface DishSpecialSaleTimeVN {
  specialDateRange: {
    startDate: number
    endDate: number
  }
  timeRanges: TimeForSaleVN[]
}

export interface DishVN extends SyncableItemVN {
  id: number
  name: string
  pictureUrl: string
  mmsImgId: string
  catalogId: number
  price: number
  description: string
  stockInfo: IStockInfo
  saleDays: DishSaleDayVN[] // [] 表示全部日期上架
  specialSaleTimes: DishSpecialSaleTimeVN[] // [] 表示全部日期上架
  // 在 VN 中如果有 approval_status.is_pending 表示审核中
  // 在 approval_status.pending_info.pending_data 中会有详细的审核字段
  // 编辑页面下方会有提示显示对应字段在审核中，无法编辑除下架时间之外的其他信息
  isPending: boolean
  isHead: boolean // 是否为旗舰店菜品?
  listingStatus: boolean // 是否上架
  isHidden: boolean // 是否隐藏

  // 新增Mart Category
  categoryId?: string
  categoryNameEn?: string
  categoryNameVi?: string
  rootCategoryId?: string
  rootCategoryNameEn?: string
  rootCategoryNameVi?: string
  categorySource?: CATEGORY_SOURCE
}

export interface DishResponseVN {
  dish: DishVN
}

export interface CatalogVN extends SyncableItemVN {
  catalogName: string
  catalogId: number
  rank: number
  dishes: DishVN[]
}

export interface ICatalog extends SyncableItemVN {
  id: string
  name: string
  rank: number
  dishes: DishVN[]
  syncItemGroupId?: string
}
// export interface ICatalog {
//   id: number
//   headId: number
//   isActive?: boolean
//   name?: string
//   rank?: number
//   partnerCatalogId: string
//   restaurantId: number
//   displayOrder: number
//   displaySortType: number
//   dishes?: DishVN[]
//   syncItemGroupId?: string
// }

export interface IFetchCatalogResponse {
  catalogs: ICatalog[]
}

export interface IQueryCatalogResponse {
  catalogs: CatalogVN[]
}

// export interface ICategoryDish {
//   //其实是要转为这个格式的？
//   index?: number
//   id: string
//   name?: string
//   price?: string
//   catalogId?: string
//   // timeForSales?: string //应该是一个数组 暂时改为下面的 inSaleTime
//   inSaleTime: boolean
//   listingStatus?: number
//   available?: boolean
//   picture?: string
//   rank?: number
//   syncItemGroupId?: string
// }

export interface ICreateDishResponseVN {
  dishId?: string
  isPending: boolean
}

export interface ICreateOptionVN {
  name: string
  price: number
  rank: number
  isActive?: boolean
  optionId?: number
  syncItemGroupId?: string
  outOfStockTime?: IStockInfo
}

export interface ICreateOptionGroupVN {
  name: string
  options: ICreateOptionVN[]
  minSelect?: number
  maxSelect?: number
  dishIds?: number[]
}

export interface ICreateOptionGroupResponseVN {
  optionGroupId?: string
  isPending: boolean
}

export interface IOptionGroupDishBindingDishVN extends SyncableItemVN {
  catalogId: number
  id: number
  name: string
  listingStatus: boolean
  price: number
  rank: number
}

export interface IOptionGroupDishBindingCatalogVN {
  catalogId: number
  name: string
  rank: number
  dishes: IOptionGroupDishBindingDishVN[]
}


export interface IOptionGroupDishBindingResponseVN {
  catalogs: IOptionGroupDishBindingCatalogVN[]
}

export interface IMartDishVN extends Omit<DishVN, 'categoryId'> {
  categoryId: number
  rank: number
}

export interface IMartCategoryVN {
  nameEn: string
  nameVi: string
  categoryId: number
  rank: number
  dishes: IMartDishVN[]
}

export interface IOptionGroupMartDishBindingResponseVN {
  rootCategories: IMartCategoryVN[]
}
