export const ErrorCodeAuth = {
  // auth_failed
  // 鉴权失败
  AuthFailed: 3004,
  // not login
  // token过期，需要重新登录
  NotLogin: 1130001,
  // need to select a store
  // 需要选择具体一个store
  NeedToSelectStore: 1130002,
  // merchant/store belongs to token/entity_id is not found
  // 没找到属于token/entity_id的商家或店铺
  TokenOrEntityIdNotFound: 1130003,
}

export const ErrorCodeCategory = {
  // invalid catalog
  // 根据catalog_id和shop_id查询不到对应的catalog信息
  CategoryInvalid: 1090102,
  // catalog number reach limit
  // 该shop存在的catalog已达到数量上限
  CategoryExceedLimit: 1090105,
} as const

export const ErrorCodeSellerAPI = {
  // error params invalid
  // 参数类型错误 / 分页数值超出最大限制
  ErrorParamsInvalid: 1000,
  // record not found
  // 记录不存在
  RecordNotFound: 2001,
  // no need update
  // 无需更新
  NoNeedUpdate: 2009,
  // Number of available options conflicts with select control
  // 删除option / 将option置为unavailable / option group下available的option数量与select mode不一致
  NumberOfOptionsConflict: 1130000,
  // no edit menu authority
  // 没有编辑菜单权限
  NoEditMenuAuthority: 1130004,
  // option group bind dish failed
  // option group绑定dish时出现失败
  GroupBindDishFailed: 1130005,
  // Dishes errors
  // 标记是dish的错误(区分于campaign的错误)
  ItemDiscountDishError: 1250002,
  // Error item discount price invalid
  // 	item discount 价格不正确
  ItemDiscountPriceInvalid: 1250034,
  // 活动时间校验不通过
  CampaignTimeInvalid: 1250035,
  // Some of dishes not existed
  // 部分菜品不存在
  SomeDishesNotExist: 1250039,
  // Number of valid discounts of a dish exceeds limit
  // 超过一个菜品最多绑定的活动数
  DiscountOfDishNumberExceedLimit: 1250040,
  // Number of valid campaigns under a store exceeds limit
  // 过一个店铺下最多有效活动数
  CampaignsExceedLimit: 1250041,
  // Error item discount in another campaign
  // 存在 dish 已经在 campaign 时间段内参加了其它活动
  DishInAnotherCampaign: 1250042,
  // no edit promotion authority
  // 没有编辑 promotion 权限
  NoEditPromotionAuthority: 1130006,
  // Error update item discount stock not allow to decrease
  // 菜品库存只能改大不能改小
  DiscountStockNotAllowDecrease: 11250081,
} as const

export const ErrorCodeItem = {
  // error dish no found
  // 不存在的菜品
  DishNoFound: 1100000,
  // error_group_count_limit_exceeded
  // 超出了 Group 数量的上限
  GroupsExceedLimit: 1100001,
  // error dish count exceed limit
  // 超出菜品数量的上限
  DishExceedLimit: 1100002,
  // error_group_rank_locked
  // 当前 Group 排序已被锁定, 该错误适用于使用锁的情况
  GroupRankLocked: 1100003,
  // error_group_rank_deprecated
  // 给定的 Group 排序已过期, 该错误适用于使用 version 的情况
  GroupRankInvalid: 1100004,
  // error option no found
  // 不存在的option
  OptionInvalid: 1100005,
  // error option exceed the limit size
  // 超出option数量的上限
  OptionsExceedLimit: 1100006,
  // -
  // -
  None: 1100007,
  // error_group_no_found
  // 找不到 Option Group
  GroupNoFound: 1100008,
  // error_group_repeated
  // group 重复
  GroupRepeated: 1100009,
  // error_group_existed
  // group 已存在
  GroupExisted: 1100010,
  // error_option_name_too_long
  // option_name太长
  OptionNameTooLong: 1100011,
  // error_option_group_name_too_long
  // option_group_name太长
  OptionGroupNameTooLong: 1100012,
  // error_option_repeated
  // option 重复
  OptionRepeated: 1100013,
  // error_option_existed
  // option 已存在
  OptionExisted: 1100014,
  // error_produce_kitlog
  // 操作日志写入失败
  ErrorProduceKitLog: 1100015,
  // error_select_control_not_match_options
  // group_select_control 与有效Options数不一致
  ErrorSelectControlNotMatch: 1100016,
  // error dish in promotion
  // 菜品正在促销，禁止修改
  DishInPromotion: 1100017,
  // error dish price invalid
  // 菜品价格低于折扣价
  PriceHigherThanPromotionPrice: 1100018,
  // dish price smaller than discount price
  // 菜品价格低于flashSale折扣价
  PriceHigherThanFlashSalePrice: 1134016,
  // error dish sale time invalid
  // 菜品售卖时间不合法
  DishSaleTimeInvalid: 1100019,
  // 	error dish sale time not set
  // 菜品售卖时间未设置
  TimeSaleNotSet: 1100020,
  // error whitelist option exceed the limit size
  // Option数量超过白名单Store Option Group限制
  WhitelistOptionsExceedLimit: 1100021,
  // error whitelist group exceed the limit size
  // Option Group数量超过白名单Store限制
  WhitelistGroupsExceedLimit: 1100022,
  // err whitelist dish exceed the limit size
  // 白名单店铺dish数量超出上限
  WhitelistDishExceedLimit: 1100023,
  // error time for sales per dish exceed the limit size
  // 菜品的售卖时间段数量超出上限
  TimeSalesExceedLimit: 1100024,
  // error repeated time period
  // 菜品售卖时间重叠
  ErrorRepeatedTimePeriod: 1100025,
  // error dish rank invalid
  // 菜品rank不合法
  ErrorDishRankInvalid: 1100026,
  // dish duplicated
  // 上传菜品重复
  DishDuplicated: 1100027,
  // set dish option group binding failed
  // dish绑定option group失败
  SetDishOptionGroupBindingFailed: 1100028,
  // error option group remark too long
  // option group remark 太长
  ErrorOptionGroupRemarkTooLong: 1100029,
  // dish binding option count exceed limit
  // Dish绑定的Option Group超过上限
  DishBindingOptionCountExceedLimit: 1100030,
  // submit qc to cqcp platform failed
  // 提交qc平台失败
  SubmitQcFailed: 1100031,
  // save edit record failed
  // 保存编辑记录失败
  SaveEditRecordFailed: 1100032,
  // invalid save request for previous saving process
  // 提交保存请求失败，因为正在处理上一次提交的记录
  SaveQcFailedForPreviousSavingProcess: 1100033,
  // edit record not found
  // edit record not found
  EditRecordNotFound: 1100034,
  // forbidden modifiy listing_status/shelve for qc frozen
  // qc冻结，禁止变更上下架
  ForbiddenModifiyListingStatusShelveForQcFrozen: 1100035,
  // price's edit times exceed the limit times
  // 菜品价格编辑频次达到上限
  PriceEditTimesExceedLimit: 1100036,
  // whitelist store dish binding option count exceed limit
  // 白名单店铺Dish绑定的Option Group超过上限
  WhitelistDishBindingOptionCountExceedLimit: 1100037,
  // dish image mapping invalid
  // 菜品图片mapping不合法
  DishImageMappingInvalid: 1100038,
  // edit record has closed
  // 编辑记录已经关闭
  EditRecordHasClosed: 1100039,
  // dish price change exceed limit
  // 价格改动幅度超过限制
  PriceChangeExceedLimit: 1100040,
  // error dish price invalid
  // 折扣后的菜品价格低于最低限制
  DiscountedPriceLessThanTheLowestLimit: 1100041,
  // error fail to get qc record
  // 该店铺查询QC记录时失败
  SearchQcRecordFailed: 1100046,
  // 菜品折扣中，不能修改
  ItemInOnGoingPromotion: 1100047,
  /**
   * @desc 包含敏感词
   * @region VN
   */
  DishHasSensitiveWords: 1100048,
  /**
   * @desc 不能更新主店同步菜品
   * @desc Cannot edit items and toppings belonging to head shop
   * @region VN
   **/
  UpdateHeadDishFailed: 1100049,
  /**
   * @desc 菜品组不存在
   * @desc Dish Type not found
   * @region VN
   **/
  DishTypeNotFound: 1100050,
  /**
   * @desc 上传图片格式不正确
   * @desc Error file format invalid  Định dạng tập tin không hợp lệ
   * @region VN
   **/
  DishImageInvalid: 1100051,
  /**
   * @desc 包含特殊字符
   * @desc Please check again, those fields above must not include special character
   * @region VN
   **/
  DishHasSpecialCharacter: 1100052,
  /**
   * @desc 不允许修改菜品价格
   * @desc Cannot update item price. Please contact our hotline to request for price change
   * @region VN
   **/
  UpdateDishPriceFailed: 1100053,
  /**
   * @desc 菜品被设置了促销，不允许修改
   * @desc You cannot create more dishes when there’s an on-going promotion
   * @region VN
   **/
  UpdatePromotionDishFailed: 1100054,
  /**
   * @desc 不允许修改赠菜信息
   * @desc You cannot edit/delete gift item
   * @region VN
   **/
  UpdateGiftDishFailed: 1100055,
  /**
   * @desc 菜品描述不能为空
   * @desc Cannot input desc with mobile phone, please input again
   * @region VN
   **/
  DishDescInvalid: 1100056,
  // error dish is blocked
  DishBlocked: 1100057,
  // qc abandon error
  // 废弃qc内容失败
  AbandonQCFail: 1400004,
  /**
   * @region VN
   * @desc 设置节假日价格失败
   * @desc
   **/
  SetHolidayPriceError: 1100060,
  /**
   * @region VN
   * @desc 设置节假日价格时间失败
   * @desc
   **/
  SetHolidayPriceTimeError: 1100061,
  /**
   * @region VN
   * @desc 节假日价格 时间已到期 不能查看或设置
   * @desc
   **/
  HolidayPriceExpirationError: 1100062,
  /**
   * @region VN
   * @desc 设置节假日价格 最大 Dish/options 限制
   * @desc Can select up to 1000 Dish/options to set holiday price
   **/
  HolidayPriceLimitItemsError: 1100063,
  /**
   * @region VN
   * @desc 设置节假日价格 最大 Dish/options 限制（同步子店时）
   * @desc Can sync up to 1000 Dish/options to child store, please only select {child_store_count} child stores to sync
   **/
  HolidayPriceLimitSyncItemsError: 1100064,
} as const

export const ErrorCodeShop = {
  // get shop base failed
  // 获取店铺基础信息失败 / admin获取基础信息
  GetShopBaseFailed: 1110006,
  /**
   * @desc 有可售菜品时，弹窗确认店铺是否需要重新开店
   * @region VN
   */
  ShopReopenWarning: 1110092,
  /**
   * @desc 无可售菜品时，后台会强制关店（需要弹窗提示）
   * @region VN
   */
  ShopForceClosed: 1110093,
  /**
   * @desc 店铺不可用（不需要弹窗提示）
   * @region VN
   */
  ShopInactived: 1110094,
} as const

export const ErrorCodeCommon = {
  REQUEST_TIMEOUT: -99,
  TRAFFIC_JAM: -100,
  OFFLINE: -101,
  FINGERPRINT: -102,
  CSRFTOKEN: -103,
  UNKNOWN: -999,
  UNHANDLED: -111,
}

export const ErrorCode = {
  ...ErrorCodeAuth,
  ...ErrorCodeCategory,
  ...ErrorCodeSellerAPI,
  ...ErrorCodeItem,
  ...ErrorCodeShop,
  ...ErrorCodeCommon,
}
