/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgNotice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <rect width={16} height={16} rx={8} fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.243 9.2H4.725a.727.727 0 01-.634-.366c-.052-.088-.201-.399.053-.776.09-.154.212-.349.353-.565.136-.21.255-.415.36-.63.112-.22.21-.456.292-.702.072-.218.108-.453.108-.696a2.68 2.68 0 01.556-1.66c.161-.207.354-.388.575-.54.18-.121.365-.218.552-.291.806-.358 2.145-.065 2.127.01.184.072.35.157.502.258.228.15.43.338.6.559a2.72 2.72 0 01.542 1.655c0 .236.036.468.109.693a5.316 5.316 0 00.657 1.333c.134.197.255.366.364.507a.699.699 0 01.158.477.768.768 0 01-.399.624.743.743 0 01-.357.11zM10.028 10.987l.782-.782 1.826 1.825-.783.783-1.825-1.826zm-2.581-.368h1.106v2.58H7.447v-2.58zm-1.475.368l-.782-.782-1.826 1.825.783.783 1.825-1.826z"
      fill="#fff"
    />
  </svg>
)
const Memo = memo(SvgNotice)
export default Memo
