// 这里放 index.ts请求 中使用的 constants
export enum DishSaleWeekDay {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

/** 菜品上下架状态 */
export enum DishListingStatus {
  Inactive = 0, // 下架
  Active = 1, // 上架
}

/** 菜品是否可售 */
export enum DishSaleStatus {
  NotForSale = 0, // 不可售
  ForSale = 1, // 售卖中
}

export enum MenuRecentlyRecordType {
  Dish = 1,
  Catalog = 2,
  OptionGroup = 3,
  Option = 4,
  DishBindingOptionGroup = 5,
  OptionGroupBindingOption = 6,
  DishPrice = 7,
}

export enum QCClosedStatus {
  Unclosed = 0,
  Closed = 1,
}

export enum QCContentSavedStatus {
  No = 0,
  Yes = 1,
}

export enum QCOperationType {
  Create = 1,
  Update = 2,
}

export enum QCViolationSeverity {
  Mild = 'MILD',
  Moderate = 'MODERATE',
  Severe = 'SEVERE',
}

export enum QCStatus {
  AutoQCBan = 1,
  Risky = 2,
  AutoQCPass = 3,
  AutoQCSaved = 4,
  QCBan = 5,
  QCPass = 6,
  NoNeedQC = 7,
  NoNeedQCSaved = 8,
}

export enum QCResult {
  NoNeedQC = 0,
  BannedContentDetected = 1,
  RiskyContentDetected = 2,
  NoRiskyContentDetected = 3,
}

export enum QCErrorDialogResult {
  // 停留在当前编辑页面
  Stay,
  // 停留在当前编辑状态，头部展示审核不通过内容
  RefreshQCDetail,
  // 退回menu页面&刷新
  BackAndRefreshMenu,
}

export const QCResultStatus = {
  [QCResult.NoNeedQC]: QCStatus.NoNeedQC,
  [QCResult.BannedContentDetected]: QCStatus.AutoQCBan,
  [QCResult.RiskyContentDetected]: QCStatus.Risky,
  [QCResult.NoRiskyContentDetected]: QCStatus.AutoQCPass,
}

export enum AbandonContentProductType {
  Dish = 500,
  Category = 501,
  OptionGroup = 502,
}

export enum OptionGroupShelveStatus {
  Unshelved = 0, //	下架
  Shelved = 1, //	上架
}

export enum OptionSelectMode {
  // 等于，取min or max
  Equal = 1,
  // 大于等于，取min
  Min = 2,
  // 小于等于，取max
  Max = 3,
  // 范围，取min and max
  Range = 4,
  // 任意，不限制
  Free = 5,
}

export enum OptionAvailable {
  Unavailable = 0, // 不可用
  Available = 1, // 可用
}

/**
 * 主子店同步 dish|option group 的同步类型
 *
 * @export
 * @enum {number}
 */
export enum ApplyBranchType {
  /** 移除同步 */
  Remove = 1,
  /** 同步 */
  Sync = 2,
}

export enum OutOfStockType {
  Today,
  Forever,
  Other,
}

export const OutOfStockTypeMapping: Record<OutOfStockType, string> = {
  get [OutOfStockType.Today]() {
    return 'Today'
  },
  get [OutOfStockType.Forever]() {
    return 'Tun off forever'
  },
  get [OutOfStockType.Other]() {
    return 'Other time'
  },
}

/** Mart Category 类型 */
export enum CategoryType {
  Normal = 0,
  Others = 1,
}
