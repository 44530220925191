import Api from "@/types/api"

export function transformDefault<T extends Object = Object, P extends Api.IResponse<T> = Api.IResponse<T>>(response: P): T {
  return response.data
}


export function formatDefaultError(err: any) {
  return err
}

export function formatErrorMessage(err: any) {
  const data = err?.data || {}

  return {
    ...err,
    message: data?.errorMessage || err.message,
  }
}
