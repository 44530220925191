import useRequest from '@ahooksjs/use-request'
import type {
  CombineService,
  BaseOptions,
  BaseResult,
  LoadMoreFormatReturn,
  LoadMoreOptions,
  LoadMoreOptionsWithFormat,
  LoadMoreParams,
  LoadMoreResult,
  OptionsWithFormat,
  PaginatedFormatReturn,
  PaginatedOptionsWithFormat,
  PaginatedParams,
  PaginatedResult,
  BasePaginatedOptions,
} from '@ahooksjs/use-request/lib/types'
import request from './request'
import { notify } from '@/components/Notify'

export interface UseFetchOptions<R = unknown, P extends unknown[] = unknown[]> extends BaseOptions<R, P> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

export interface UseFetchOptionsWithFormat<
  R = unknown,
  P extends unknown[] = unknown[],
  U = unknown,
  UU extends U = any,
> extends OptionsWithFormat<R, P, U, UU> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

export interface UseFetchLoadMoreOptions<R extends LoadMoreFormatReturn>
  extends LoadMoreOptions<R> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

export interface UseFetchLoadMoreOptionsWithFormat<R extends LoadMoreFormatReturn, RR>
  extends LoadMoreOptionsWithFormat<R, RR> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

export interface UseFetchPaginatedOptionsWithFormat<R, Item, U>
  extends PaginatedOptionsWithFormat<R, Item, U> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

export interface UseFetchBasePaginatedOptions<U> extends BasePaginatedOptions<U> {
  disabledErrorMessage?: boolean
  useAllSettled?: boolean
  formatError?: (err: any) => any
}

function useFetch<R = any, P extends any[] = any, U = any, UU extends U = any>(
  service: CombineService<R, P>,
  options: UseFetchOptionsWithFormat<R, P, U, UU>,
): BaseResult<U, P>

function useFetch<R = any, P extends any[] = any>(
  service: CombineService<R, P>,
  options?: UseFetchOptions<R, P>,
): BaseResult<R, P>

function useFetch<R extends LoadMoreFormatReturn, RR>(
  service: CombineService<RR, LoadMoreParams<R>>,
  options: UseFetchLoadMoreOptionsWithFormat<R, RR>,
): LoadMoreResult<R>

function useFetch<R extends LoadMoreFormatReturn, RR extends R>(
  service: CombineService<R, LoadMoreParams<R>>,
  options: UseFetchLoadMoreOptions<RR>,
): LoadMoreResult<R>

function useFetch<R = any, Item = any, U extends Item = any>(
  service: CombineService<R, PaginatedParams>,
  options: UseFetchPaginatedOptionsWithFormat<R, Item, U>,
): PaginatedResult<Item>

function useFetch<Item = any, U extends Item = any>(
  service: CombineService<PaginatedFormatReturn<Item>, PaginatedParams>,
  options: UseFetchBasePaginatedOptions<U>,
): PaginatedResult<Item>

function useFetch(service: any, options: any) {
  return useRequest(service, {
    useAllSettled: false,
    ...options,
    requestMethod: async (opts) => {
      // 如果第一个参数是数组，说明是批量请求
      if (Array.isArray(opts)) {
        const requests = opts.map(item => {
          if (item instanceof Promise) {
            return item
          }
          return request(item).catch(err => {
            if (options.formatError) {
              return Promise.reject(options.formatError(err))
            }

            return Promise.reject(err)
          })
        })


        return options?.useAllSettled
          ? Promise.allSettled(requests)
          : Promise.all(requests)
      }

      // 普通单个请求
      return request(opts).catch(err => {
        if (options.formatError) {
          return Promise.reject(options.formatError(err))
        }

        return Promise.reject(err)
      })
    },
    onError: (error, params) => {
      if (!options?.disabledErrorMessage) {
        console.error('useFetch Error', error, params)
        notify.error({ message: error.message })
      }
      options?.onError?.(error, params)
    },
  })
}

export default useFetch
