import type Api from "@/types/api"
import { BoolStatus } from "@/constants/common.constant"
import { isSettledFulfilled } from "@/utils/promise.util"
import * as StoreConstants from "./constants"
import * as ISchemaStore from "./data.d"
import * as SchemaStoreVn from "./schema.vn"

export function transformStoreListItem(data: SchemaStoreVn.IStoreInfo): ISchemaStore.IStoreInfo {
  return {
    id: data.storeId,
    name: data.name,
    status: data.status,
    overtimeOrderMode: data.overtimeOrderMode,
    autoConfirmed: data.autoConfirmed,
    isInstantPrep: data.isInstantPrep,
    statusReason: StoreConstants.StoreStatusReason.Others,
    contactNumber: '',
    timezone: '',
  }
}

export function transformStoreListResponse(response: Api.IResponse<SchemaStoreVn.IStoreListResponse>): ISchemaStore.IStoreListResponse {
  return {
    ...response.data,
    stores: response.data?.restaurants?.map(transformStoreListItem)
  }
}

export function transformStoreListRequest(payload: ISchemaStore.IStoreListRequest): SchemaStoreVn.IStoreListRequest {
  return {
    page: payload.pageNo,
    pageNo: payload.pageNo,
    pageSize: payload.pageSize,
    service: 3, // 查询 mart&food 两种类型
  }
}

export function transformStoreBasicInfo(data: SchemaStoreVn.IStoreBasicInfo) {
  const storeStatusReason = data.storeStatusReason ?? {}
  return {
    openingStatus: {},
    store: {
      id: String(data.restaurantId),
      name: data.name,
      contactPhone: data.phones?.[0],
      storeType: data.storeType,
      cityId: String(data.cityId),
      foodyServiceId: data.foodyServiceId,
      ratingScore: data.ratingScore,
      isUseWallet: data.isUseMws ? BoolStatus.True : BoolStatus.False,
      statusReasons: [
        {
          scene: storeStatusReason.scene ?? StoreConstants.StoreStatusReasonsScene.Admin, // 默认 admin 永久关店
          statusReason: storeStatusReason.reason ?? StoreConstants.StoreStatusReason.PermanentlyClosed,
          endTime: storeStatusReason.remark?.endTime
            ? String(storeStatusReason.remark.endTime)
            : undefined,
        },
      ],
      status: data.isActive ? StoreConstants.StoreActiveStatus.Active : StoreConstants.StoreActiveStatus.Inactive,
    },
    flag: {}
  }
}

export function transformStoreSettings(data: SchemaStoreVn.IStoreSettings) {
  const autoConfirmedEnabled = data.isConfirmPayCommission
    ? BoolStatus.False
    : BoolStatus.True
  const autoConfirmTimeout =
    (data.autoConfirmTimeout?.timeoutUp ?? 0) > 0
      ? BoolStatus.False
      : BoolStatus.True

  return {
    openingStatus: {},
    store: {
      autoConfirmed: data.isConfirmPayCommission
        ? BoolStatus.False
        : autoConfirmTimeout, // 是否自动接单
      isInstantPrep: BoolStatus.False, // 是否即时备餐店铺，0-否，1-是
      autoConfirmedEnabled, // isConfirmPayCommission 为 true 时，不允许设置自动接单
      allowEditingDishOptionGroupBinding:
        data.allowEditingDishOptionGroupBinding,
      isInCategoryWhitelist: data.isInCategoryWhitelist, // VN Mart 店铺白名单标识
    },
    flag: {
      // Home 页的 wallet tab 入口白名单控制
      displayMerchantWalletPage: data.allowEnterWalletPage
        ? BoolStatus.True
        : BoolStatus.False,
    },
  }
}

export function transformStoreDetailResponse(responses: any[]): ISchemaStore.IStoreDetailResponse {
  const [basicInfoRes, settingsRes, openingStatusRes] = responses

  const result = {
    store: {},
    openingStatus: {},
    area: {},
  } as ISchemaStore.IStoreBusiness

  if (isSettledFulfilled(basicInfoRes)) {
    const basicInfo = transformStoreBasicInfo(basicInfoRes.value.data)
    result.store.flag = Object.assign({}, result.store.flag, basicInfo.flag)
    result.store = Object.assign({}, result.store, basicInfo.store)
    result.openingStatus = Object.assign(
      {},
      result.openingStatus,
      basicInfo.openingStatus,
    )
  }
  if (isSettledFulfilled(settingsRes)) {
    const settings = transformStoreSettings(settingsRes.value.data)
    result.store.flag = Object.assign({}, result.store.flag, settings.flag)
    result.store = Object.assign({}, result.store, settings.store)
    result.openingStatus = Object.assign(
      {},
      result.openingStatus,
      settings.openingStatus,
    )
  }
  if (isSettledFulfilled(openingStatusRes)) {
    const openingStatus = openingStatusRes.value.data
    result.store.flag = Object.assign({}, result.store.flag, openingStatus.flag)
    result.store = Object.assign({}, result.store, openingStatus.store)
    result.openingStatus = Object.assign(
      {},
      result.openingStatus,
      openingStatus.openingStatus,
    )
  }

  result.area = {
    id: result.store?.cityId || '',
    status: BoolStatus.True,
    serviceTypeFlag: {
      foodEnabled: BoolStatus.True,
      spxInstantShopeeEnabled: BoolStatus.True,
      spxInstantEnabled: BoolStatus.True,
    },
  }

  return result
}
