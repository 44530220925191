import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from '@/hooks/useSearchParams'
import { AppContext } from './app.context'
import { AppStoreId } from '@/constants/app.constant'
import { setCookies } from '@/utils/cookie.util'
import { COOKIE_CACHE_KEYS } from '@/constants/cache.constant'

export interface AppProviderProps {
  storeId?: string
  merchantId?: number
  userId: string
}

export const AppProvider: React.FC<AppProviderProps> = React.memo((props) => {
  const userId = props.userId
  const merchantId = props.merchantId ?? 0
  const paramStoreId = useSearchParams('storeId', 0, Number)

  const [storeId, setStoreId] = useState<number>(() => {
    const storeId = Number(props.storeId ?? paramStoreId) || 0

    AppStoreId.current = `${storeId || ''}`

    return storeId
  })

  useLayoutEffect(() => {
    AppStoreId.current = `${storeId || ''}`

    setCookies([
      {
        name: COOKIE_CACHE_KEYS.requestEntityId,
        value: `${storeId || ''}`,
      },
    ])

    return () => {
      AppStoreId.current = ''

      setCookies([
        {
          name: COOKIE_CACHE_KEYS.requestEntityId,
          value: '',
        },
      ])
    }
  }, [storeId])

  return (
    <AppContext.Provider value={{ userId, merchantId, storeId, setStoreId }}>
      {props.children}
    </AppContext.Provider>
  )
})
