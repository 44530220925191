import { BreakDown, OrderType } from './data.d'
import type { CancelReason } from '@/constants/order.constant'
import { merchantCancelReason, ItemStatus, DefaultCancelReason } from '@/constants/order.constant'
import type { OrderItem, StoreSettlement } from '@/services/transaction/data.d'
import { DiscountType } from '@/services/transaction/data.d'
import { t } from '@/utils/i18n'
import BigNumber from 'bignumber.js'

export const getCancelReasonText = (cancelReason: CancelReason) => {
  const reason = merchantCancelReason.find((reason) => reason.id === String(cancelReason))
  return reason ? t(reason.value.key) : t(DefaultCancelReason.key)
}

export const getFormattedPrice = ({
  cartItem: { quantity },
  status,
  unitPrice,
  unitListPrice,
  subtotal,
}: OrderItem) => {
  const price = unitPrice
  const isListPriceValid =
    ![ItemStatus.ModifiedPrice, ItemStatus.ModifiedAll].includes(status) &&
    unitListPrice !== undefined &&
    unitListPrice !== unitPrice

  return {
    price,
    priceSubTotal: subtotal,
    ...(isListPriceValid && {
      listPrice: unitListPrice,
      listPriceSubTotal: new BigNumber(unitListPrice).multipliedBy(quantity || 0).toString(),
    }),
  }
}

export const calcTransactionAmount = (
  order: OrderType,
  storeSettlement?: StoreSettlement,
): BreakDown => {
  const { items, amount } = order
  const { amount: settlementAmount } = storeSettlement || {}
  const { subtotal: orderValue, promotion } = amount!
  const total = (items || []).reduce(
    (total, item) => {
      const {
        cartItem: { quantity },
        unitListPrice,
        unitPrice,
        discountType,
      } = item
      const itemListPriceSubTotal = new BigNumber(unitListPrice).multipliedBy(quantity || 0)
      const itemUnitPriceSubTotal = new BigNumber(unitPrice).multipliedBy(quantity || 0)
      total.foodOriginPrice = total.foodOriginPrice.plus(itemListPriceSubTotal)
      if (discountType === DiscountType.FlashSale) {
        total.flashSaleDiscounts = total.flashSaleDiscounts.plus(
          itemListPriceSubTotal.minus(itemUnitPriceSubTotal),
        )
      } else if (discountType === DiscountType.PriceSlash) {
        total.itemDiscounts = total.itemDiscounts.plus(
          itemListPriceSubTotal.minus(itemUnitPriceSubTotal),
        )
      }
      return total
    },
    {
      foodOriginPrice: new BigNumber(0),
      itemDiscounts: new BigNumber(0),
      flashSaleDiscounts: new BigNumber(0),
    },
  )

  const totalAmount = new BigNumber(orderValue)
    .plus(amount!.merchantSurchargeFee || 0)
    .minus(settlementAmount?.storeSubsidy || 0)
    .toString()
  return {
    orderValue,
    foodOriginPrice: total.foodOriginPrice.toString(),
    flashSaleDiscountsAmount: total.flashSaleDiscounts.toString(),
    itemDiscountAmount: total.itemDiscounts.toString(),
    surchargeFee: amount!.merchantSurchargeFee || '0',
    totalAmount,
    promotionSubsidy: settlementAmount?.storeSubsidy || '0',
    voucherMerchantSubsidyAmount: settlementAmount?.itemVoucherSubsidy || '0',
    voucherShippingSubsidyAmount: settlementAmount?.shippingFeeVoucherSubsidy || '0',
    merchantPrepaidSubsidyAmount: settlementAmount?.merchantPrepaidSubsidy || '0',
    directDiscountAmount: promotion?.itemDiscountAmount || '0',
  }
}
