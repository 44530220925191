import React, { useEffect, useLayoutEffect, useState } from 'react'
import { StoreProvider } from './StoreProvider'
import { useAppContext } from '../app/app.context'
import { useSearchParams } from '@/hooks/useSearchParams'
import { Spin } from 'antd'
import { useStoreContext } from './store.context'

const WrappedStoreComponent = React.memo<React.PropsWithChildren<{}>>(({ children }) => {
  const { loading } = useStoreContext()

  return <Spin spinning={loading}>{children}</Spin>
})

/**
 *  需要选择具体店铺的Hoc组件
 *
 * @param BaseComponent
 * @param storeIdKey
 * @returns
 */
export function withSelectedStore<
  T extends object,
  P extends React.ComponentProps<React.ComponentType<T & Record<string, unknown>>>,
  R extends unknown = any,
>(BaseComponent: React.ComponentType<T>, storeIdKey: string = 'storeId') {
  const WrappedComponent: React.ForwardRefRenderFunction<R, P> = (props, ref) => {
    const { setStoreId } = useAppContext()
    const paramStoreId = useSearchParams('storeId', 0, Number)

    // 添加 loading 用于 Modal 弹窗页面，等待设置 storeId 成功后再打开
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      const storeId = props[storeIdKey] || paramStoreId
      if (storeId) {
        setStoreId(Number(storeId))
      }
    }, [paramStoreId, props, setStoreId])

    useLayoutEffect(() => {
      setTimeout(() => {
        setLoading(false)
      }, 0)
    }, [])

    if (loading) {
      return null
    }

    return (
      <StoreProvider>
        <WrappedStoreComponent>
          <BaseComponent ref={ref} {...props} />
        </WrappedStoreComponent>
      </StoreProvider>
    )
  }

  WrappedComponent.displayName = `withSelectedStore(${
    BaseComponent.displayName || BaseComponent.name || 'Unknown'
  })`

  return React.memo(React.forwardRef<R, P>(WrappedComponent))
}
