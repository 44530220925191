import qs from 'qs'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import sap from '@shopee/web_enhance_sap'
import { isEmptyObject, isFormData, isObject } from './utils'
import {
  AppCountry,
  AppFoodServer,
  AppLanguage,
  AppOpenApiServer,
  AppStoreId,
  AppToken,
  CloseType,
} from '@/constants/app.constant'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { delCookie } from '@/utils/cookie.util'
import {
  ErrorCodeMessageListAuth,
  ErrorCodeMessageMapping,
  ErrorMessageFallback,
} from './request.constant'
import { message } from 'antd'
import { t } from '@/utils/i18n'
import { I18nKey } from '@/assets/strings/i18n/i18n'
import { SpecialErrorCodes } from './constant'

/**
 * 添加 SAP Header 信息
 * @param config
 * @returns
 */
export const addSapRequestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { url = '', method = 'GET', data, params } = config

  // 过滤 OpenApi 接口以及 FormData 数据请求
  if ((data && isFormData(data)) || url.startsWith(AppOpenApiServer.current)) {
    return config
  }

  let body = data
  if (method.toLowerCase() === 'post' && isObject(data)) {
    try {
      body = JSON.stringify(data)
    } catch (error) {
      // EMPTY
    }
  }

  let requestUrl = url
  // 如果有参数，也需要添加到 url 中
  if (!isEmptyObject(params)) {
    const queryString = qs.stringify(params, { sort: (a, b) => a.localeCompare(b) })
    if (queryString.length > 0) {
      if (requestUrl[requestUrl.length - 1] === '?') {
        requestUrl += queryString
      } else if (requestUrl.includes('?')) {
        requestUrl = `${requestUrl}&${queryString}`
      } else {
        requestUrl = `${requestUrl}?${queryString}`
      }
    }
  }

  const signature = sap.generateSignEntry(requestUrl, body)
  Object.assign(config.headers, signature)

  return config
}

/**
 * TSS 需要添加 SPC-B-OFT offlineToken
 * @param config
 * @returns
 */
export const addOfflineTokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (window._BaseBridge) {
    const offlineToken = window._BaseBridge.getAppData().data?.user?.offlineToken
    if (offlineToken) {
      Object.assign(config.headers, { 'SPC-B-OFT': offlineToken })
    }
  }

  return config
}

export const camelCaseRequestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...config,
    data: config.data ? { ...decamelizeKeys(config.data) } : config.data,
    params: config.params ? { ...decamelizeKeys(config.params) } : config.params,
  }
}

export const camelCaseResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
  return {
    ...response,
    data: camelizeKeys(response.data),
  }
}

export const addServiceHeaderRequestInterceptor = (
  config: AxiosRequestConfig,
): AxiosRequestConfig => {
  if (AppCountry.isVn) {
    const headers = {
      ...config.headers,
      'x-foody-client-id': 'ffffffff-c9a4-034c-ffff-ffffc2e834d9',
      'x-foody-client-type': '1',
      'x-foody-app-type': '1025',
      'x-foody-client-version': '3.0.0',
      'x-foody-api-version': '1',
      'x-foody-client-language': AppLanguage.current,
      'x-foody-access-token': AppToken.current,
    }
    if (AppStoreId.current) {
      headers['x-foody-entity-id'] = AppStoreId.current
    }

    return {
      ...config,
      headers,
      baseURL: AppFoodServer.current,
    }
  } else {
    delCookie('shopee_tob_session_id', '/', AppFoodServer.current.split('.').slice(1).join('.'))
  }

  return {
    ...config,
    baseURL: AppFoodServer.current,
  }
}

export const processResponseInterceptor = (response: AxiosResponse) => {
  const { data } = response

  if (data?.code === 0) {
    return Promise.resolve(data)
  }

  const resCode = (data?.code ??
    response?.status ??
    ErrorMessageFallback) as keyof typeof ErrorCodeMessageMapping
  const resMsg = ErrorCodeMessageMapping[resCode]
    ? t(ErrorCodeMessageMapping[resCode] as I18nKey)
    : data?.msg ?? t(ErrorMessageFallback)

  if (ErrorCodeMessageListAuth.includes(data?.code)) {
    const baseBridge = (window as any)._BaseBridge
    if (baseBridge) {
      const { onClose } = baseBridge.getAppData()
      onClose?.({ closeType: CloseType.LOGOUT })
      message.error(resMsg)

      return Promise.reject({
        code: resCode,
        message: resMsg,
        data: data?.data,
      })
    }
  }

  if (SpecialErrorCodes.UseBackendMessageCodes.includes(data?.code)) {
    return Promise.reject({
      code: resCode,
      message: data?.data?.errorMessage || resMsg,
      data: data?.data,
    })
  }

  return Promise.reject({
    code: resCode,
    message: resMsg,
    data: data?.data,
  })
}
