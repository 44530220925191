/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 25 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.602 17.219a8 8 0 111.06-1.06l3.368 3.367a.75.75 0 01-1.06 1.06l-3.368-3.367zM18 11.056a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgSearch)
export default Memo
