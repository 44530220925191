import type {
  OrderStatus,
  ItemStatus,
  CancelReason,
  CancelSource,
  RewriteSource,
  RewriteType,
  RewriteReason,
  ChargebackStatus,
  ChargebackMehod,
  PaymentMethod,
  ListingStatus,
  SaleStatus,
  TransactionStatus,
} from '@/constants/order.constant'
import { BoolStatus } from '@/constants/common.constant'

export interface OrderOption {
  id: string
  name: string
  price: string
}

export interface OrderOptionGroup {
  id: string
  name: string
  options: OrderOption[]
}

// Time for Sale
export interface TimeForSale {
  saleStartTime: number // 售卖开始时间 (相对秒数)
  saleEndTime: number // 售卖结束时间 (相对秒数)
}

// Dish
export interface Dish {
  // ID
  id: string //	菜品ID
  storeId: string // 商铺ID
  // 基础信息
  name: string // 菜品名称
  available: boolean // 可售状态
  description: string // 描述
  price: string // 价格
  listPrice: string // 原价
  picture: string // 图片
  // 关联信息
  // bindGroupCount: number //	总group数
  optionGroupCount: number //	有效的 group 数量
  // 销售信息
  // ratingTotal: number // 评价数
  // ratingGood: number // 好评数
  // ratingBad: number // 差评数
  salesVolume: number // 销量
  timeForSales: TimeForSale[] // 菜品售卖时间（目前最多支持设置 3 个）
  saleStartTime: number // 售卖开始时间 (相对秒数)
  saleEndTime: number // 售卖结束时间 (相对秒数)
  saleWeekBit: number // 售卖时间周几生效 (返回为十进制, 需要转换为二进制按位运算) (最右第一位起为周日, 周一 ... 周六)
  inSaleTime: boolean // 菜品是否在售卖时间内 (根据 saleStartTime, saleEndTime, saleWeekBit 三个字段基于服务器时间计算得出)
  listingStatus: ListingStatus // 菜品上下架状态
  saleStatus: SaleStatus // 菜品是否可售 (根据 available, inSaleTime, listingStatus 三个字段基于服务器时间计算得出)
  // 以下为详情页字段
  catalogId?: string
  catalogName?: string
  rank?: number // 菜品排序
  qcFrozen?: boolean
  failedQcRecordId?: string
}

export interface CartItemDetail {
  id: string
  dish: Dish
  optionGroups: OrderOptionGroup[]
  quantity: number // 菜品数量
}

export interface CartItem {
  detail: CartItemDetail
  quantity: number // 菜品数量
}

export enum DiscountType {
  None = 0,
  PriceSlash = 1,
  FlashSale = 2,
}

export interface DishOptionsType {
  sugarLevel: number
  toppings: string
  size: string
  ice: number
}

// @see https://confluence.shopee.io/pages/viewpage.action?pageId=179118480#id-5.2.7.1.OrderJSONStructure-OrderItem
export interface OrderItem {
  id: string
  cartItem: CartItem
  remark: string // 菜品备注
  subtotal: string // 菜品小计
  unitPrice: string // 菜品标价
  unitListPrice: string // 划线价
  flatPrice: string // 均摊价格
  status: ItemStatus // 菜品状态
  // Tracking
  viewType?: string
  targetData?: any
  discountType: DiscountType
}

export interface DeliveryAddressLocation {
  province: string
  city: string
  district: string
  address: string
  latitude: number
  longitude: number
}

export interface DeliveryAddress {
  name: string // 收件人姓名
  phone: string // 电话
  location: DeliveryAddressLocation
}

export interface PromotionAmount {
  shippingDiscountAmount?: string
  coinsRedeemedAmount?: string
  itemVoucherAmount?: string
  shippingVoucherAmount?: string
  coinsEarning?: string
  coinsCashback?: string
  itemDiscountAmount?: string
  voucherMerchantSubsidyAmount: string // Merchant vouchers cofund
}

export interface Amount {
  subtotal: string // 菜品总价
  totalAmount: string // // 合计
  merchantServiceFee?: string // 商家服务费（已乘以 1e5）
  merchantSurchargeFee?: string // 商家服务费（已乘以 1e5）
  platformServiceFee?: string //	平台服务费（已乘以 1e5)
  shippingFee: string //	运费（已乘以 1e5)
  shippingBasicFee: string //	基础运费（已乘以 1e5)
  shippingSurgeFee: string //	高峰期运费补贴（已乘以 1e5)
  discountAmount: string //	满减优惠金额（已乘以 1e5)
  coinsAmount: string //	金币抵扣金额（已乘以 1e5)
  voucherAmount: string //	优惠券抵扣商品金额（已乘以 1e5)
  shippingVoucherAmount: string //	优惠券抵扣运费金额（已乘以 1e5)
  coinsEarning: string //	订单完成后赠予用户的金币
  promotion: PromotionAmount
}

export interface Store {
  id: string
  name: string
  phone: string
  location: Location
  logo: string
}

export interface OrderFlag {
  isReturned: BoolStatus // 是否有关联的退款申请	0：否 1：是	(出错)
  isRefunded: BoolStatus // 是否有关联的退款单	0：否 1：是	(出错)
  isManualConfirmed: BoolStatus // 是否人工确认	0：否 1：是	(出错)
  canDriverRewrite: BoolStatus // 骑手能否修改本订单	0：否 1：是	(出错)
  isDriverRewritePreview: BoolStatus // （已废弃）是否处于骑手改单预览状态	0：否 1：是	(出错)
  isInstantPreparation: BoolStatus // 是否为即时备餐订单	0：否 1：是	(出错)
  canMerchantRewrite: BoolStatus // 商家能否修改本订单	0：否 1：是	(出错)
}

export interface Chargeback {
  // 商户退款
  id: string // id
  returnId: string // returnId
  chargebackStatus: ChargebackStatus // 状态
  chargebackMethod: ChargebackMehod // 退回方式
  chargebackAmount: string // 退回方式
}

export interface Order {
  id: string // 订单ID
  amount: Amount // 费用信息
  store: Store // 商家信息
  pickupSeq: number // 取餐号
  remark: string // 订单备注
  status: OrderStatus // 订单状态
  // 拒订单
  cancelReason: CancelReason // 原因
  cancelSource: CancelSource // 来源
  cancelTime: string // 时间
  // 收货人
  deliveryAddress?: DeliveryAddress
  deliveryArriveTime: string
  deliveryAssignTime: string
  deliveryCompleteTime: string
  deliveryDistance: number
  deliveryPickupTime: string
  deliveryReconfirmTime: string
  // 预计时间
  estimateArrivedTime: string // 到店
  estimateDeliveredTime: string // 送达
  // 标记
  isRatingCompleted: number
  isReady: boolean // 订单是否已处理
  isReceived: boolean // 订单是否已制作完成
  // 菜品
  itemQuantity: number // 数量
  items: OrderItem[] // 内容
  // 支付
  paymentMethod: PaymentMethod // 方式
  paymentTime: string // 时间
  // 各种时间
  createTime: string // 订单创建时间
  updateTime: string // 订单更新时间
  placeTime: string // 下单时间
  readyTime: string // 商品备好时间
  completeTime: string // 完成时间
  reviewTime: string // 审核时间
  merchantConfirmTime: string
  // 改单
  rewriteReason: RewriteReason // 原因
  rewriteRemark: string // 备注
  rewriteType: RewriteType // 状态
  refundAmount: string //	改价-应退差价（difference after update）
  makeupAmount: string // 改价-应补差价（difference after update）
  rewriteSource: RewriteSource // 改单来源
  // 预格式化数据
  internalFormattedTime?: string
  internalFormattedPrice?: string
  // Tracking
  viewType?: string
  targetData?: any
  flag?: OrderFlag
  merchantConfirmedDeadline?: string // 商户接单超时时间
  chargeback: Chargeback
  storeLocationGroups: any[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Payment {
  // TODO: 暂时没有用到, 先不写
  // @see https://confluence.shopee.io/display/SPFOODY/5.2.7.1.+Order+JSON+Structure#id-5.2.7.1.OrderJSONStructure-Payment.1
}

enum VatType {
  VAT_UNKNOWN = 0,
  YES = 1,
  No = 2,
}

enum WhtType {
  WHT_UNKNOWN = 0,
  YES = 1,
  No = 2,
  Onbehalf = 3,
  ManualTransfer = 4,
}

interface MerchantAmount {
  itemSubtotal: string // 订单菜品金额
  amount: string // 结算后要调整的钱（可正可负）
  commission?: string // 佣金金额
  commissionRate?: number // 佣金比例
  storeSubsidy: string // 商家补贴
  itemVoucherSubsidy: string // 餐费优惠券补贴
  shippingFeeVoucherSubsidy: string // 配送费补贴
  vat?: VatType
  vatRate?: number
  vatAmount?: string
  wht?: WhtType
  whtRate?: number
  whtAmount?: string
  vatCalculate?: number
  merchantPrepaidSubsidy: string // 商家预付补贴
  platformPrepaidSubsidy: string // 平台预付补贴
}

export interface StoreSettlement {
  id: string //	结算单ID
  amount: MerchantAmount // 变动金额
  // ...其他未用到，先不写
}
export interface TransactionDetailResponse {
  order: Order
  payment: Payment
  storeSettlement: StoreSettlement
}

export interface OrderDetailStatistics {
  foodOriginalPrice: string
  itemDiscounts: string
  flashSaleDiscounts: string
  surchargeFee: string
  merchantSubsidyVoucher: string
  shippingSubsidyVoucher: string
  amount: string
  orderValue: string
  promotionSubsidy: string
  itemDiscountAmount?: string
  merchantPrepaidSubsidy: string
}

export interface OrderDetailResponse extends OrderDetailStatistics {
  storeId: string
  status: TransactionStatus
  orderStatus: OrderStatus
  transactionType: number
  orderSubmittedTime: string
  orderPickupTime: string
  orderDeliveredTime: string
  orderCancelledTime: string
  cancelledBy: CancelSource
  cancelReason: CancelReason
  orderItems: OrderItem[]
  storeSettlement?: StoreSettlement
}
