import { ErrorCode } from "@/hooks/useRequest/request.codes"
import { isBusinessError } from "@/hooks/useRequest/utils"

export function waitForMs<T = unknown>(ms: number = 1000) {
  return new Promise<T>(resolve => setTimeout(resolve, ms))
}

type FulfilledPromiseResult = {
  status: 'fulfilled'
  value: any
}
type RejectedPromiseResult = {
  status: 'rejected'
  reason: any
}

/**
 * 判断聚合 Promise 中的某个 Promise 是否成功
 *
 * @export
 * @param {*} result
 * @returns {result is FulfilledPromiseResult}
 */
export function isSettledFulfilled(
  result: any,
): result is FulfilledPromiseResult {
  return result && result.status === 'fulfilled'
}

/**
 * 判断聚合 Promise 中的某个 Promise 是否失败
 *
 * @export
 * @param {*} result
 * @returns {result is RejectedPromiseResult}
 */
export function isSettledRejected(
  result: any,
): result is RejectedPromiseResult {
  return result && result.status === 'rejected'
}


// #region 批量处理方法

type BaseBatchItem = string | number
type BaseBatchResult = {
  id: BaseBatchItem
  index?: number
  code?: string
  message?: string
}

type BatchOptions<T extends BaseBatchItem, R extends BaseBatchResult> = {
  maxLength?: number
  materiels: T[]
  action: (payloads: T[]) => Promise<R[]>
  onProgress?: (progress: number) => void
}

export async function withBatchablePromise<
  T extends BaseBatchItem,
  R extends BaseBatchResult
>(options: BatchOptions<T, R>) {
  const { maxLength = 10, materiels, action, onProgress } = options

  const errorResult: { code: number; message: string; result: R[] } = {
    code: 0,
    message: '',
    result: [],
  }
  const data = materiels.map((m, index) => ({ id: m, index }))
  const launch = async (start = 0) => {
    const next = Math.min(start + maxLength, data.length)
    const payloads = data.slice(start, next)
    // 上传完毕
    if (payloads.length === 0) {
      await waitForMs(500)
      return
    }

    try {
      const res = await action(payloads.map(i => i.id))
      errorResult.result.push(
        ...(res || []).map(item => ({
          ...item,
          ...payloads.find(i => i.id === item.id),
        })),
      )
    } catch (error: any) {
      if (isBusinessError(error)) {
        errorResult.code = error.code
        errorResult.message = error.message
      } else {
        errorResult.code = ErrorCode.UNHANDLED
        errorResult.message = error?.message
      }
      errorResult.result.push(...((payloads as unknown) as R[]))
    } finally {
      onProgress?.(Math.floor((next / materiels.length) * 100))
    }

    await launch(next)
  }

  await launch()

  return {
    ...errorResult,
    result: errorResult.result.filter(e => !!e),
  }
}

// #endregion
