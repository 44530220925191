import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { AppCountry, AppShowType } from '@/constants/app.constant'
import TransactionDetail from '@/pages/TransactionDetail'
import { useFetchMerchantDetail } from './services/store'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Provider } from 'react-redux'
import { StoreContext } from 'redux-react-hook'
import store from './pages/Order/redux/store'
import { AppProvider } from './context/app'
import type { AppProps } from './interface'
import { initTracker } from './utils/tracker'

function App(props: Partial<AppProps> & { appBridgeData: Window['bridgeData'] }) {
  const { appBridgeData } = props
  const { data, showType, onClose } = appBridgeData

  const userId = `${data?.user?.userId || ''}`

  const { PREFIXCLS, PREFIXCLS_ICON } = process.env
  const routePath = window.location.pathname

  // Initialize tracking
  useEffect(() => {
    try {
      initTracker()
    } catch (e) {
      console.error('initTracker error: ', e)
    }
  }, [])

  // NOTE: 解决 单独访问子应用时 message 显示问题
  ConfigProvider.config({ prefixCls: PREFIXCLS })

  const [merchantId, updateMerchantId] = useState<number>(0)

  const { run: fetchMerchantDetail } = useFetchMerchantDetail()
  const setMerchantId = useCallback(async () => {
    const storeInfo = await fetchMerchantDetail({
      tobUserId: Number(userId),
    })

    updateMerchantId(storeInfo?.merchantId ?? 0)
  }, [fetchMerchantDetail, userId])

  useEffect(() => {
    if (userId && !AppCountry.isVn) {
      setMerchantId()
    }
  }, [setMerchantId, userId, routePath])

  const memoAppChildren = useMemo(() => {
    // TODO: 根据showType判断是页面还是弹窗，本期只有一个弹窗暂时先写死，后期需主应用传对应的弹窗标识做分发
    if (showType === AppShowType.Modal) {
      return <TransactionDetail onClose={onClose} {...data?.props} />
    }

    if (!AppCountry.isVn) {
      return (
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      )
    }

    // VN 的 Order 模块依赖 Redux
    return (
      <Provider store={store}>
        <StoreContext.Provider value={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </StoreContext.Provider>
      </Provider>
    )
  }, [data?.props, onClose, showType])

  return (
    <AppProvider {...data?.props} userId={userId} merchantId={merchantId}>
      <ConfigProvider prefixCls={PREFIXCLS} iconPrefixCls={PREFIXCLS_ICON}>
        {memoAppChildren}
      </ConfigProvider>
    </AppProvider>
  )
}

export default App
