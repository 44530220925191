import { TimeStandard, TimezoneOffset, TimezoneOffsetText } from './constants'
import type { LocaleConfig } from './type'

enum TimezoneMY {
  KUALALUMPUR = 'Asia/Kuala_Lumpur',
}

const localeConfig: LocaleConfig = {
  /**
   * timezone
   */
  defaultTimezone: TimezoneMY.KUALALUMPUR,
  getTimezone: (timezone?: string) => {
    const lowerTimezone = timezone?.toLowerCase()
    const config = {
      [TimezoneMY.KUALALUMPUR.toLowerCase()]: {
        timezoneStandardText: TimeStandard.WITA,
        timezoneOffset: TimezoneOffset.UTC8,
        timezoneOffsetText: TimezoneOffsetText[TimezoneOffset.UTC8],
        timezoneName: TimezoneMY.KUALALUMPUR,
      },
    }
    return config[lowerTimezone as TimezoneMY] || config[TimezoneMY.KUALALUMPUR.toLowerCase()]
  },

  price: {
    regExp: /^(\d+)(\.\d{0,2})?$/,
    precision: 2
  },

  menu: {
    dish: {
      minPrice: 0.01,
      maxPrice: 10000000
    }
  }
}

export default localeConfig
