import { RouteConfig } from '../interface'

const menuRoutes: RouteConfig[] = [
  {
    path: '/shopee-food/menu',
    component: () =>
      import(/* webpackChunkName: "Menu-MainDish" */ '@/pages/Menu/MainDish/List'),
  },
  {
    path: '/shopee-food/menu/transit/audit-result',
    component: () =>
      import(/* webpackChunkName: "Menu-AuditResult" */ '@/pages/Menu/AuditResult'),
  },
  {
    path: '/shopee-food/menu/:tab',
    component: () =>
      import(/* webpackChunkName: "Menu-MainDish" */ '@/pages/Menu/MainDish/List'),
  },
  {
    path: '/shopee-food/menu/dish/create',
    component: () =>
      import(/* webpackChunkName: "Menu-Dish-Create" */ '@/pages/Menu/MainDish/Create'),
  },
  {
    path: '/shopee-food/menu/dish/edit',
    component: () =>
      import(/* webpackChunkName: "Menu-Dish-Edit" */ '@/pages/Menu/MainDish/Create'),
  },
  {
    path: '/shopee-food/menu/option-group/create',
    component: () =>
      import(/* webpackChunkName: "Menu-OptionGroup-Create" */ '@/pages/Menu/OptionGroup/Create'),
  },
  {
    path: '/shopee-food/menu/option-group/edit',
    component: () =>
      import(/* webpackChunkName: "Menu-OptionGroup-Edit" */ '@/pages/Menu/OptionGroup/Create'),
  },
]

export default menuRoutes
