import { AppFoodServer } from '@/constants/app.constant'

type CookieType = {
  name: string
  value: string
}

export function getCookie(name: string) {
  if (!name) {
    return ''
  }

  const cookieName = `${encodeURIComponent(name)}=`,
    cookieStart = document.cookie.indexOf(cookieName)
  let cookieValue = null

  if (cookieStart > -1) {
    let cookieEnd = document.cookie.indexOf(';', cookieStart)
    if (cookieEnd === -1) {
      cookieEnd = document.cookie.length
    }
    cookieValue = decodeURIComponent(
      document.cookie.substring(cookieStart + cookieName.length, cookieEnd),
    )
  }

  return cookieValue
}

export function setCookie({
  name,
  value,
  expires,
  path,
  domain,
  secure,
}: {
  name: string
  value: string
  expires?: Date
  path?: string
  domain?: string
  secure?: boolean
}) {
  let cookieText = `${name}=${value}`
  if (expires instanceof Date) {
    cookieText += `; expires=${expires.toUTCString()}`
  }
  if (path) {
    cookieText += `; path=${path}`
  }
  if (domain) {
    cookieText += `; domain=${domain}`
  }
  if (secure) {
    cookieText += '; secure'
  }
  document.cookie = cookieText
}

export function setCookies(cookies: CookieType[], domain = AppFoodServer.current) {
  cookies.forEach(({ name, value }) => {
    setCookie({
      name,
      value,
      path: '/',
      domain: domain.split('.').slice(1).join('.'),
    })
  })
}

export function delCookie(name: string, path: string, domain?: string, secure?: boolean) {
  // 删除，name必须
  setCookie({ name, value: '', expires: new Date(0), path, domain, secure })
}
