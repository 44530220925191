import { Country, Environment, Language } from './env.constant'

export const AppCountry = {
  current: Country.id,
  get isVn() {
    return this.current === Country.vn
  },
  get isTh() {
    return this.current === Country.th
  },
  get isMy() {
    return this.current === Country.my
  },
  get isId() {
    return this.current === Country.id
  },
}

export const AppEnvironment = {
  current: Environment.Live,
  get isLive() {
    return this.current === Environment.Live
  },
  get isStaging() {
    return this.current === Environment.Staging
  },
  get isUat() {
    return this.current === Environment.Uat
  },
  get isTest() {
    return this.current === Environment.Test
  },
}

export const AppToken = { current: '' }

// 每个国家对应的语言 - MS
// const transifyFileMap = {
//   id: ['en', 'id'],
//   my: ['en', 'ms', 'zh-my'],
//   ph: ['en', 'ph', 'zh-Hans'],
//   sg: ['en', 'zh-Hans'],
//   th: ['en', 'th'],
//   vn: ['en', 'vi'],
// }
export const AppLanguage = { current: Language.en }

export const AppFoodServer = { current: '' }

export const AppOpenApiServer = { current: '' }

export const AppStoreId = { current: '' }

export enum AppShowType {
  Page = 'page',
  Modal = 'modal',
}

export enum CloseType {
  LOGOUT = 'logout',
}

export enum RequestFromType {
  PartnerWeb = 'partner_web',
  PartnerApp = 'partner_app',
}
