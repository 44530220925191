/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgCheckedCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <rect width={20} height={20} rx={10} fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.664 7.75l-6.207 6.207a1 1 0 01-1.414 0L4.336 10.25 5.75 8.836l3 3 5.5-5.5 1.414 1.414z"
      fill="#fff"
    />
  </svg>
)
const Memo = memo(SvgCheckedCircle)
export default Memo
