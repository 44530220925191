import useRequest from '@/hooks/useRequest'
import type { UseFetchOptions } from '@/hooks/useRequest'
import type { OrderDetailResponse, TransactionDetailResponse } from './data.d'
import type { BaseResult } from '@ahooksjs/use-request/lib/types'
import { AppCountry } from '@/constants/app.constant'

export function useFetchTransactionDetail(options?: UseFetchOptions<any, any>) {
  return useRequest<BaseResult<TransactionDetailResponse | OrderDetailResponse, any>>(
    (orderId: string, storeId: number) => {
      // TH调用Food BE接口，其他环境暂时调用清结算接口，后续将会统一切换到Food接口
      if (AppCountry.isTh) {
        return {
          url: `/api/seller/transaction/detail`,
          method: 'POST',
          data: {
            order_id: orderId,
            store_id: storeId.toString(),
          },
        }
      }
      return {
        url: `/api/seller/mis/orders/${orderId}`,
        method: 'GET',
      }
    },
    {
      manual: true,
      ...options,
    },
  )
}
