import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import type { I18nKey, I18nT } from '@/assets/strings/i18n/i18n'
import en from '@/assets/strings/i18n/en.json'
import id from '@/assets/strings/i18n/id.json'
import msMy from '@/assets/strings/i18n/ms-my.json'
import zhHans from '@/assets/strings/i18n/zh-Hans.json'
import th from '@/assets/strings/i18n/th.json'
import vi from '@/assets/strings/i18n/vi.json'
import { AppCountry, AppLanguage } from '@/constants/app.constant'
import { Language } from '@/constants/env.constant'

// 每个国家对应的语言 - MS
// const transifyFileMap = {
//   id: ['en', 'id'],
//   my: ['en', 'ms', 'zh-my'],
//   ph: ['en', 'ph', 'zh-Hans'],
//   sg: ['en', 'zh-Hans'],
//   th: ['en', 'th'],
//   vn: ['en', 'vi'],
// }

const resources: Record<Language, any> = {
  [Language.en]: {
    translation: en,
  },
  [Language.id]: {
    translation: id,
  },
  [Language['ms-my']]: {
    translation: msMy,
  },
  [Language['zh-Hans']]: {
    translation: zhHans,
  },
  [Language.th]: {
    translation: th,
  },
  [Language.vi]: {
    translation: vi,
  },
}

export const initLanguage = (language: Language) => {
  AppLanguage.current = language

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: Language.en,
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
  })
}

const origionT = i18n.t.bind(i18n)
function i18nT(key: I18nKey, options?: any) {
  const suffixKey = `${key}__${AppCountry.current.toUpperCase()}`
  const hasSuffix = i18n.exists(suffixKey)

  if (hasSuffix) {
    return origionT(suffixKey, options)
  }

  return origionT(key, options)
}

export const t = i18nT as I18nT

export default i18n
