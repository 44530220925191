import { DishSaleWeekDay } from '@/services/menu/constants'
// 这里放 Menu 中共同使用的 constants
export const AllAppliedDays = [1, 2, 3, 4, 5, 6, 7]
export const AllDaysNum = (1 << 7) - 1
export const DayStartTime = 0
export const DayEndTime = 23 * 3600 + 59 * 60 + 59

export const WeekBitMaskList = [
  parseInt('0000010', 2), // mon
  parseInt('0000100', 2), // tue
  parseInt('0001000', 2),
  parseInt('0010000', 2),
  parseInt('0100000', 2),
  parseInt('1000000', 2),
  parseInt('0000001', 2), // sunday
]
export const WeekBitIdxMapping = {
  [WeekBitMaskList[0]]: 1, // mon
  [WeekBitMaskList[1]]: 2,
  [WeekBitMaskList[2]]: 3,
  [WeekBitMaskList[3]]: 4,
  [WeekBitMaskList[4]]: 5,
  [WeekBitMaskList[5]]: 6,
  [WeekBitMaskList[6]]: 0, // sun
}

export const weekBitDayMapVN = {
  [WeekBitMaskList[0]]: DishSaleWeekDay.MONDAY,
  [WeekBitMaskList[1]]: DishSaleWeekDay.TUESDAY,
  [WeekBitMaskList[2]]: DishSaleWeekDay.WEDNESDAY,
  [WeekBitMaskList[3]]: DishSaleWeekDay.THURSDAY,
  [WeekBitMaskList[4]]: DishSaleWeekDay.FRIDAY,
  [WeekBitMaskList[5]]: DishSaleWeekDay.SATURDAY,
  [WeekBitMaskList[6]]: DishSaleWeekDay.SUNDAY,
}
