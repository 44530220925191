import { AppCountry } from "@/constants/app.constant"
import { localeConfig } from "@/locale"
import { StoreSettleToEnum, StoreType } from "@/services/store/constants"

import type { IStoreBusiness } from "@/services/store/data"
import { isMart } from "@/utils/store.business.util"
import { createContext, useContext, useMemo } from "react"

const { defaultTimezone, getTimezone } = localeConfig

interface StoreContextProps {
  loading: boolean
  storeBusiness?: IStoreBusiness
  refreshBusiness: () => void
}

export const StoreContext = createContext<StoreContextProps>({
  loading: true,
  storeBusiness: undefined,
  refreshBusiness: () => { },
})

export function useStoreContext() {
  const { storeBusiness, ...context } = useContext(StoreContext)

  /** 是否主店铺 */
  const isHeadStore = useMemo(
    () => storeBusiness?.store?.storeType === StoreType.HeadStore,
    [storeBusiness?.store?.storeType],
  )

  /** 是否子店铺 */
  const isSubStore = useMemo(
    () => storeBusiness?.store?.storeType === StoreType.SubStore,
    [storeBusiness?.store?.storeType],
  )

  /** 是否商家结算 */
  const isSettleToMerchant = useMemo(
    () => storeBusiness?.store?.settleTo === StoreSettleToEnum.Merchant,
    [storeBusiness?.store?.settleTo],
  )

  /** 是否 Mart 店铺 */
  const isMartStore = useMemo(
    () => isMart(storeBusiness?.store?.foodyServiceId ?? 0),
    [storeBusiness?.store?.foodyServiceId],
  )

  /** 是否白名单中的 Mart 店铺 */
  const isMartInCategoryWhitelist = useMemo(
    () => isMartStore && !!storeBusiness?.store?.isInCategoryWhitelist,
    [storeBusiness?.store?.isInCategoryWhitelist, isMartStore],
  )

  /** 是否允许 dish 和 option-group 的绑定，仅 VN 黑名单商家不允许绑定 */
  const allowBindingDishOptionGroup = useMemo(
    () => !AppCountry.isVn || !!storeBusiness?.store?.allowEditingDishOptionGroupBinding,
    [storeBusiness?.store?.allowEditingDishOptionGroupBinding],
  )

  /** dish 和 option-group 是否允许同步到子店铺, 仅 VN 支持 */
  const allowMenuApplySubStore = useMemo(
    () => AppCountry.isVn && isHeadStore && allowBindingDishOptionGroup,
    [isHeadStore, allowBindingDishOptionGroup],
  )

  const timezone = useMemo(() => storeBusiness?.store?.timezone ?? defaultTimezone, [storeBusiness?.store?.timezone])

  const timezoneText = useMemo(() => {
    const finalTimezone = getTimezone(timezone)
    return finalTimezone ? `${finalTimezone.timezoneStandardText} (${finalTimezone.timezoneOffsetText})` : ''
  }, [timezone])

  return {
    ...context,
    storeBusiness,

    // store
    timezone,
    timezoneText,

    isHeadStore,
    isSubStore,
    isMartStore,
    isSettleToMerchant,
    isMartInCategoryWhitelist,
    allowBindingDishOptionGroup,
    allowMenuApplySubStore
  } as const
}
