import { RouteConfig } from './interface'
import exceptionRoutes from './children/exception'
import settingRoutes from './children/settingRoutes'
import openApiRoutes from './children/openApiRoutes'
import orderRoutes from './children/orderRoutes'
import menuRoutes from './children/menuRoutes'

const routes: RouteConfig[] = [
  ...settingRoutes,
  ...menuRoutes,
  ...orderRoutes,
  ...openApiRoutes,
  /** 异常页面，必须放到最后 */
  ...exceptionRoutes,
]

if (process.env.NODE_ENV === 'development') {
  routes.unshift(
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    },
    {
      path: '/demo',
      component: () => import(/* webpackChunkName: "demo" */ '@/pages/Demo'),
    },
  )
}

export default routes
