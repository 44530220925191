/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgImageCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="currentColor" />
    <path d="M25.958 16.75a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.167 11.333c0-1.196.97-2.166 2.166-2.166h17.334c1.196 0 2.166.97 2.166 2.166v17.334c0 1.196-.97 2.166-2.166 2.166H11.333a2.167 2.167 0 01-2.166-2.166V11.333zm2.166-.541h17.334c.299 0 .541.242.541.541v11.851l-3.217-3.217a.812.812 0 00-1.149 0l-2.675 2.676-5.926-5.926a.813.813 0 00-1.149 0l-4.3 4.3v-9.684c0-.299.242-.541.541-.541z"
      fill="#fff"
    />
  </svg>
)
const Memo = memo(SvgImageCircle)
export default Memo
