import { Modal, ModalFuncProps, Space, Typography } from 'antd'
import { t } from './i18n'
import classNames from 'classnames'
import React from 'react'
import { IconNotice, IconSetting } from '@/components/Icons'


export function showConfirmDialog(options: Omit<ModalFuncProps, 'onOk' | 'onCancel'>) {
  const {
    className,
    title,
    icon = <IconSetting width={40} height={40} />,
    okText = t('common.button.yes'),
    cancelText = t('common.button.no'),
    ...props
  } = options

  return new Promise((resolve, reject) => {
    Modal.confirm({
      closable: true,
      ...props,
      ...props,
      title: title ? (
        <Space>
          {icon}
          <Typography.Title>{title}</Typography.Title>
        </Space>
      ) : undefined,
      icon: null,
      className: classNames(`${process.env.PREFIXCLS}-modal-alert`, className),
      okText,
      cancelText,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    })
  })
}

export function showAlertDialog(options: Omit<ModalFuncProps, 'onOk' | 'onCancel' | 'cancelText'>) {
  const {
    okText = t('common.button.ok'),
    className,
    title,
    icon = <IconNotice width={40} height={40} color="rgba(237, 165, 0, 1)" />,
    ...props
  } = options

  return new Promise((resolve) => {
    Modal.confirm({
      closable: true,
      ...props,
      title: title ? (
        <Space>
          {icon}
          <Typography.Title>{title}</Typography.Title>
        </Space>
      ) : undefined,
      icon: null,
      className: classNames(`${process.env.PREFIXCLS}-modal-alert`, className),
      okText,
      okButtonProps: { block: true, size: 'large' },
      cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    })
  })
}

export function showConfirmDiscardDialog() {
  return showConfirmDialog({
    content: t('discard.dialog.content'),
    okText: t('discard.dialog.button.discard'),
    cancelText: t('discard.dialog.button.cancel'),
  })
}

export function showConfirmCoverDialog() {
  return showConfirmDialog({
    title: t('cover.dialog.title'),
    content: t('cover.dialog.content'),
    cancelText: t('common.button.no'),
    okText: t('common.button.yes'),
  })
}

export function showNoCancelDialog(options: Omit<ModalFuncProps, 'onOk' | 'onCancel'>) {
  const { okText = t('common.button.ok'), ...props } = options

  return showConfirmDialog({
    ...props,
    okText,
  })
}
