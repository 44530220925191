/**
 * Auto-generated file created by svgr-cli source svgr.template.config.js
 * Run yarn svgr to update
 * Do not edit
 */
import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 33 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#EE4D2D'}
    {...props}
  >
    <path
      d="M3.5 28a1 1 0 001 1h24a1 1 0 001-1v-6.667a1 1 0 10-2 0V27h-22v-5.667a1 1 0 10-2 0V28z"
      fill="currentColor"
    />
    <path
      d="M9.126 11.293a1 1 0 101.415 1.414l4.96-4.96v13.586a1 1 0 102 0V7.747l4.959 4.96a1 1 0 001.414-1.414l-6.667-6.667a1 1 0 00-1.414 0l-6.667 6.667z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgUpload)
export default Memo
